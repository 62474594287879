import { useEffect } from 'react';

function CareerPage() {
  useEffect(() => {
    window.location.href = 'https://kmong.career.greetinghr.com/';
  }, []);

  return null;
}

export default CareerPage;
