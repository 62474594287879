import Cookies from 'js-cookie';
import type { CookiesKeyEnum } from './types/cookiesType';

const internalCookies = () => {
  if (!window || !Cookies) {
    return null;
  }

  return Cookies;
};

const setItem = (key: CookiesKeyEnum, value: string, expireDate: Date) => {
  internalCookies()?.set(key, value, { expires: expireDate });
};

const getItem = (key: CookiesKeyEnum) => internalCookies()?.get(key);

const removeItem = (key: CookiesKeyEnum) => internalCookies()?.remove(key);

export const cookies = {
  setItem,
  getItem,
  removeItem,
};
