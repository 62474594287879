import { CLOUDFRONT_URL } from '@kmong-service/utils';
import type { SEOProps } from '../..';

export const quoteGuideSEO: SEOProps = {
  title: '크몽 엔터프라이즈 - 프로젝트 예상 견적 확인',
  description: '홈페이지 제작, 앱 개발, ERP 개발 등∙∙∙ 프로젝트 적정 개발 견적을 알아보세요!',
  openGraph: {
    image: {
      url: `${CLOUDFRONT_URL}/press/og/og_enterprise.png`,
      alt: '크몽 엔터프라이즈 - 프로젝트 예상 견적 확인',
    },
  },
  breadcrumbItemList: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      item: 'https://kmong.com/enterprise',
      name: '엔터프라이즈',
      position: 2,
    },
    {
      item: 'https://kmong.com/enterprise/quote-guide',
      name: '엔터프라이즈 견적 페이지',
      position: 3,
    },
  ],
};
