import type { ArticleDetailModuleType } from './module.type';
import type { ArticleDetailModuleBase } from '../base.type';

export enum ArticleDetailTextDesignType {
  DEFAULT = 'DEFAULT',
  BOX = 'BOX',
}

export interface ArticleDetailTextType extends ArticleDetailModuleBase {
  type: ArticleDetailModuleType.TEXT;
  designType: ArticleDetailTextDesignType;
  text: string;
}
