import api from './api';

export interface GetSellerProfileSkillCategoryResponse {
  skill: SellerProfileSkillCategory[];
}

export interface GetSellerProfileFormatSkillCategoryResponse {
  category_skill: SellerProfileSkillCategory[];
}

export interface SellerProfileSkillCategory {
  category_id: number;
  category_name: string;
  option_list: Array<{
    id: number;
    name: string;
  }>;
}

export interface SelectedSkill {
  category_id: number;
  skill_id: number;
  name?: string;
}

const getSellerProfileFormatSkillCategory = async () => {
  const { data } = await api.get<GetSellerProfileFormatSkillCategoryResponse>('/api/v5/seller-profile/format/skill');

  return data;
};

export interface GetSellerProfileFormatSpecialtyResponse {
  category_specialty: CategorySpecialty[];
}

export interface CategorySpecialty {
  category_id: number;
  category_name: string;
  option_list: Array<{
    id: number;
    name: string;
  }>;
}

const getSellerProfileFormatSpecialty = async () => {
  const { data } = await api.get<GetSellerProfileFormatSpecialtyResponse>('/api/v5/seller-profile/format/specialty');

  return data;
};

export interface GetActivityAreaResponse {
  activity_area: ActivityArea[];
}

export interface ActivityArea {
  code: number;
  name: string;
}

export interface Education {
  university: string;
  major: string;
  status_attendance: string;
  is_authenticated: boolean;
  FID: number | null;
}

export interface License {
  description: string;
  licensed_at: string;
  licensed_provider_center: string;
  is_authenticated: boolean;
  FID: number | null;
}

const getSellerProfileActivityArea = async () => {
  const { data } = await api.get<GetActivityAreaResponse>('/api/seller-profile/v2/activity-area');

  return data;
};

export interface Activity {
  count_order: number;
  satisfaction_point: number;
  avg_response_time: string;
}

export interface Seller {
  has_username_log: boolean;
  username: string;
  username_updated_at: string | null;
  is_able_to_edit_username: boolean;
  seller_type: string;
  available_time: string;
  activity_area: ActivityArea | null;
  basic_hourly_pay: number;
  is_resident: boolean;
  is_consultation: boolean;
  is_completed_seller_profile: boolean;
}

export interface OriginalCareer {
  description: string;
  period_start: string;
  period_end: string;
}

export interface Career {
  is_freelancer: boolean;
  month: number;
  year: number;
  is_authenticated: boolean;
  FID: number | null;
  company?: string;
  department?: string;
  position?: string;
  activity_area?: ActivityArea;
}

export type SellerStatus = 'SEARCHING' | 'WORKING';

export interface Place {
  id: number;
  name: string;
}

export interface Subject {
  subject_id: number;
  subject_name: string;
}

export interface ResidentProject {
  title: string;
  subject: Subject;
  project_start_date: string;
  project_end_date?: string | null;
  is_ongoing: boolean;
}

export interface Resident {
  is_full_time: boolean;
  is_part_time: boolean;
  place: Place;
  seller_status: SellerStatus | null;
  min_pay: number | null;
  max_pay: number | null;
  project_start_date?: string;
  project_end_date?: string;
}

export interface GetSellerProfileUserInfoResponse {
  activity: Activity;
  seller: Seller;
  description: string;
  ori_career: OriginalCareer[];
  career: Career[];
  education: Education[];
  skill: SellerProfileSkillCategory[];
  specialty: CategorySpecialty[];
  license: License[];
  total_career: number;
  resident: Resident;
  resident_project: ResidentProject[];
}

const getSellerProfileUserInfo = async (USERID: number) => {
  const { data } = await api.get<GetSellerProfileUserInfoResponse>(`/api/seller-profile/v3/${USERID}/info`);

  return data;
};

export interface ResidentPlace {
  place_id: number;
  place_name: string;
}

export interface GetResidentPlaceResponse {
  places: ResidentPlace[];
}

const getSellerProfileResidentPlace = async () => {
  const { data } = await api.get<GetResidentPlaceResponse>('/api/seller-profile/v2/resident-place');

  return data;
};

export interface ResidentSubject {
  subject_id: number;
  subject_name: string;
}

export interface GetResidentSubjectResponse {
  subjects: ResidentSubject[];
}

const getSellerProfileResidentSubject = async () => {
  const { data } = await api.get<GetResidentSubjectResponse>('/api/seller-profile/v2/resident-subject');

  return data;
};

export interface SpecialtyForm {
  category_id: number;
  specialty_id: number;
}

export interface ResidentProjectForm extends Omit<ResidentProject, 'subject'> {
  subject_id: number;
}

export interface ResidentForm extends Omit<Resident, 'place'> {
  is_resident?: boolean | null;
  place_id: number | null;
}

export interface SaveSellerProfileFormBody {
  username: string;
  description: string;
  activity_area: number | null;
  specialty: SpecialtyForm[];
  skill: SelectedSkill[];
  ori_career: OriginalCareer[];
  career: Career[];
  total_career: number | null;
  education: Education[];
  license: License[];
  resident: ResidentForm;
  resident_project: ResidentProjectForm[];
  basic_hourly_pay: {
    basic_hourly_pay: number;
    is_consultation: boolean;
  };
}

const saveSellerProfile = async (formBody: SaveSellerProfileFormBody) => {
  const { data } = await api.post('/api/seller-profile/v2/seller-profiles', formBody);

  return data;
};

export interface UserThumbnailResponse {
  thumbnail: string;
  is_default_thumbnail: boolean;
}

const getUserThumbnail = async () => {
  const { data } = await api.get<UserThumbnailResponse>('/api/user/v2/me/thumbnail');

  return data;
};

export const sellerProfileApi = {
  getSellerProfileFormatSpecialty,
  getSellerProfileFormatSkillCategory,
  getSellerProfileActivityArea,
  getSellerProfileUserInfo,
  getSellerProfileResidentPlace,
  getSellerProfileResidentSubject,
  saveSellerProfile,
  getUserThumbnail,
};
