import type { ArticleType } from './article.type';
import type { ArticleDetailButtonType } from './buttonType';
import type { ArticleDetailDividerType } from './divider.type';
import type { ArticleDetailGigType } from './gig.type';
import type { ArticleDetailImageType } from './image.type';
import type { ArticleDetailPortfolioType } from './portfolio.type';
import type { ArticleDetailTextType } from './text.type';
import type { ArticleDetailVideoType } from './video.type';

export enum ArticleDetailModuleType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  GIG = 'GIG',
  ARTICLE = 'ARTICLE',
  DIVIDER = 'DIVIDER',
  TEXT = 'TEXT',
  BUTTON = 'BUTTON',
  PORTFOLIO = 'PORTFOLIO',
}

export type ArticleDetailModule =
  | ArticleDetailGigType
  | ArticleType
  | ArticleDetailVideoType
  | ArticleDetailImageType
  | ArticleDetailDividerType
  | ArticleDetailTextType
  | ArticleDetailButtonType
  | ArticleDetailPortfolioType;
