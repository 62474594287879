import { useEffect, useState } from 'react';

const useWindowScrollTop = () => {
  const [state, setState] = useState(typeof window === 'undefined'
    ? true
    : window.pageYOffset === 0);

  function handler() {
    setState(window.pageYOffset === 0);
  }

  useEffect(() => {
    handler();
    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return state;
};

export default useWindowScrollTop;
