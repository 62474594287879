/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { defaultKeywords, familySites } from '@kmong/seo';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import { useStaticQuery, graphql } from 'gatsby';

export interface SEOProps {
  meta?: {
    name: string;
    content: string;
  }[];
  description?: string;
  title?: string;
  breadcrumbListElement?: {
    '@type': string;
    position: number;
    name: string;
    item?: string;
  }[];
  siteURL: string;
}

function SEO({
  title,
  description,
  meta = [],
  breadcrumbListElement = [],
  siteURL,
}: SEOProps) {
  const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `);

  const defaultBreadcrumbs: SEOProps['breadcrumbListElement'] = [
    {
      '@type': 'ListItem',
      position: 1,
      name: '메인',
      item: 'https://www.kmongcorp.com/',
    },
  ];

  return (
    <Helmet
      title={title ?? '회사소개 - 크몽'}
      htmlAttributes={{
        lang: 'ko-KR',
        xmlns: 'http://www.w3.org/1999/xhtml',
        prefix: 'og: http://ogp.me/ns#',
        'xml:lang': 'ko-KR',
      }}
      meta={[
        {
          name: 'description',
          content: description ?? '크몽은 긱 이코노미 시대의 노동시장 변화를 주도합니다. 고숙련 프리랜서의 전문성을 상품화하고, 유연하게 거래할 수 있는 시장을 구현합니다.',
        },
        {
          property: 'og:description',
          content: description ?? '크몽은 긱 이코노미 시대의 노동시장 변화를 주도합니다. 고숙련 프리랜서의 전문성을 상품화하고, 유연하게 거래할 수 있는 시장을 구현합니다.',
        },
        {
          property: 'og:title',
          content: title ?? defaultKeywords.title,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:site_name',
          content: 'kmongcorp.com',
        },
        {
          property: 'og:locale',
          content: 'ko_KR',
        },
        {
          property: 'og:url',
          content: siteURL,
        },
        {
          property: 'og:image',
          content:
            defaultKeywords.ogImage.url,
        },
        {
          property: 'og:image:secure_url',
          content:
            defaultKeywords.ogImage.url,
        },
        {
          property: 'og:image:type',
          content: 'image/jpeg',
        },
        {
          property: 'og:image:alt',
          content:
            defaultKeywords.title,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '1200',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: defaultKeywords.title,
        },
        {
          name: 'twitter:description',
          content: description ?? '크몽은 긱 이코노미 시대의 노동시장 변화를 주도합니다. 고숙련 프리랜서의 전문성을 상품화하고, 유연하게 거래할 수 있는 시장을 구현합니다.',
        },
        {
          name: 'naver-site-verification',
          content: 'd9e35a4927b84f35cf219904e1ea5bff76cf6e2c',
        },
        {
          name: 'google-site-verification',
          content: 'Qq8HA6gvJndnKGi0WTgYig4C20pC3u0dx9j1UeHGVaY',
        },
        {
          name: 'robots',
          content: 'index, follow',
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "url": "https://www.kmongcorp.com",
            "name": "kmongcorp.com",
            "alternateName": "kmongcorp",
            "description": "전문가가 필요한 순간 | 프리랜서 마켓 No.1, 크몽",
            "logo": "${CLOUDFRONT_URL}/assets/images/desktop/kmong-og-v2.jpg",
            "sameAs": ${familySites}
          }
        `}
      </script>

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: defaultBreadcrumbs.concat(breadcrumbListElement),
        })}
      </script>
    </Helmet>
  );
}

export default SEO;
