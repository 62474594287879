import api from '../api';
import type {
  SearchParams,
} from '../search';

export interface KnowhowAutoKeyword {
  keyword: string;
}

export interface KnowhowAutoCompleteResponse {
  keywords: KnowhowAutoKeyword[];
}

const getKnowhowAutoComplete = async ({ q = '' }: SearchParams) => {
  const { data } = await api.get<KnowhowAutoCompleteResponse>('/api/search/v5/keywords/autocomplete/knowhow', {
    params: { q },
  });

  return data;
};

export const searchApi = {
  getKnowhowAutoComplete,
};

export default searchApi;
