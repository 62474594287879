import api from '../api';

export interface GetFileDonwloadResponse {
  data: {
    url: string;
  };
  meta: {
    status: 1;
    message: string | null;
  };
}

const getFileDownload = async (FID: number) => {
  const { data } = await api.get<GetFileDonwloadResponse>(`/api/v5/files/download/${FID}`);

  return data;
};

export interface DirectoryItem {
  id: number;
  parent_id: number;
  depth: number;
  link: string;
  name: string;
  is_new?: boolean;
  icon?: {
    default: string;
    active: string;
  };
  category_id?: number;
  banner?: {
    id: number;
    category_id: number;
    link: string;
    type: string;
    type_data: string;
    image: string;
    title: string;
  };
  directories?: DirectoryItem[];
}

export interface GetMenuDirectoriesResponse {
  directories: Array<DirectoryItem>;
}

const getMenuDirectories = async () => {
  const { data } = await api.get<GetMenuDirectoriesResponse>('/api/knowhow/v1/menu/directories');

  return data;
};

export interface SideMenuItem {
  name: string;
  link: string;
}

export interface GetMenuSideResponse {
  sideMenu: {
    left: SideMenuItem[];
    right: SideMenuItem[];
  };
}

export interface KnowhowGlobalNavigationItem {
  title: string;
  link: string;
}

const getMenuSide = async () => {
  const { data } = await api.get<GetMenuSideResponse>('/api/knowhow/v1/menu/side');

  return data;
};

const commonApi = {
  getFileDownload,
  getMenuDirectories,
  getMenuSide,
};

export default commonApi;
