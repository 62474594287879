import type { SignupServiceType, SocialProviderType } from '@kmong/rest-client';
import { CLOUDFRONT_URL } from '@kmong-service/utils';

export interface SocialAuthProvider {
  name: SignupServiceType;
  upperName: SocialProviderType;
  koName: string;
  backgroundColor: string;
  image: string;
  disabledImage: string;
  hasBorder?: boolean;
}

export const SOCIAL_AUTH_PROVIDERS: SocialAuthProvider[] = [
  {
    name: 'naver',
    upperName: 'NAVER',
    koName: '네이버',
    backgroundColor: '#1EC800',
    image: `${CLOUDFRONT_URL}/assets/icon/naver-logo_v2.png`,
    disabledImage: `${CLOUDFRONT_URL}/assets/icon/naver-logo-disabled.png`,
  },
  {
    name: 'kakao',
    upperName: 'KAKAO',
    koName: '카카오톡',
    backgroundColor: '#f9e000',
    image: `${CLOUDFRONT_URL}/assets/icon/kakao-logo_v2.png`,
    disabledImage: `${CLOUDFRONT_URL}/assets/icon/kakao-logo-disabled.png`,
  },
  {
    name: 'facebook',
    upperName: 'FACEBOOK',
    koName: '페이스북',
    backgroundColor: '#1877F2',
    image: `${CLOUDFRONT_URL}/assets/icon/facebook-logo_v2.png`,
    disabledImage: `${CLOUDFRONT_URL}/assets/icon/facebook-logo-disabled.png`,
  },
  {
    name: 'google',
    upperName: 'GOOGLE',
    koName: '구글',
    backgroundColor: '#fff',
    hasBorder: true,
    image: `${CLOUDFRONT_URL}/assets/icon/google-logo_v2.png`,
    disabledImage: `${CLOUDFRONT_URL}/assets/icon/google-logo-disabled.png`,
  },
  {
    name: 'apple',
    upperName: 'APPLE',
    koName: '애플',
    backgroundColor: '#000',
    image: `${CLOUDFRONT_URL}/assets/icon/apple-logo_v2.png`,
    disabledImage: `${CLOUDFRONT_URL}/assets/icon/apple-logo-disabled.png`,
  },
];
