import api from './api';
import type { Gig } from './gig';

export interface GetInterestGigsResponse {
  title: string;
  gigs: Gig[];
}

interface GetInterestGigsParams {
  recently_viewed_gigs: number[];
}

export const getInterestGigs = async ({ recently_viewed_gigs }: GetInterestGigsParams) => {
  const { data } = await api.get<GetInterestGigsResponse>('/api/v5/gigs/recommend-gigs', { params: { recently_viewed_gigs } });

  return data;
};
