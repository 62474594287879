import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import { stringifySearchParams } from '../utils/search';
import type { Gig, GradeType, TempGig } from '../gig';
import type { PortfolioCardBase } from '../portfolio';

export type SearchResultType = 'original' | 'suggest';

export type SearchType = 'gigs' | 'sellers' | 'portfolios';

export type SearchEventType = 'services' | 'sellers' | 'portfolios';

export type SearchPortfolioPageType = 'portfolio_home' | 'search_result';

export type PortfolioSearchSort = 'RELEVANCE' | 'CREATED_AT' | 'RANKING_POINTS';

export type SearchSort =
  '_score' |
  'relevance' |
  'ratings' |
  'recommendation_point' |
  'ranking_points' |
  'created_at' |
  PortfolioSearchSort;

export type SearchParamsMetadata = Record<string, number[]>;

export interface SearchParams {
  q?: string;
  keyword?: string;
  type?: SearchType;
  sort?: SearchSort;
  category_id?: string;
  specialty_id?: string;
  skill_id?: string;
  is_prime?: boolean;
  is_online?: boolean;
  is_contactable?: boolean;
  is_fast_reaction?: boolean;
  is_company?: boolean;
  is_resident?: boolean;
  has_portfolio?: boolean;
  page?: number;
  portfolio_meta?: SearchParamsMetadata;
  metadata?: SearchParamsMetadata;
  service?: 'web';
  notSuggestSearch?: boolean;
  force_q?: string;
  min_price?: number;
  max_price?: number;
  grade?: string;
  /**
   * @deprecated ratings대신 grade로 네이밍 일괄 변경 필요
   * @link https://kmong.slack.com/archives/C052YEQK005/p1699518657285949?thread_ts=1699515242.487209&cid=C052YEQK005
   */
  ratings?: string;
  is_retargeting?: boolean;
  portfolio_id?: number;
  is_knowhow?: boolean;
  place_ids?: string;
  root_place_id?: string;
}

export interface PageResponse {
  total?: number;
  last_page?: number;
}

export interface GetKnowhowSearchResultResponse extends PageResponse {
  gigs?: Gig[];
  sellers?: SearchSeller[];
  origin_keyword?: string;
  replace_keyword?: string;
  related_keywords?: string[];
  searchType: SearchResultType;
}

const getKnowhowSearchResult = async (params: SearchParams, signal?: AbortSignal) => {
  const { data } = await api.get<GetKnowhowSearchResultResponse>('/api/search/v5/gigs', {
    params: { ...stringifySearchParams(params) },
    signal,
  });

  return data;
};

interface PageParams {
  page: number;
  perPage: number;
}

export interface GetSearchGigResultParams extends PageParams {
  keyword: string;
  isPrime: boolean;
  isFastReaction: boolean;
  isCompany: boolean;
  isNowContactable: boolean;
  hasPortfolios: boolean;
  categoryIds?: string;
  metadata?: SearchParamsMetadata;
  placeIds?: string;
  minPrice?: number;
  maxPrice?: number;
  grade?: string | null;
  sortType?: SortType;
  variation?: string;
  featureFlag?: string;
}

export enum SortType {
  SCORE = 'SCORE',
  RANKING = 'RANKING',
  RECOMMENDATION = 'RECOMMENDATION',
  CREATED_AT = 'CREATED_AT',
  RATINGS = 'RATINGS',
}

interface MSAPageResponse {
  perPage: number;
  lastPage: number;
  currentPage: number;
  totalItemCount: number;
}

export interface GetSearchGigResultResponse extends MSAPageResponse {
  relatedKeywords: string[];
  gigs: TempGig[];
}

const getSearchGigResult = async (params: GetSearchGigResultParams) => {
  const { data } = await api.get<GetSearchGigResultResponse>('/api/next/gig-app/gig/v1/gigs/search', {
    params: { ...stringifySearchParams(params) },
  });

  return data;
};

interface OngoingSearchKeyword {
  searchKeyword: string;
  searchKeywordUuid: string;
}

export interface GetSearchClickUpGigResultResponse {
  ongoingSearchKeyword: OngoingSearchKeyword | null;
  gigs: TempGig[];
}

const getSearchClickUpGigResult = async (searchKeyword: string) => {
  const { data } = await api.get<GetSearchClickUpGigResultResponse>('/api/next/gig-app/gig/v1/kmong-ad/click/search/gigs', {
    params: { searchKeyword },
  });

  return data;
};

export interface SearchSeller {
  USERID: number;
  activity_area: null | {
    name: string;
  };
  avg_response_time: string;
  basic_hourly_pay: number;
  description: string;
  career: null | {
    company: string;
    department: string;
    position: string;
    month: number;
    year: number;
  };
  grade: GradeType;
  has_portfolio: boolean;
  is_company: boolean;
  is_online: boolean;
  is_resident: boolean;
  is_fast_reaction: boolean;
  left_skill_count: number;
  left_specialty_count: number;
  orders_count: number;
  portfolio_count: number;
  ratings_average: number;
  ratings_count: number;
  satisfaction_point: number;
  skill_list?: {
    id: number;
    name: string;
  }[];
  specialty_list?: {
    id: number;
    name: string;
  }[];
  thumbnail: string;
  total_skill_count: number;
  total_specialty_count: number;
  username: string;
}

interface GetSearchSellerResultResponse extends PageResponse {
  gigs?: Gig[];
  sellers?: SearchSeller[];
  origin_keyword?: string;
  replace_keyword?: string;
  related_keywords?: string[];
  searchType: SearchResultType;
}

const getSearchSellerResult = async (params: SearchParams, signal?: AbortSignal) => {
  const { data } = await api.get<GetSearchSellerResultResponse>('/api/search/v5/sellers', {
    params: {
      ...stringifySearchParams(params),
    },
    signal,
  });

  return data;
};

export interface GetSearchPortfolioResultResponse {
  total: number;
  lastPage: number;
  portfolios: PortfolioCardBase[];
}

// 포트폴리오 검색 결과 불러오기
const getSearchPortfolioResult = async (params: SearchParams) => {
  const { data } = await api.get<GetSearchPortfolioResultResponse>('/api/search/v5/portfolios', {
    params: {
      ...stringifySearchParams(params),
    },
  });

  return data;
};

export interface MetadataCategory {
  category_id: number;
  category_name: string;
  count: number;
  child_category: MetadataCategory[] | null;
}

export interface GetSearchMetadataCategoryResponse {
  category: MetadataCategory[];
}

const getKnowhowSearchGigCategory = async (params: SearchParams) => {
  const { data } = await api.get<GetSearchMetadataCategoryResponse>('/api/search/v5/gigs/categories', { params });

  return data;
};

const getSearchSellerCategory = async (params: SearchParams) => {
  const { data } = await api.get<GetSearchMetadataCategoryResponse>('/api/search/v5/sellers/categories', {
    params,
  });

  return data;
};

export interface SearchGigCategories {
  childCategories: SearchGigCategories[] | null;
  categoryName: string;
  totalCategoryCount: number;
  categoryId: number;
}

export interface GetSearchGigCategoryResponse {
  categories: SearchGigCategories[];
}

const getSearchGigCategory = async (params: GetSearchGigResultParams) => {
  const { data } = await api.get<GetSearchGigCategoryResponse>('/api/next/gig-app/gig/v1/gigs/search/categories', {
    params,
  });

  return data;
};

export interface GetSearchPortfolioCategoriesResponse {
  categories: SearchPortfolioCategory[];
}

export type SearchPortfolioRootCategory = Omit<SearchPortfolioCategory, 'subCategories'>;
export interface SearchPortfolioCategory {
  rootCategoryId: number;
  rootCategoryName: string;
  rootCategoryCount: number;
  subCategories: SearchPortfolioSubCategory[];
}

export interface SearchPortfolioSubCategory {
  subCategoryId: number;
  subCategoryName: string;
  subCategoryCount: number;
}

const getSearchPortfolioCategories = async (q?: string) => {
  const { data } = await api.get<GetSearchPortfolioCategoriesResponse>('/api/search/v5/portfolios/categories', {
    params: { q: q ?? '' },
  });

  return data;
};

export interface GetSearchMetaDataResponse {
  metadata: SearchMetadata[];
}

export interface GigMetaData {
  id: number;
  metadata_id: number;
  name: string;
}

export interface SearchMetadata {
  gig_data: GigMetaData[];
  gig_metadata_id: number;
  id: number;
  name: string;
}

const getSearchMetaData = async (categoryId: number) => {
  const { data } = await api.get<GetSearchMetaDataResponse>(`/api/search/v5/gigs/filters/metadata/${categoryId}`);

  return data;
};

export interface SellerItem {
  id: number;
  name: string;
  count: number;
}

export interface GetSearchSkillResponse {
  skill_list: SellerItem[];
}

// 전문가 스킬 가져오기
const getSearchSkill = async (categoryId: number, q: string) => {
  const { data } = await api.get<GetSearchSkillResponse>(`/api/search/v5/sellers/skills/${categoryId}?q=${q}`);

  return data;
};

export interface GetSearchSpecialtyResponse {
  specialty_list: SellerItem[];
}

// 전문가 전문분야 가져오기
const getSearchSpecialty = async (categoryId: number, q: string) => {
  const { data } = await api.get<GetSearchSpecialtyResponse>(`/api/search/v5/sellers/specialties/${categoryId}`, {
    params: { q },
  });

  return data;
};

export interface SearchBannerData {
  bannerId: number;
  desktopImage: string | null;
  desktopBackgroundColor: string | null;
  desktopLink: string;
  mobileImage: string | null;
  mobileBackgroundColor: string | null;
  mobileLink: string;
}

export interface GetSearchAutoCompleteParams {
  keyword: string;
}

export interface GetSearchAutoCompleteResponse {
  keywords: string[];
}

const getSearchAutoComplete = async (params: GetSearchAutoCompleteParams, signal?: AbortSignal) => {
  try {
    const { data } = await api.get<GetSearchAutoCompleteResponse>('/api/next/gig-app/search/v1/search/autocomplete', {
      params,
      signal,
    });

    return data.keywords;
  } catch {
    return [];
  }
};

export interface GetMatchedSellerParams {
  q: string;
}

export type GetMatchedSellerResponse = {
  USERID: number;
  username: string;
} | null;

const getMatchedSeller = async (params: GetMatchedSellerParams, signal?: AbortSignal) => {
  try {
    const { data } = await api.get<GetMatchedSellerResponse>('/api/search/v5/sellers/nickname', {
      params,
      signal,
    });

    if (!data) {
      return null;
    }

    return data;
  } catch {
    return null;
  }
};

export interface BudgetScopeType {
  min: number;
  max: number;
}

export type GetBudgetScopeResponse = BudgetScopeType[];

const getBudgetScope = async () => {
  const response = await fetch(`${CLOUDFRONT_URL}/assets/search/budgets.json`);

  return response.json() as Promise<GetBudgetScopeResponse>;
};

interface GetKnowhowSearchResultCountResponse {
  total: number;
  searchType: SearchResultType;
}

const getKnowhowSearchResultCount = async (params: SearchParams) => {
  const { data } = await api.get<GetKnowhowSearchResultCountResponse>('/api/search/v5/gigs/count', {
    params: {
      ...stringifySearchParams(params),
    },
  });

  return data;
};

export interface GetSuggestedKeywordsResponse {
  keywords: string[];
}

const getSuggestedKeywords = async (categoryId: number | null) => {
  const { data } = await api.get<GetSuggestedKeywordsResponse>('/api/next/gig-app/search/v1/search/suggested-keywords', {
    params: { categoryId },
  });

  return data?.keywords ?? [];
};

export interface GigIdWithTakeCountParams {
  gigId: number;
  takeCount: number;
}

interface GigsResponse {
  gigs: TempGig[];
}

const getSimilarViewedGigsThirdCategory = async ({
  gigId,
  takeCount,
}: GigIdWithTakeCountParams) => {
  const { data } = await api.get<GigsResponse>('/api/next/gig-app/gig/v1/similar-viewed-gigs/third-category', {
    params: {
      gigId,
      takeCount,
    },
  });

  return data?.gigs ?? [];
};

export interface SellerProfilesSearchGigs {
  userId: number;
  nickname: string;
  thumbnail: string;
  reviewAverage: number;
  reviewCount: number;
  isCompany: boolean;
  isOnline: boolean;
  isResident: boolean;
  hasPortfolios: boolean;
  portfolioCount: number;
}

export interface GetSellerProfilesSearchGigsResponse extends SellerProfilesSearchGigs {
  gigs: TempGig[];
}

const getSellerProfilesSearchGigs = async (keyword: string) => {
  const { data } = await api.get<GetSellerProfilesSearchGigsResponse>('/api/next/gig-app/seller-profile/v1/seller-profiles/search/gigs', {
    params: {
      keyword,
    },
  });

  return data;
};

export const searchApi = {
  getSearchGigResult,
  getSearchSellerResult,
  getSearchPortfolioResult,
  getKnowhowSearchGigCategory,
  getSearchPortfolioCategories,
  getSearchSkill,
  getSearchSpecialty,
  getSearchMetaData,
  getSearchAutoComplete,
  getMatchedSeller,
  getBudgetScope,
  getSuggestedKeywords,
  getSimilarViewedGigsThirdCategory,
  getSellerProfilesSearchGigs,
  getKnowhowSearchResult,
  getKnowhowSearchResultCount,
  getSearchClickUpGigResult,
  getSearchGigCategory,
  getSearchSellerCategory,
};
