import escape from 'lodash/escape';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Response = Record<string, any>;

export function escapeFields<T extends Response>(payload: T, fields: (keyof T)[]) {
  return fields.reduce<T>((acc, field) => ({
    ...acc,
    [field]: escape(acc[field]),
  }), payload);
}
