interface GetBecomeASellerSEOProps {
  totalOrderCount: number;
  totalUserCount: number;
}

export const getBecomeASellerSEO = ({
  totalOrderCount,
  totalUserCount,
}: GetBecomeASellerSEOProps) => ({
  title: '외주·일감이 필요한 프리랜서 & n잡러라면, 크몽!',
  description: `상위 10% 월평균 수익 663만원, 누적 거래 ${totalOrderCount}만 건, 누적 회원 ${totalUserCount}만 명! 프리랜서 마켓 No.1 크몽에서 의뢰인을 만나보세요.`,
  breadcrumbs: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      name: '크몽 전문가 유도 페이지',
      position: 2,
    },
  ],
});
