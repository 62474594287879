/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button, ChevronLeftIcon, ChevronRightIcon, colors,
} from '@kmong/ui';

export interface PaginationProps {
  /**
   * 현재 페이지 값
   */
  current?: number;
  /**
   * 전체 페이지 수
   */
  total?: number;
  /**
   * 보여지는 페이지 범위
   */
  pageRange?: number;
  /**
   * 여백을 표시하는 페이지 수
   */
  marginRange?: number;
  /**
   * 줄임표 레이블
   */
  breakLabel?: string;
  /**
   * 페이지를 클릭 할 때 호출 할 메서드입니다. 현재 페이지 개체를 인수로 노출합니다.
   */
  onChange?: (currentPage: number) => void;
}

type GetPaginationValuesProps = string | number;

const getPaginationValues = ({
  current: currentPage = 1,
  pageRange = 5,
  marginRange = 1,
  total = 1,
  breakLabel = '…',
}: PaginationProps): GetPaginationValuesProps[] => {
  const left = Math.floor(currentPage - (pageRange - 1) / 2);
  const right = Math.ceil(currentPage + (pageRange - 1) / 2);
  const range: number[] = [];
  const rangeWithBreak: (number | string)[] = [];
  let last;
  for (let i = 1; i <= total; i += 1) {
    if (
      i <= marginRange
      || i > total - marginRange
      || (i >= left && i <= right)
    ) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (last) {
      if (i - last === 2) {
        rangeWithBreak.push(last + 1);
      } else if (i - last !== 1) {
        rangeWithBreak.push(breakLabel);
      }
    }
    rangeWithBreak.push(i);
    last = i;
  }

  return rangeWithBreak;
};

function Pagination({
  current = 1,
  total = 1,
  pageRange = 5,
  marginRange = 1,
  breakLabel = '…',
  onChange,
}: PaginationProps) {
  const [currentPage, setCurrent] = useState(0);
  const [paginationValues, setPaginationValues] = useState<GetPaginationValuesProps[]>([]);

  useEffect(() => {
    setCurrent(current);
  }, [current]);

  useEffect(() => {
    setPaginationValues(getPaginationValues({
      current: currentPage,
      total,
      pageRange,
      marginRange,
      breakLabel,
    }));
  }, [breakLabel, currentPage, marginRange, pageRange, total]);

  const handleChange = useCallback(
    (currentValue: GetPaginationValuesProps) => () => {
      if (currentPage === currentValue) {
        return;
      }

      setCurrent(Number(currentValue));
      if (onChange) {
        onChange(Number(currentValue));
      }
    },
    [onChange, currentPage],
  );

  return (
    <PaginationBlock>
      <Pager>
        <PagerButton
          data-testid="pager-left-button"
          disabled={currentPage === 1}
          variant="icon"
          onClick={handleChange(currentPage - 1)}
        >
          <ChevronLeftIcon />
        </PagerButton>
      </Pager>

      {paginationValues.map((item, index) => (
        <Pager key={index}>
          {item === breakLabel ? (
            <BreakLabel
              disabled
              size="xsmall"
            >
              {breakLabel}
            </BreakLabel>
          ) : (
            <PagerButton
              active={currentPage === item}
              size="xsmall"
              variant="text"
              data-testid={
                currentPage === item ? 'pager-active-button' : 'pager-button'
              }
              onClick={handleChange(item)}
            >
              {item}
            </PagerButton>
          )}
        </Pager>
      ))}

      <Pager>
        <PagerButton
          data-testid="pager-right-button"
          disabled={currentPage === total}
          variant="icon"
          onClick={handleChange(currentPage + 1)}
        >
          <ChevronRightIcon />
        </PagerButton>
      </Pager>
    </PaginationBlock>
  );
}

const PaginationBlock = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
`;

const Pager = styled.li`
  display: inline-flex;
  padding: 0 1px;
`;

const PagerButton = styled(Button)<{ active?: boolean }>`
  color: ${colors.gray[500]};
  border: none;
  min-width: 36px;
  height: 36px;

  ${({ active }) => active
    && css`
      color: ${colors.gray[900]};

      &:hover,
      &:active,
      &:focus {
        background: none;
        cursor: default;
      }
    `}

  &:disabled {
    color: ${colors.gray[300]};
    background: none;

    svg {
      color: ${colors.gray[300]};
    }
  }

  &:focus {
    box-shadow: none;
  }
`;

const BreakLabel = styled(PagerButton)`
  &:disabled {
    cursor: default;
  }
`;

export default Pagination;
