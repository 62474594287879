import type { LinkInfo } from '@kmong/rest-client';

export const getLandingTarget = (linkInfo: LinkInfo) => {
  if (!linkInfo) {
    return '';
  }

  const { target, value } = linkInfo;

  switch (target) {
    case 'CATEGORY':
      return `/category/${value}`;
    case 'SEARCH':
      return `/search?keyword=${value}`;
    case 'GIG_DETAIL':
      return `/gig/${value}`;
    case 'WEB_VIEW':
    case 'DEEP_LINK':
    case 'EXTERNAL_LINK':
      return decodeURIComponent(value);
    default:
      return '';
  }
};

export const isNewTab = (target?: string) => target === 'EXTERNAL_LINK';
