import api from '../api';

interface PutTimeModificationResponse {
  orderId: number;
  status: string;
  updated_at: string;
}

interface PutTimeModificationParams {
  orderId: number;
  orderProgressId: number;
}

const putTimeModificationAccept = async ({ orderId, orderProgressId }: PutTimeModificationParams) => {
  await api.put<PutTimeModificationResponse>(`/api/order/v1/orders/${orderId}/progress/time-modification/accept`, { orderProgressId });
};

const putTimeModificationReject = async ({ orderId, orderProgressId }: PutTimeModificationParams) => {
  await api.put<PutTimeModificationResponse>(`/api/order/v1/orders/${orderId}/progress/time-modification/reject`, { orderProgressId });
};

export const orderTimeModificationApi = {
  putTimeModificationAccept,
  putTimeModificationReject,
};
