import type { GetSearchBannerResponse, SearchBannerData } from '@kmong/rest-client/src';

export const searchBannerMock: SearchBannerData = {
  bannerId: 1224,
  desktopImage: 'https://d2v80xjmx68n4w.cloudfront.net/assets/images/curated_contents/YpTG5_curated_content_item_search_banner_DESKTOP.jpg',
  desktopBackgroundColor: null,
  mobileImage: 'https://d2v80xjmx68n4w.cloudfront.net/assets/images/curated_contents/2OZgB_curated_content_item_search_banner_MOBILE.png',
  mobileBackgroundColor: '#ffd400',
  desktopLink: '/md-pick/470',
  mobileLink: 'kmong://kmong.com?v=mdp&mdpid=470',
};

export const searchBannerResponseMock: GetSearchBannerResponse = {
  banners: [
    searchBannerMock,
    {
      bannerId: 1225,
      desktopImage: '',
      desktopBackgroundColor: null,
      mobileImage: 'https://d2v80xjmx68n4w.cloudfront.net/assets/images/curated_contents/2OZgB_curated_content_item_search_banner_MOBILE.png',
      mobileBackgroundColor: '#ffd400',
      desktopLink: '/md-pick/470',
      mobileLink: 'kmong://kmong.com?v=mdp&mdpid=470',
    },
  ],
};
