export function urlToAnchorTag(inputText: string) {
  let replacedText = '';

  // URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;

  replacedText = inputText
    .replace(replacePattern1, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
    .replace(replacePattern2, '$1<a href="http://$2" target="_blank" rel="noopener noreferrer">$2</a>');

  return replacedText;
}
