import api from '../api';
import type { Gig } from '../gig';

export interface SearchParams {
  page?: number;
  sort?: string;
  company?: boolean;
  is_prime?: boolean;
  has_portfolio?: boolean;
  ratings?: string;
  random_number?: number;
  selected_gig_data?: string;
  is_fast_reaction?: boolean;
  id?: string;
  meta?: { [key: string]: number[] };
  categories?: number;
  meta_category_id?: number;
}

export interface BreadCrumbItem {
  id: number;
  name: string;
  link: string | null;
}

export type GetMenuBreadCrumbResponse = Array<BreadCrumbItem>

const getMenuBreadCrumb = async (id: number) => {
  const { data } = await api.get<GetMenuBreadCrumbResponse>(`/api/knowhow/v1/menu/bread-crumb/${id}`);

  return data;
};

export interface CategoryGigListParams {
  categoryId?: string;
  page?: string;
  sort?: string;
  online?: string;
  company?: string;
  is_prime?: string;
  has_portfolio?: string;
  is_fast_reaction?: string;
  ratings?: string;
  meta?: string;
  random_number?: number;
  categories?: string[];
}

export interface GetCategoryGigListResponse {
  total: number;
  last_page: number;
  random_number: number;
  gigs: Gig[];
}

const getCategoryGigList = async (params: CategoryGigListParams) => {
  const { data } = await api.get<GetCategoryGigListResponse>(
    '/api/knowhow/v1/categories/gigs',
    { params },
  );

  return data;
};

const categoryApi = {
  getCategoryGigList,
  getMenuBreadCrumb,
};

export default categoryApi;
