import api from './api';

export interface QuoteGuideProject {
  categoryId: string;
  projectName: string;
  taskPeriod: string;
  budget: number;
  manMonth: string;
  projectReadyStatus: string;
  features: string;
  techStacks: string;
  requirement: string;
  details: string;
  reference: string;
  imageUrl: string;
  isResident: boolean;
  isGovernment: boolean;
}

interface QuoteGuideCategory {
  id: string;
  description: string;
}

export interface GetQuoteGuideProjectsResponse {
  guides: QuoteGuideProject[];
  categories: QuoteGuideCategory[];
}

const getQuoteGuideProjects = async () => {
  const { data } = await api.get<GetQuoteGuideProjectsResponse>('/api/next/enterprise/v1/quote-guides');

  return data;
};

export const quoteGuideApi = {
  getQuoteGuideProjects,
};
