import { localStorage } from '../localStorage';
import type { LocalStorageKeyEnum } from '../localStorage';

/**
 * @description
 * 추후 코어쪽으로 옮겨야 함(localStorage key와 같이 관리)
 */

export interface CategorySet {
  index?: number;
  rootCategory: Category;
  subCategory: Category | null;
  thirdCategory: Category | null;
}

/**
 * @description
 * 추후 코어쪽으로 옮겨야 함(localStorage key와 같이 관리)
 */

interface Category {
  name: string;
  id: number;
}

type Key = LocalStorageKeyEnum.RECENTLY_VIEWED_CATEGORIES | LocalStorageKeyEnum.RECENTLY_VIEWED_CATEGORIES_MOBILE;

export const getRecentlyViewedCategorySets = (localStorageKey: Key) => {
  const categories = localStorage.getItem<CategorySet[]>(localStorageKey);

  return categories ?? [];
};

interface ParseRecentlyViewedCategoriesProps {
  category: CategorySet;
  recentlyViewedCategories: CategorySet[];
}

/**
 * @description 로컬스토리지 데스크탑(recentlyViewedCategories), 모바일(recentlyViewedCategoriesMobile)의 데이터 구조가 같다는 전제하에 동작합니다.
 */
export const parseRecentlyViewedCategories = ({
  category,
  recentlyViewedCategories,
}: ParseRecentlyViewedCategoriesProps): CategorySet[] => {
  const sameCategory = getSameCategory({ category, recentlyViewedCategories });
  if (sameCategory) {
    const filteredSameCategories = recentlyViewedCategories.filter(({ index }) => index !== sameCategory.index);
    const limitLengthCategories = getLimitLengthCategorySets(filteredSameCategories, category);

    return rearrangeIndex(limitLengthCategories);
  }

  const limitLengthCategories = getLimitLengthCategorySets(recentlyViewedCategories, category);

  return rearrangeIndex(limitLengthCategories);
};

interface GetSameCategory {
  category: CategorySet;
  recentlyViewedCategories: CategorySet[];
}

const getSameCategory = ({ category, recentlyViewedCategories }: GetSameCategory) => recentlyViewedCategories
  ?.find(({ rootCategory, subCategory, thirdCategory }) => ((thirdCategory && (thirdCategory?.id === category.thirdCategory?.id))
    || (!thirdCategory && category.subCategory?.id === subCategory?.id)
    || (!subCategory && category.rootCategory.id === rootCategory.id)
  ));

const rearrangeIndex = (recentlyViewedCategories: CategorySet[]) => recentlyViewedCategories.map((categories, index) => ({ ...categories, index }));
const getLimitLengthCategorySets = (categories: CategorySet[], currentCategory: CategorySet) => {
  if (categories.length === CATEGORIES_MAX_LENGTH) {
    categories.shift();
  }
  categories.push(currentCategory);

  return categories;
};

const CATEGORIES_MAX_LENGTH = 3;
