import type { Gig, Label, TempGig } from '@kmong/rest-client';

export function convertGigToTempGig(gig: Gig | TempGig): TempGig {
  if ('gigId' in gig) {
    return gig;
  }

  return {
    gigId: gig.PID,
    currentCategoryId: gig.category_id,
    category: {
      rootCategoryId: gig.cat1,
      rootCategoryName: gig.cat1_name,
      subCategoryId: gig.cat2,
      subCategoryName: gig.cat2_name,
      thirdCategoryId: gig.cat3,
      thirdCategoryName: gig.cat3_name,
    },
    title: gig.title,
    images: gig.additional_images.length ? gig.additional_images : [gig.image],
    price: gig.price ?? 0,
    seller: {
      userId: gig.user.USERID,
      nickname: gig.user.username,
      grade: gig.user.grade,
      thumbnail: gig.user.thumbnail,
      isOnline: gig.user.online,
      isAvailableTax: gig.user.is_company,
      isNowContactable: Boolean(gig.user.contactable?.contactable_status === 'ACCESSIBLE'),
      isFastReaction: gig.user.is_fast_reaction,
    },
    review: {
      reviewCount: gig.ratings_count,
      reviewAverage: gig.ratings_average,
    },
    advertisementType: gig.advertisement,
    isCurrentUserBookmark: gig.is_bookmark,
    hasVideos: gig.has_video,
    hasPackages: gig.has_packages,
    isPrime: gig.is_prime,
    labels: getConvertLabels(gig.labels),
    isAvailableCalling: gig.is_available_calling,
    gigCardUuid: gig.gig_card_uuid,
    kmongAd: {
      adId: gig.kmong_ad_id ?? null,
      adGroupType: gig.kmong_ad_group_type ?? null,
    },
  };
}

const getConvertLabels = (labels: Label[]) => labels.reduce<TempGig['labels']>((acc, cur) => {
  if (cur.provider === 'KMONG_AWARDS') {
    return [...acc, 'KMONG_AWARDS'];
  }

  if (!cur.values?.length) {
    return acc;
  }

  return [...acc, ...(cur.values.map((value) => value.type))];
}, []);
