import type { PersonalizedGigTheme, ThemeId } from '@kmong/rest-client';

export interface GigModuleLink {
  themeId: ThemeId;
  description: string;
  minCount: number;
  showMoreMinCount: number;
}

export const gigModuleLinkMap: Record<PersonalizedGigTheme, GigModuleLink> = {
  'seller-other-gigs': {
    themeId: 180,
    description: '이 전문가의 다른 서비스',
    minCount: 1,
    showMoreMinCount: 20,
  },
  'recommend-viewed-gigs': {
    themeId: 181,
    description: '다른 고객이 함께 본 서비스',
    minCount: 5,
    showMoreMinCount: -1,
  },
  'top-order-gigs-sub-category': {
    themeId: 182,
    description: '2차 카테고리 Best 서비스',
    minCount: 10,
    showMoreMinCount: -1,
  },
  'recent-viewed-gigs': {
    themeId: 183,
    description: '최근 본 서비스',
    minCount: 1,
    showMoreMinCount: -1,
  },
};
