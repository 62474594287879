import api from './api';
import type { Gig } from './gig';

export interface SurveyRequest {
  USERID: number | null;
  answers: SurveyAnswer[];
  version: number;
}

export type SurveyQuestionType = 'KIND' | 'BUDGET' | 'INTERESTING';

export interface SurveyAnswer {
  questionType: SurveyQuestionType;
  answer: Array<string | number>;
}

export interface SurveyAnswerResponse {
  surveyId: number;
}

const postRecommends = async (params: SurveyRequest) => {
  const { data } = await api.post<SurveyAnswerResponse>('/api/v5/marketing/recommend', params);

  return data;
};

export interface GetRecommendsResponse {
  planMoney: number;
  selectedBusinessType: string;
  recommendSummary: RecommendSummary[];
  categories: RecommendCategory[];
}

export interface RecommendSummary {
  planMoney: number;
  cat2: string;
  cat3: string | null;
  order: number;
  sectionId: number;
  title: string;
}

export interface RecommendCategory {
  cat1: number;
  cat1_name: string;
  cat2: number;
  cat2_name: string;
  cat3: number | null;
  cat3_name: string | null;
  current_category_id: number;
  gigs: Gig[];
  title: string;
}

const getRecommends = async (surveyId: string) => {
  const { data } = await api.get<GetRecommendsResponse>(`/api/curation/recommend/v1/recommends/${surveyId}`);

  return data;
};

export const businessDiagnosisApi = {
  postRecommends,
  getRecommends,
};
