import { CLOUDFRONT_URL } from '@kmong-service/utils';

export const defaultKeywords = {
  title: '실력 있는 전문가 찾기, 크몽',
  description: '마케팅·디자인·IT프로그래밍·영상 등 다양한 전문가를 크몽에서 찾아보세요.',
  ogImage: {
    url: `${CLOUDFRONT_URL}/assets/images/desktop/og_img_v1.jpg`,
    alt: '실력 있는 전문가 찾기, 크몽',
    width: 1200,
    height: 630,
  },
  organizationDescription: '마케팅·디자인·IT프로그래밍·영상 등 다양한 전문가를 크몽에서 찾아보세요.',
};
