import { isKmongV4Error, KmongErrorInstance } from '../types';
import type { ErrorData, IntegrationKmongErrorInstance, KmongErrorResponse } from '../types';
import type { KmongV4ErrorResponse } from '../types/kmong-error-legacy-instance.type';

/**
 * @todo 추후 swal이 kmong-service/ui로 이동된다면 이 함수도 kmong-service/utils로 이동시켜야 합니다.
 * @description 이 함수는 axios 의 response interceptor하여 401 에러가 발생하면 로그인 페이지로 이동시킵니다.
 */
export function withAuthorizationError(error: IntegrationKmongErrorInstance) {
  if (error.response?.config.url?.includes('/api/user/v3/me')) {
    return Promise.reject(error);
  }

  switch (error.response?.status) {
    case 401:

      /**
       * @see https://github.com/Kmong/kmong-frontend/pull/4950#discussion_r1171499086
       * @description 데스크탑에선 /?open=login_modal 이지만 모바일에선 /signup 페이지로 보내야합니다.
       * 현재 이 레벨에선 해당 api 가 모바일에서 호출했는지 데스크탑에서 호출했는지 알 수 없기 때문에 일단은 데스크탑 기준으로 처리하고
       * 모바일 redirect.js 에서 /?open=login_modal 로 접근할 경우 /signup 으로 redirect 되도록 처리했습니다.
       */
      window.location.assign(`/?open=login_modal&next_page=${encodeURIComponent(window.location.pathname + window.location.search)}`);
      break;
    default: {
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
}

export function withMigrateError(error: IntegrationKmongErrorInstance) {
  if (!error?.response?.data) {
    return error;
  }

  /**
   * @description data에서 404, 502 error 페이지 자체가 string 으로 오는 케이스 예외처리
   */
  if (typeof error.response.data === 'string') {
    return error;
  }

  if ('meta' in error.response.data) {
    return error;
  }

  const errorData = convertV4ErrorData(error);

  const migrateError = createErrorInstance(error, errorData);

  if (!migrateError.response) {
    return error;
  }

  if (isKmongV4Error(migrateError)) {
    return error;
  }

  if (!(migrateError.response.data?.message) || !(migrateError.response?.data)) {
    return error;
  }

  return new KmongErrorInstance(migrateError.response) as KmongErrorResponse;
}

/**
 * @param error 원본 에러 객체
 * @return 구 버전의 에러 객체를 새로운 MSA 에러 객체로 변환
 */
const convertV4ErrorData = (error: IntegrationKmongErrorInstance) => {
  if (!(error?.response?.data)) {
    return defaultErrorInstance;
  }

  if (!error.response) {
    return defaultErrorInstance;
  }

  if (!('errors' in error.response.data)) {
    return defaultErrorInstance;
  }

  const legacyErrorData = error.response.data.errors?.[0];

  if (!legacyErrorData || !legacyErrorData.code || !legacyErrorData.message) {
    return defaultErrorInstance;
  }

  return {
    code: legacyErrorData.code,
    message: legacyErrorData.message,
  } as ErrorData<number>;
};

/**
 * @param error 원본 에러 객체
 * @param errorData MSA 스펙으로 마이그레이션 된 에러 데이터

 * @returns 마이그레이션 된 에러 데이터 새로운 에러 객체를 리턴
 */
const createErrorInstance = (
  error: IntegrationKmongErrorInstance,
  errorData: ErrorData<string | number>,
) => {
  const errorInstance: IntegrationKmongErrorInstance = { ...error };

  if (!errorInstance.response?.data) {
    return error as KmongV4ErrorResponse;
  }

  if (('code' in errorInstance.response.data && 'message' in errorInstance.response.data)) {
    return error as KmongErrorResponse;
  }

  errorInstance.response.data = errorData as ErrorData<string>;

  return errorInstance as KmongErrorResponse;
};

const defaultErrorInstance: ErrorData<string> = {
  code: '',
  message: '',
};
