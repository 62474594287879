import api from '../api';

export interface GetRecentTaxRequestResponse {
  companyRegistrationNumber: string;
  companyName: string;
  companyRepresentativeName: string;
  companyAddress: string;
  companyType: string;
  companyKind: string;
  companyEmail: string;
  companyManagerName: string;
  companyManagerContact: string;
}

const getRecentTaxRequest = async () => {
  const { data } = await api.get<GetRecentTaxRequestResponse>('/api/next/order-app/order/v1/orders/tax-requests/recent-tax-request');

  return data;
};

export interface GetCompanyRegistrationNumberValidProps {
  companyName: string;
  companyRegistrationNumber: string;
}

export interface PostCompanyRegistrationNumberValidResponse {
  companyName: string;
  companyRegistrationNumber: string;
  taxType: string;
  taxTypeName: string;
  companyCategoryId: number;
  companyCategoryName: string;
}

const postCompanyRegistrationNumberValid = async (props: GetCompanyRegistrationNumberValidProps) => {
  const { data } = await api.post<PostCompanyRegistrationNumberValidResponse>('/api/organization/v1/organizations/business/company-registration-number/valid', props);

  return data;
};

export interface PostOrganizationsBusinessData {
  companyName: string;
  companyRegistrationNumber: string;
  companyCategoryId: number;
  representativeName: string;
  companyType: string;
  companyKind: string;
  companyAddress: string;
  companyScaleId: number;
  taxType: 'TAX_TYPE_TAX' | 'TAX_TYPE_TAX_FREE' | 'TAX_TYPE_PERSONAL_SIMPLED' | 'TAX_TYPE_PERSONAL_SIMPLED_TAX' | 'TAX_TYPE_TAX_NON_PROFIT';
  companyRegistrationFileId: number;
  bankAccountFileId: number;
  department?: string | null;
  jobCategoryId?: number;
  jobGradeId?: number;
  isAgreedPersonalData: boolean;
}

const postOrganizationsBusiness = async (data: PostOrganizationsBusinessData) => api.post<GetRecentTaxRequestResponse>('/api/next/user-app/organization/v1/organizations/business', data);

export enum OrganizationStatus {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}

export interface GetOrganizationsBusinessResponse {
  organizationId: number;
  companyName: string;
  status: OrganizationStatus;
  rejectedReason: string | null;
  createdDateTime: string;
}

const getOrganizationsBusiness = async () => {
  const { data } = await api.get<GetOrganizationsBusinessResponse>('/api/next/user-app/organization/v1/organizations/business');

  return data;
};

interface ErrorMessage {
  title: string;
  description?: string;
}

export const businessAccountErrors: Record<string, ErrorMessage> = {
  ALREADY_EXIST_ORGANIZATION_COMPANY_REGISTRATION_NUMBER: {
    title: '이미 등록된 사업자 등록번호예요',
    description: '승인 상태를 확인해 주세요.',
  },
  DOESNT_EXIST_MOBILE_AUTHENTICATION: {
    title: '휴대폰 인증 정보가 존재하지 않아요',
    description: '휴대폰 인증을 다시 진행해 주세요.',
  },
  ALREADY_EXIST_BUSINESS_USER_MOBILE_AUTHENTICATION: {
    title: '이미 기업계정으로 가입된 휴대폰 인증 정보예요',
    description: '휴대폰 인증을 다시 진행해 주세요.',
  },
  REQUEST_ERROR_INCORRECT_REQUEST_PARAMETER: {
    title: '기업명, 사업자 등록번호를 확인해 주세요',
    description: '다시 입력해 주세요.',
  },
  USER_ERROR_HAS_PENALTY: {
    title: '페널티가 부과된 계정이에요',
  },
  ORGANIZATION_ERROR_ALREADY_EXIST_COMPANY_REGISTRATION_NUMBER: {
    title: '이미 등록된 기업계정이에요',
    description: '다시 입력해 주세요.',
  },
  ORGANIZATION_ERROR_ALREADY_REQUESTED_COMPANY_REGISTRATION_NUMBER: {
    title: '승인 대기 중인 사업자등록번호예요',
  },
  COMPANY_REGISTRATION_NUMBER_AUTH_ERROR_AUTHENTICATION_FAILED: {
    title: '사업자 인증에 실패했어요',
  },
  DOESNT_EXIST_INVITED_ORGANIZATION_BUSINESS_USER: {
    title: '초대된 회원이 아니에요',
  },
  CANCELED_INVITE_BUSINESS_USER: {
    title: '취소된 초대입니다',
    description: '기업계정 초대 상태를 다시 확인해 주세요.',
  },
  EXPIRED_INVITE_BUSINESS_USER: {
    title: '초대 수락 유효기간이 만료됐어요',
    description: '기업계정 초대 상태를 다시 확인해 주세요.',
  },
  DOESNT_EXIST_ORGANIZATION: {
    title: '기업계정 정보를 찾을 수 없어요',
    description: '기업계정 상태를 다시 확인해 주세요.',
  },
};

export const businessInviteErrors: Record<string, string> = {
  MAX_INVITED_BUSINESS_USER_EXCEED: '구성원은 최대 20명까지 초대할 수 있어요.',
  ALREADY_EXIST_ORGANIZATION_OWNER_BUSINESS_USER: '기업 계정을 신청 중인 회원은 초대할 수 없어요.',
  ALREADY_EXIST_ACTIVE_SAME_ORGANIZATION_BUSINESS_USER: '이미 \'구성원\'으로 활성화된 회원이에요.',
  ALREADY_EXIST_INVITED_OTHER_ORGANIZATION_BUSINESS_USER: '이미 기업 계정에 초대된 회원이라 초대할 수 없어요.',
  ALREADY_EXIST_INVITED_SAME_ORGANIZATION_BUSINESS_USER: '이미 기업 계정에 초대된 회원이라 초대할 수 없어요.',
  ALREADY_EXIST_ACTIVE_OTHER_ORGANIZATION_BUSINESS_USER: '이미 다른 기업의 구성원으로 활성화된 회원이라 초대할 수 없어요.',
  DOESNT_EXIST_BUSINESS_USER_ROLE_PERMISSION: '초대 권한이 없어요.',
  DOESNT_EXIST_USER: '아직 크몽에 가입하지 않은 회원이에요. 가입 후 초대해 주세요.',
};

export interface GetOrganizationProfileResponse {
  companyName: string;
  activeUserCount: number;
  invitedUserCount: number;
  isCorporation: boolean;
}

const getOrganizationProfile = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationProfileResponse>(`/api/next/user-app/organization/v1/organizations/${organizationId}/profile`);

  return data;
};

interface GetOrganizationTaxRequestResponse {
  companyName: string;
  companyRegistrationNumber: string;
  representativeName: string | null;
  companyType: string;
  companyKind: string;
  companyAddress: string | null;
  companyEmail: string | null;
  companyManagerName: string | null;
  companyManagerContact: string | null;
}

const getOrganizationTaxRequest = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationTaxRequestResponse>(`/api/next/user-app/organization/v1/organizations/${organizationId}/tax-request`);

  return data;
};

export interface PutOrganizationTaxRequestData {
  companyEmail: string | null;
  companyManagerName: string | null;
  companyManagerContact: string | null;
}

const putOrganizationTaxRequest = async (organizationId: number, data: PutOrganizationTaxRequestData) => api.put(`/api/next/user-app/organization/v1/organizations/${organizationId}/tax-request`, data);

interface GetOrganizationUserProfileResponse {
  email: string;
  nickname: string;
  realName: string | null;
  department: string | null;
  jobCategoryId: number | null;
  jobGradeId: number | null;
  isAgreedPersonalData: boolean;
}

const getOrganizationUserProfile = async () => {
  const { data } = await api.get<GetOrganizationUserProfileResponse>('/api/next/user-app/organization/v1/business-users/profile');

  return data;
};

export interface PutOrganizationUserProfileData {
  department: string | null;
  jobCategoryId: number | null;
  jobGradeId: number | null;
  isAgreedPersonalData: boolean;
}

const putOrganizationUserProfile = async (data: PutOrganizationUserProfileData) => api.put<GetOrganizationUserProfileResponse>('/api/next/user-app/organization/v1/business-users/profile', data);

export enum BusinessUserRoleLabels {
  OWNER = '대표',
  MANAGER = '매니저',
  BILLING_MANAGER = '재무매니저',
  MEMBER = '구성원',
}

export type BusinessUserRole = keyof typeof BusinessUserRoleLabels;

export interface PostBusinessUserInviteData {
  organizationId: number;
  roleType: BusinessUserRole;
  email: string;
  invitedMessage: string | null;
}

const postBusinessUserInvite = async (data: PostBusinessUserInviteData) => api.post<GetOrganizationUserProfileResponse>('/api/next/user-app/organization/v1/business-users/invite', data);

interface GetBusinessUsersEmailValidData {
  organizationId: number;
  email: string;
}

const postBusinessUsersEmailValid = async (data: GetBusinessUsersEmailValidData) => api.post('/api/next/user-app/organization/v1/business-users/email/valid', data);

interface GetOrganizationsBusinessDetailResponse {
  companyName: string;
  companyRegistrationNumber: string;
  representativeName: string | null;
  companyCategoryId: number;
  companyType: string;
  companyKind: string;
  companyAddress: string | null;
  companyScaleId: number;
  companyEmail: string | null;
  companyManagerName: string | null;
  companyManagerContact: string | null;
  activeUserCount: number;
  isCorporation: boolean;
}

const getOrganizationDetail = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationsBusinessDetailResponse>(`/api/next/user-app/organization/v1/organizations/${organizationId}`);

  return data;
};

export interface PutBusinessUsersRoleData {
  organizationId: number;
  userId: number;
  changeRoleType: BusinessUserRole;
}

const putBusinessUsersRole = async (data: PutBusinessUsersRoleData) => api.put('/api/next/user-app/organization/v1/business-users/role', data);

interface GetIsValidInvitedUserResponse {
  organizationId: number;
  companyName: string;
}

export enum BusinessInvitedExceptionRoutes {
  'INVALID_INVITE_BUSINESS_USER_TOKEN' = '/business/invited/invalid',
  'DOESNT_EXIST_INVITED_ORGANIZATION_BUSINESS_USER' = '/business/invited/invalid',
  'ALREADY_EXIST_ACTIVE_SAME_ORGANIZATION_BUSINESS_USER' = '/business/invited/already-exist',
  'CANCELED_INVITE_BUSINESS_USER' = '/business/invited/expired',
  'EXPIRED_INVITE_BUSINESS_USER' = '/business/invited/expired',
}

const getIsValidInvitedUser = async (organizationId: number, token: string) => {
  const { data } = await api.get<GetIsValidInvitedUserResponse>('/api/next/user-app/organization/v1/business-users/invite/accept/valid', {
    params: { organizationId, token },
  });

  return data;
};

export enum BusinessInvitedStatusLabel {
  REQUESTED = '수락 대기',
  ACTIVE = '활성화',
  INACTIVE = '비활성화',
}

export type BusinessInvitedStatus = keyof typeof BusinessInvitedStatusLabel;

interface BusinessUserListItem {
  businessUserId: number;
  userId: number;
  nickname: string;
  realName: string | null;
  thumbnail: string;
  email: string;
  roleId: number;
  roleType: BusinessUserRole;
  roleTypeLabel: string;
  status: BusinessInvitedStatus;
  joinedDateTime: string | null;
}

interface GetOrganizationBusinessUsersResponse {
  items: BusinessUserListItem[];
  perPage: number;
  lastPage: number;
  currentPage: number;
  totalItemCount: number;
}

const getOrganizationBusinessUsers = async (organizationId: number) => {
  const { data } = await api.get<GetOrganizationBusinessUsersResponse>('/api/next/user-app/organization/v1/business-users', { params: { organizationId, perPage: 20 } });

  return data;
};

interface DeleteOrganizationBusinessUserData {
  organizationId: number;
  userId: number;
  roleType: BusinessUserRole;
}

const deleteOrganizationBusinessUser = async (data: DeleteOrganizationBusinessUserData) => api.delete('/api/next/user-app/organization/v1/business-users/invite', { data });

export interface PostBusinessUsersInviteAcceptData {
  organizationId: number;
  isAgreedPersonalData: boolean;
  jobGradeId: number | null;
  jobCategoryId: number | null;
  department: string | null;
  token: string;
}

interface PostBusinessUsersInviteAcceptResponse {
  organizationId: number;
}

const postBusinessUsersInviteAccept = async (data: PostBusinessUsersInviteAcceptData) => {
  const response = await api.post<PostBusinessUsersInviteAcceptResponse>('/api/next/user-app/organization/v1/business-users/invite/accept', data);

  return response.data;
};

export const businessAccountApis = {
  getRecentTaxRequest,
  postCompanyRegistrationNumberValid,
  postOrganizationsBusiness,
  getOrganizationsBusiness,
  getOrganizationProfile,
  getOrganizationTaxRequest,
  putOrganizationTaxRequest,
  getOrganizationDetail,
  getOrganizationUserProfile,
  putOrganizationUserProfile,
  postBusinessUserInvite,
  postBusinessUsersEmailValid,
  putBusinessUsersRole,
  getIsValidInvitedUser,
  postBusinessUsersInviteAccept,
  getOrganizationBusinessUsers,
  deleteOrganizationBusinessUser,
};
