import type { GetSearchClickUpGigResultResponse } from '../search';

export const searchClickUpGigResultMock: GetSearchClickUpGigResultResponse = {
  gigs: [
    {
      gigId: 108976,
      currentCategoryId: 601,
      title: '홈페이지제작,최적의SEO,완벽한디자인 후회하지않는선택',
      price: 1320000,
      advertisementType: 'CPC_SEARCH',
      labels: [
        'KMONG_AWARDS',
      ],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/jydCm1679538134.jpg',
      ],
      seller: {
        userId: 25160,
        nickname: '더블유포지션',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/1RIwO1705906620.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 227,
      },
      category: {
        rootCategoryId: 6,
        rootCategoryName: 'IT·프로그래밍',
        subCategoryId: 601,
        subCategoryName: '홈페이지',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: false,
      isPrime: true,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060417040297101_55vrix',
      kmongAd: {
        adId: 12999309,
        adGroupType: 'CPC',
      },
    },
    {
      gigId: 238408,
      currentCategoryId: 101,
      title: '크몽공식 Prime 로고업체｜로고제작｜로고디자인',
      price: 90000,
      advertisementType: 'CPC_SEARCH',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/fbq611706354954.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/amOpl1706354965.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/rOhA11706354969.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/oV8Rt1706354973.jpg',
      ],
      seller: {
        userId: 968401,
        nickname: '로고이션',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/NmDNi1649437851.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 1470,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: true,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060417040299328_36kdv6',
      kmongAd: {
        adId: 12985800,
        adGroupType: 'CPC',
      },
    },
    {
      gigId: 384360,
      currentCategoryId: 101,
      title: 'CI BI 로고디자인 심플 깔끔 심볼 텍스트 로고제작',
      price: 80000,
      advertisementType: 'CPC_SEARCH',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/iJRHo1713169406.jpg',
      ],
      seller: {
        userId: 1980880,
        nickname: '일원리테일',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/l1x8y1653898834.jpeg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: false,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.5,
        reviewCount: 16,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060417040300950_y0pkog',
      kmongAd: {
        adId: 12987187,
        adGroupType: 'CPC',
      },
    },
    {
      gigId: 346125,
      currentCategoryId: 101,
      title: '24시간 젊은 감각으로 젊은 로고를 제작해 드립니다.',
      price: 78000,
      advertisementType: 'CPC_SEARCH',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/RMhhT1709224015.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/B9qDL1709224028.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/0xods1709224034.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/YtTsE1709224062.jpg',
      ],
      seller: {
        userId: 668624,
        nickname: 'LILALOGO',
        grade: 'LEVEL3',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/9TKRX1707668106.png',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 114,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060417040302609_by9nlu',
      kmongAd: {
        adId: 12986941,
        adGroupType: 'CPC',
      },
    },
    {
      gigId: 546346,
      currentCategoryId: 10601,
      title: '모든 PPT를 세련되게 만들어드립니다.',
      price: 10000,
      advertisementType: 'CPC_SEARCH',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/f63Zu1708082925.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/ScWTQ1708344527.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/cPSrX1708344536.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/TTNxU1708344544.jpg',
      ],
      seller: {
        userId: 2375320,
        nickname: '잉글레이션',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/E0kjD1669114443.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.8,
        reviewCount: 187,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 106,
        subCategoryName: 'PPT·인포그래픽',
        thirdCategoryId: 10601,
        thirdCategoryName: 'PPT',
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060417040305119_7jctia',
      kmongAd: {
        adId: 12986080,
        adGroupType: 'CPC',
      },
    },
  ],
  ongoingSearchKeyword: {
    searchKeywordUuid: '07479be0-e736-11ee-bcae-06e60c09dc13',
    searchKeyword: '디자인',
  },
};
