import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, colors } from '@kmong/ui';
import layoutBase from '../../lib/styles/layoutBase';
import SectionCopyOrigin from './SectionCopy';
import SectionDescriptionOrigin from './SectionDescription';
import View from './View';

function SellerIntroSection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <SellerIntroSectionBlock>
      <Main
        ref={ref}
        inView={inView}
      >
        <ContentSection>
          <SectionCopy>
            나는 전문가입니다
          </SectionCopy>
          <View platform="desktop">
            <SectionDescription>
              전문성을 수익으로 연결할 수 있도록 크몽이 함께합니다.
            </SectionDescription>
          </View>
          <View platform="mobile">
            <SectionDescription>
              전문성을 갖추기 위한 각 분야 전문가들의 노력이
              <br />
              빛날 수 있도록 크몽이 함께 합니다.
            </SectionDescription>
          </View>
        </ContentSection>
        <VideoSection>
          <VideoIframeWrapper>
            <iframe
              allowFullScreen
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              src="https://www.youtube.com/embed/S-8ZQ4lPkiA"
              title="나는 전문가입니다"
            />
          </VideoIframeWrapper>
        </VideoSection>
      </Main>
    </SellerIntroSectionBlock>
  );
}

const SellerIntroSectionBlock = styled.section`
  background-color: ${colors.gray[800]};
  padding: 128px 16px;

  @media screen and (max-width: ${breakpoints.medium}px){
    padding: 64px 0;
  }
`;

const Main = styled.div<{ inView: boolean }>`
  ${layoutBase};
    
  @media screen and (max-width: ${breakpoints.medium}px){
    padding: 0;
  }
  
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}
`;

const ContentSection = styled.div`
  
`;

const VideoSection = styled.div`
  margin: 40px 0 0 0;
  
  @media screen and (max-width: ${breakpoints.medium}px){
    margin: 56px 0 0 0;
  }
`;

const VideoIframeWrapper = styled.div`
  position: relative;
  width: 1076px;
  margin: 0 auto;
  padding-bottom: 56.25%;

  iframe {
    border: none;
    position: absolute;
    width: 100%;
    height: 100%
  }

  @media screen and (max-width: ${breakpoints.medium}px){
    width: 100%;
  }

`;

const SectionCopy = styled(SectionCopyOrigin)`
  text-align: center;
  color: ${colors.gray[100]};
`;

const SectionDescription = styled(SectionDescriptionOrigin)`
  text-align: center;
  color: ${colors.gray[100]};
  margin: 0 0 16px 0;
`;

export default SellerIntroSection;
