import type {
  Best,
  BestSearchSort,
  RootCategory,
} from '@kmong/rest-client';
import { EMPTY_SPACE_REGEX, HANGUL_ENGLISH_NUMBER_REGEX, MULTIPLE_EMPTY_SPACE_REGEX } from '@kmong/utils';

export interface GetBestGigProps {
  bestList: Best[];
  bestId: number;
}

const defaultBestGig: Best = {
  id: 0,
  keyword: '',
  rootCategoryId: 0,
  heroTitle: '',
  SEO: { title: '', description: '' },
  searchKeyword: '',
  searchSort: '_score',
  averagePrice: 0,
  orderIds: [],
  FAQList: [],
};
export const getBestGig = ({ bestList, bestId }: GetBestGigProps): Best => bestList.find((best) => best.id === bestId) ?? defaultBestGig;

export interface BestSearchParams {
  q?: string;
  keyword?: string;
  type?: 'gigs';
  sort?: BestSearchSort;
  category_id?: string;
  page?: number;
  service?: 'web';
}

export const getSearchParams = (bestGig: Best): BestSearchParams => ({
  type: 'gigs',
  page: 1,
  sort: bestGig?.searchSort,
  service: 'web',
  keyword: bestGig?.searchKeyword,
  q: bestGig?.searchKeyword,
  category_id: `${bestGig?.rootCategoryId}`,
});

interface GetFilteredBestListProps {
  bestList: Best[];
  bestId: number;
  limit?: number;
}

export const getFilteredBestList = ({ bestList, bestId, limit = bestList.length }: GetFilteredBestListProps):
  Best[] => bestList.filter((best) => best.id !== bestId).slice(0, limit);

interface GetRootCategoryProps {
  rootCategoryList: RootCategory[];
  rootCategoryId: number;
}
const defaultRootCategory: RootCategory = {
  id: 0,
  name: '',
  imageURL: '',
  mainColor: '',
};
export const getRootCategory = ({ rootCategoryList, rootCategoryId }: GetRootCategoryProps): RootCategory => rootCategoryList
  .find((rootCategory) => rootCategory.id === rootCategoryId) ?? defaultRootCategory;

interface GetBestGigUrlProps {
  bestId: number;
  bestTitle: string;
}
export const getBestGigUrl = ({ bestId, bestTitle }: GetBestGigUrlProps): string => {
  const replacebestTitle = bestTitle
    .replace(HANGUL_ENGLISH_NUMBER_REGEX, ' ')
    .replace(MULTIPLE_EMPTY_SPACE_REGEX, ' ')
    .trim()
    .replace(EMPTY_SPACE_REGEX, '-');

  return `/best-gig/${bestId}--${replacebestTitle}`;
};
