import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, Button } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import layoutBase from '../../lib/styles/layoutBase';
import SectionCopy from './SectionCopy';
import SectionDescription from './SectionDescription';
import SectionTitle from './SectionTitle';
import View from './View';

const MarketSectionBlock = styled.section`
  padding: 128px 16px 62px 16px;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 64px 20px 20px 20px;
  }
`;

const Main = styled.div`
  ${layoutBase};
  display: flex;

  @media screen and (max-width: ${breakpoints.large}px){
    display: block;
    padding: 0;
  }
`;

const ContentSection = styled.div<{ inView: boolean }>`
  flex: 1;
  opacity: 0;
  transform: translateX(-40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateX(0);
    opacity: 1;
  `}
`;

const ImageSection = styled.div<{ inView: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.large}px){
    margin: 56px 0 0 0;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}
`;

function MarketSection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <MarketSectionBlock>
      <Main ref={ref}>
        <ContentSection inView={inView}>
          <SectionTitle>국내 최대 비즈니스 플랫폼</SectionTitle>
          <SectionCopy>
            프리랜서 전문가에게 수익을
            <br />
            의뢰인에게 비즈니스 성공을
          </SectionCopy>
          <SectionDescription>
            크몽은 IT·디자인·마케팅 등 다양한 카테고리의
            <br />
            전문성을 상품화하여 거래할 수 있는 마켓입니다.
          </SectionDescription>
          <View platform="desktop">
            <StyledButton
              color="yellow"
              href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/"
              size="xlarge"
              variant="contained"
              css={css`
                width: 238px;
              `}
            >
              서비스 둘러보기
            </StyledButton>
          </View>
        </ContentSection>
        <ImageSection inView={inView}>
          <img
            alt="크몽 마켓"
            src={`${CLOUDFRONT_URL}/assets/kmong_is/main-img-mobile.webp`}
            srcSet={`${CLOUDFRONT_URL}/assets/kmong_is/main-img-mobile@2x.webp 2x,
             ${CLOUDFRONT_URL}/assets/kmong_is/main-img-mobile@3x.webp 3x`}
          />
        </ImageSection>
        <View platform="mobile">
          <StyledButton
            fullWidth
            color="yellow"
            href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/"
            size="xlarge"
            variant="contained"
          >
            서비스 둘러보기
          </StyledButton>
        </View>
      </Main>
    </MarketSectionBlock>
  );
}

const StyledButton = styled(Button)`
  

  @media screen and (max-width: ${breakpoints.large}px){
    margin: 32px 0 0 0;
  }
`;

export default MarketSection;
