import { nl2br } from '@kmong/utils';

interface GetBestGigBreadCrumbJsonLdProps {
  title: string;
}
export const getBestGigBreadCrumbJsonLd = ({ title }: GetBestGigBreadCrumbJsonLdProps) => [
  {
    item: 'https://kmong.com',
    name: '홈',
    position: 1,
  }, {
    name: title,
    position: 2,
  },
];

interface FAQ {
  question: string;
  answer: string;
}

interface GetBestGigFAQJsonLdProps {
  FAQList: FAQ[];
}
export const getBestGigFAQJsonLd = ({ FAQList }: GetBestGigFAQJsonLdProps) => `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    ${FAQList.map((faqItem) => `{
      "@type": "Question",
      "name": "${faqItem.question}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${nl2br(faqItem.answer.replaceAll('"', '\''))}"
      }
    }`)}
   ]
}`;
