/**
 * 최솟값과 최댓값을 포함한 정수 범위에서 난수를 반환합니다.
 * @param min 최솟값
 * @param max 최댓값
 */
export function getRandomNumber(min: number, max: number) {
  const minNumber = Math.ceil(min);
  const maxNumber = Math.floor(max);

  return Math.floor(Math.random() * (maxNumber - minNumber + 1)) + minNumber;
}
