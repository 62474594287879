import api from '../api';
import type { InboxMessage } from './messages';
import type { ColorChipType } from './partners';

export type InboxGroupsType = 'GENERAL' | 'UNREAD' | 'ONGOING_ORDERS' | 'STARRED';
export type InboxGroupsKeywordType = 'NAME' | 'MESSAGE';

export interface GetInboxGroupsParams {
  type?: InboxGroupsType;
  keyword?: string;
  keyword_type?: InboxGroupsKeywordType;
  page?: number;
}

export interface GetInboxGroupsResponse {
  total: number;
  previous_page_link?: string | null;
  next_page_link?: string | null;
  last_page: number;
  inbox_groups: InboxGroup[];
}

export interface InboxGroup {
  id: number;
  USERID: number;
  created_at: string;
  message: string;
  group_created_at: string;
  /**
   * @description 가장 최근에 메세지를 발송한 유저가 나인지 여부, 미답변 체크 시 사용
   */
  is_mine: boolean;
  labels: InboxGroupLabel[];
  partner: InboxGroupPartner;
  is_starred: boolean;
  is_unread: boolean;
  inbox_group_id: number;
  group_started_userid: number;
  memo: string;
  colorChip: ColorChipType | null;
  sent_at: string;
}

export interface InboxGroupLabel {
  type: 'ONGOING_ORDERS' | 'REQUEST' | 'CUSTOM_PROJECT';
  name: '거래중' | '결제요청' | '프로젝트 의뢰';
}

interface InboxGroupPartner {
  USERID: number;
  username: string;
  image: string;
  is_online: boolean;
  vacated_at: string | null;
}

const getInboxGroups = async (params: GetInboxGroupsParams) => {
  const { data } = await api.get<GetInboxGroupsResponse>('/api/v5/inbox-groups', {
    params,
  });

  return data;
};

interface DeleteInboxGroupsParams {
  inbox_group_ids: number[];
}

interface DeleteInboxGroupsResponse {
  results: DeleteInboxGroupsObject[];
}

interface DeleteInboxGroupsObject {
  inbox_group_id: string;
  status: string;
}

const deleteInboxGroups = async (params: DeleteInboxGroupsParams) => api.delete<DeleteInboxGroupsResponse>('/api/v5/inbox-groups', { params });

interface PostInboxGroupStarredResponse {
  inbox_group_id: number;
  USERID: number;
  type: 'STARRED';
  updated_at: string;
  created_at: string;
  id: number;
}

const postInboxGroupStarred = async (inboxGroupId: number) => api.post<PostInboxGroupStarredResponse>(`/api/v5/inbox-groups/${inboxGroupId}/starred`);
const deleteInboxGroupStarred = async (inboxGroupId: number) => api.delete(`/api/v5/inbox-groups/${inboxGroupId}/starred`);

const putInboxGroupRead = async (inboxGroupId: number) => api.put(`/api/v5/inbox-groups/${inboxGroupId}/read`);

export interface PostInboxRequestDirectOrdersParams {
  inboxGroupId: number;
  inboxRequestId: number;
}

export interface PostInboxRequestDirectOrdersResponse {
  directOrderId: number;
}

export enum PostInboxRequestDirectOrdersErrorEnum {
  /**
   * 로그인한 회원의 채팅방번호가 잘못 들어온경우
   */
  INBOX_ERROR_DOENST_EXIST_INBOX_GROUP = 'INBOX_ERROR_DOENST_EXIST_INBOX_GROUP',
  /**
   * 결제요청 번호가 잘못 들어온 경우
   */
  INBOX_REQUEST_ERROR_DOENST_EXIST_INBOX_REQUEST = 'INBOX_REQUEST_ERROR_DOENST_EXIST_INBOX_REQUEST',
  /**
   * 이미 주문서를 생성했거나 주문이 생성된 경우
   */
  INBOX_REQUEST_ERROR_ALREADY_ORDERED = 'INBOX_REQUEST_ERROR_ALREADY_ORDERED',
  /**
   * 결제요청 번호에 메시지 번호가 잘못 매핑된 경우
   */
  INBOX_ERROR_DOENST_EXIST_MESSAGE = 'INBOX_ERROR_DOENST_EXIST_MESSAGE'
}

const postInboxRequestDirectOrders = async ({ inboxGroupId, inboxRequestId }: PostInboxRequestDirectOrdersParams) => {
  const { data } = await api.post<PostInboxRequestDirectOrdersResponse>(`/api/inbox/v1/inbox-groups/${inboxGroupId}/inbox-requests/${inboxRequestId}/direct-orders`);

  return data;
};
export interface GetInboxGroupLabelsInfoResponse {
  label: InboxLabelEachType | null;
  button: InboxGroupButton | null; // 판매중인 서비스가 있을 경우 내려줌
  isAvailableToSendMessage: boolean; // 입력창 활성/비활성화 여부
  /**
   * @deprecated 앱전용으로 사용하지 않음
   */
  isAvailableToChangeSellerContactableStatus: boolean; // (앱)전문가 상담 상태 UI 표시 유무
  isAvailableToShowAutoMessage: boolean; // 자동 응답 메세지 노출 여부
}

export type InboxLabelEachType = InboxLabelInquiry | InboxLabelRequest | InboxLabelPurchased;

export interface InboxLabelInquiry {
  type: InboxGroupLabelTypeEnum.INBOX_INQUIRY;
  id: null;
  gigId: number;
  directOrderId: null;
  orderId: null;
  content: string;
  amount: number;
  days: null;
  isHiddenButton: boolean;
  image: string;
  categoryId: number;
  rootCategoryId: number;
}

export interface InboxLabelRequest {
  type: InboxGroupLabelTypeEnum.INBOX_REQUEST;
  id: number;
  gigId: number;
  directOrderId: number;
  orderId: number;
  content: string;
  amount: number;
  days: number;
  isHiddenButton: boolean;
  image: string;
  categoryId: number;
  rootCategoryId: number;
}

interface InboxLabelPurchased {
  type: InboxGroupLabelTypeEnum.INBOX_PURCHASED;
  id: null;
  gigId: number;
  directOrderId: number;
  orderId: number;
  content: string;
  amount: null;
  days: null;
  isHiddenButton: boolean;
  image: string;
  categoryId: null;
  rootCategoryId: null;
}

interface InboxGroupButton {
  type: InboxGroupLabelTypeEnum.INBOX_REQUEST;
  name: string;
  gigs: InboxGroupButtonGig[];
}

interface InboxGroupButtonGig {
  gigId: number;
  rootCategoryId: number;
  categoryId: number;
  title: string;
  image: string;
  reviewCount: number;
  reviewAverage: number;
}

export enum InboxGroupLabelTypeEnum {
  INBOX_REQUEST = 'INBOX_REQUEST', // 결제 요청
  INBOX_INQUIRY = 'INBOX_INQUIRY', // 서비스에서 문의하기로 진입시
  INBOX_PURCHASED = 'INBOX_PURCHASED', // 거래중
  /**
   * @deprecated 사용하지 않음. (과거 데이터는 존재)
   */
  CUSTOM_PROJECT_REQUEST = 'CUSTOM_PROJECT_REQUEST',
}

const getInboxGroupLabelsInfo = async (inboxGroupId: number) => {
  const { data } = await api.get<GetInboxGroupLabelsInfoResponse>(`/api/inbox/v1/inbox-groups/${inboxGroupId}`);

  return data;
};

/**
 * @link: https://kmongteam.atlassian.net/wiki/spaces/DEV/pages/3121087864#%EC%95%88%EC%A0%84-%EA%B2%B0%EC%A0%9C-%EC%9A%94%EC%B2%AD-API
 */
interface PostInboxRequestPayload {
  inbox_request: PostInboxRequestObject;
  message?: string;
  index_id?: number;
  socket_id?: number;
}

export interface PostInboxRequestObject {
  PID: number;
  content: string;
  price: number;
  days: number;
}

const postInboxRequest = async (inboxGroupId: number, payload: PostInboxRequestPayload) => {
  const { data } = await api.post<InboxMessage>(`/api/v5/inbox-groups/${inboxGroupId}/inbox-request`, payload);

  return data;
};

export const inboxGroupsApi = {
  getInboxGroups,
  deleteInboxGroups,
  postInboxGroupStarred,
  deleteInboxGroupStarred,
  putInboxGroupRead,
  postInboxRequestDirectOrders,
  postInboxRequest,
  getInboxGroupLabelsInfo,
};
