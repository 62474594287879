import { css } from '@emotion/react';

const layoutBase = css`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
  word-break: keep-all;
`;

export default layoutBase;
