import type { User } from '@kmong/rest-client/src/auth';
import type { GetPenaltyResponse } from '@kmong/rest-client/src/user';

export const authStateOptions: {
  key: string;
  default: User | null;
} = {
  key: 'auth/authState',
  default: null,
};

export const isPendingAuthStateOptions = {
  key: 'auth/isPendingAuthState',
  default: false,
};

export const userPenaltyStateOptions: {
  key: string;
  default: GetPenaltyResponse | null;
} = {
  key: 'auth/userPenaltyState',
  default: null,
};

export const loginModalStateOptions = {
  key: 'auth/loginModalState',
  default: false,
};
