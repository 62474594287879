import { CookiesKeyEnum } from '@kmong-service/utils';

export const getAlreadySignupUsersCookie = (cookies?: string): string | null => {
  if (!cookies) {
    return null;
  }

  const alreadySignupUserCookie = findCookieByKey(cookies, CookiesKeyEnum.ALREADY_SIGNUP_USERS);

  if (!alreadySignupUserCookie) {
    return null;
  }

  return parseCookieValue(alreadySignupUserCookie);
};

const findCookieByKey = (cookies: string, key: string): string | null => {
  const matchedCookie = cookies.split(';').find((cookie) => {
    const [cookieKey] = cookie.split('=');

    return cookieKey.trim() === key;
  });

  return matchedCookie || null;
};

const parseCookieValue = (cookie: string): string | null => {
  const [, value] = cookie.split('=');

  return value ? JSON.parse(decodeURIComponent(value)) : null;
};
