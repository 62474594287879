import api from './api';

export interface ThumbsUp {
  heart_count: number;
  smile_count: number;
  thumbup_count: number;
}

export interface GetThumbsUpResponse {
  [key: string]: ThumbsUp;
}

const getThumbsUp = async () => {
  const { data } = await api.get<GetThumbsUpResponse>('/api/kmong-awards-2021/thumbs-up');

  return data;
};

export interface UpdateThumbsUpPayload {
  USERID: number;
  thumbs_up_type: 'HEART' | 'SMILE' | 'THUMBUP';
}

const updateThumbsUp = ({ USERID, thumbs_up_type }: UpdateThumbsUpPayload) => {
  api.put<GetThumbsUpResponse>('/api/kmong-awards-2021/thumbs-up', {
    USERID,
    thumbs_up_type,
  });
};

export interface GetSellerHistoryResponse {
  real_name: string;
  username: string;
  is_seller: boolean;
  first_gig_registration_date: string;
  seller_registration_date: string;
  inquiries_count: number;
  order_completed_count: number;
  profit_amount: number;
  top_ranking_percentage: number;
}

const getSellerHistory = async () => {
  const { data } = await api.get<GetSellerHistoryResponse>('/api/kmong-awards-2021/my-history');

  return data;
};

export const kmongAwards2021Api = {
  getThumbsUp,
  updateThumbsUp,
  getSellerHistory,
};
