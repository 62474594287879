import { CLOUDFRONT_URL } from '@kmong-service/utils';

export const freelancerClubSEO = {
  title: '크몽 프리랜서클럽',
  description: '프리랜서클럽은 프리랜서와 프리랜서를 꿈꾸는 모든 이들을 위한 성장 커뮤니티입니다. 프리랜서 시작하자! 잘하자! 오래하자!',
  breadcrumbs: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      name: '크몽 프리랜서클럽 홈',
      position: 2,
    },
  ],
  image: {
    url: `${CLOUDFRONT_URL}/assets/desktop/pages/freelancer-club/kmong_freelancer-club_v1_og.jpg`,
    alt: '크몽 프리랜서클럽',
    width: 800,
    height: 420,
  },
};
