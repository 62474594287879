import type { SignupServiceType, UserType } from '@kmong/rest-client/src/signup';

export interface SignUpState {
  step: 1 | 2 | 3;
  service: SignupServiceType;
  memberType: UserType | null;
  next_page: string;
  thumbnail: string;
  email: string;
  id: string;
  target: string;
}

export const signUpStateOptions: {
  key: string;
  default: SignUpState;
} = {
  key: 'signUp/signUpState',
  default: {
    step: 1,
    service: 'email',
    memberType: null,
    next_page: '/',
    thumbnail: '',
    email: '',
    id: '',
    target: '',
  },
};
