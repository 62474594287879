export const familySites = [
  'https://kmong.com',
  'https://www.facebook.com/kmongkorea/',
  'https://www.instagram.com/kmong_official/',
  'https://www.youtube.com/channel/UCUpdmwMWbAEtK7jf_7XztAw',
  'https://www.youtube.com/channel/UCaOGlL_px_qYIXQ6jEq0IwQ',
  'https://blog.kmong.com',
  'https://tv.naver.com/kmong',
  'https://play.google.com/store/apps/details?id=com.kmong.kmong&hl=ko',
  'https://itunes.apple.com/kr/app/id1039179300',
];
