import React, { useEffect, useMemo, useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { commonApi } from '@kmong/rest-client';
import { breakpoints, colors } from '@kmong/ui';
import layoutBase from '../../lib/styles/layoutBase';

function AwardsSection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [data, setData] = useState<{
    totalUserCount: number;
    totalRatingAverage: number;
    totalSellerCount: number;
    totalOrderCount: number;
  }>({
    totalUserCount: 0,
    totalRatingAverage: 0,
    totalSellerCount: 0,
    totalOrderCount: 0,
  });

  useEffect(() => {
    (async () => {
      const res = await commonApi.getMarketingReports();

      const {
        total_user_count,
        total_rating_average,
        total_seller_count,
        total_order_count,
      } = res;

      setData({
        totalUserCount: total_user_count,
        totalRatingAverage: total_rating_average,
        totalSellerCount: total_seller_count,
        totalOrderCount: total_order_count,
      });
    })();
  }, []);

  const STATISTICS_ITEMS = useMemo(() => [{
    key: 'users',
    title: '누적 회원수',
    value: data.totalUserCount,
    decimals: 0,
    unit: '만 명+',
  }, {
    key: 'incomes',
    title: '평균 만족도',
    value: data.totalRatingAverage,
    decimals: 1,
    unit: '%+',
  },
  {
    key: 'transactions',
    title: '누적 거래건 수',
    value: data.totalOrderCount,
    decimals: 0,
    unit: '만 건+',
  }], [data]);

  return (
    <AwardsSectionBlock>
      <Main
        ref={ref}
        inView={inView}
      >
        {STATISTICS_ITEMS.map((item) => (
          <Column key={item.key}>
            <Title>{item.title}</Title>
            {inView ? (
              <CountUp
                decimals={item.decimals}
                delay={0}
                end={item.value}
                start={0}
              >
                {({ countUpRef }) => (
                  <CountWrapper>
                    <b><Content ref={countUpRef} /></b>
                    {item.unit}
                  </CountWrapper>
                )}
              </CountUp>
            ) : (
              <>
                <b>0</b>
                <Content>{item.unit}</Content>
              </>
            )}
          </Column>
        ))}
      </Main>
    </AwardsSectionBlock>
  );
}

const AwardsSectionBlock = styled.section`
  background-color: ${colors.gray[200]};
  padding: 0 16px;
  height: 232px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.large}px){
    height: auto;
  }
`;

const Main = styled.div<{ inView: boolean }>`
  ${layoutBase};
  display: flex;
  align-items: center;
  padding: 0 8px;

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}

  @media screen and (max-width: ${breakpoints.large}px){
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1 0 33.33333%;
  padding: 0 8px;
  text-align: center;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 32px 0;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Title = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${colors.gray[800]};
  font-weight: 500;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 14px;
  }
`;

const Content = styled.p`
  margin: 0;
`;

const CountWrapper = styled.div`
  display: flex;
  font-size: 45px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 35px;
  }
`;

export default AwardsSection;
