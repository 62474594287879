import dayjs from 'dayjs';
import { localStorage, LocalStorageKeyEnum } from '../localStorage';

export interface RecentlyViewedGig {
  gigId: number;
  title: string;
  expires: string; // YYYY-MM-DD
}

interface DefaultGigInfo {
  gigId: number;
  title: string;
}

export const getExpiresDate = (): string => dayjs().add(30, 'day').format('YYYY-MM-DD');

const isExpired = (expires: string): boolean => dayjs(expires).isBefore(dayjs());

const getGigItem = ({ gigId, title }: DefaultGigInfo) => ({
  gigId,
  title,
  expires: getExpiresDate(),
});

const convertNumberToRecentlyViewedGig = (itemList: number[]): RecentlyViewedGig[] => {
  try {
    return itemList.map((item) => getGigItem({ gigId: item, title: '' }));
  } catch {
    return [];
  }
};

const verifyItem = (item: RecentlyViewedGig) => typeof item === 'object' && item.gigId !== undefined && item.title !== undefined && item.expires !== undefined;

const initializeFromData = (itemList: RecentlyViewedGig[]): RecentlyViewedGig[] => {
  let newItemList = [];

  try {
    newItemList = itemList.filter((item) => (verifyItem(item)));
    newItemList = newItemList.filter((item) => (!isExpired(item.expires)));

    return newItemList;
  } catch (error) {
    return [];
  }
};

export const getRecentlyViewedGigs = (): RecentlyViewedGig[] => {
  const gigs: RecentlyViewedGig[] | number[] = localStorage.getItem(LOCAL_STORAGE_KEY) ?? [];

  if (gigs.length === 0) {
    return [];
  }

  // @description 이전에 저장된 데이터가 number[] 형태로 저장되어 있을 수 있으므로, 이를 RecentlyViewedGig[] 형태로 변환
  // @todo 안녕하세요 미래인, 만약 지금 이 주석을 보고 있는 시점이 2023년 12월이 지났다면 number[] 를 제거하세요 안심하세요
  if (typeof gigs[0] === 'number') {
    return initializeFromData(convertNumberToRecentlyViewedGig(gigs as number[]));
  }

  return initializeFromData(gigs as RecentlyViewedGig[]);
};

export const setRecentlyViewedGigs = ({ gigId, title }: DefaultGigInfo) => {
  const gigs = createLocalStorageGigs({
    gigId,
    title,
    itemList: getRecentlyViewedGigs(),
    maxLength: GIGS_MAX_LENGTH,
  });
  localStorage.setItem(LOCAL_STORAGE_KEY, gigs);
};

interface CreateLocalStorageGigsProps {
  gigId: number;
  title: string;
  itemList: RecentlyViewedGig[];
  maxLength: number;
}

const createLocalStorageGigs = ({
  gigId, title, itemList, maxLength,
}: CreateLocalStorageGigsProps) => {
  let list: RecentlyViewedGig[] = [...itemList];

  if (list.findIndex((item) => item.gigId === gigId) > -1) {
    list = list.filter((item) => item.gigId !== gigId);
  }

  if (list.length >= maxLength) {
    list.splice(0, list.length - maxLength + 1);
  }

  return list.concat(getGigItem({ gigId, title }));
};

const GIGS_MAX_LENGTH = 20;
const LOCAL_STORAGE_KEY = LocalStorageKeyEnum.RECENTLY_VIEWED_GIGS;
