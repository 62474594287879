import React from 'react';
import AwardsSection from '../components/index/AwardsSection';
import EnterpriseSection from '../components/index/EnterpriseSection';
import HeroBanner from '../components/index/HeroBanner';
import LastSection from '../components/index/LastSection';
import MarketSection from '../components/index/MarketSection';
import PrimeSection from '../components/index/PrimeSection';
import SecuritySection from '../components/index/SecuritySection';
import SellerIntroSection from '../components/index/SellerIntroSection';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import useSiteMetadata from '../lib/hooks/useSiteMetaData';

function IndexPage() {
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout>
      <SEO siteURL={siteUrl} />
      <HeroBanner />
      <AwardsSection />
      <MarketSection />
      <PrimeSection />
      <EnterpriseSection />
      <SellerIntroSection />
      <SecuritySection />
      <LastSection />
    </Layout>
  );
}

export default IndexPage;
