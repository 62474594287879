import React from 'react';
import { markup } from '@kmong/utils';
import {
  getBreadcrumbJsonLd,
  getFaqJsonLd,
  getMinifyJsonLd,
  getOrganizationJsonLd,
  getPersonJsonLd,
  getProductJsonLd,
  getWebSiteJsonLd,
} from '../utils/jsonLd';
import type { SEOProps } from '../types';

export const buildTags = (config: SEOProps): JSX.Element[] => {
  const tagsToRender = [];

  if (config.title) {
    tagsToRender.push(
      <title key="title">{config.title}</title>,
      <meta
        key="og:title"
        content={config.title}
        property="og:title"
      />,
    );
  }

  if (config.noindex || config.nofollow) {
    tagsToRender.push(<meta
      key="robots"
      name="robots"
      content={`${config.noindex ? 'noindex' : 'index'},${
        config.nofollow ? 'nofollow' : 'follow'
      }`}
    />);
  } else {
    tagsToRender.push(<meta
      key="robots"
      content="index,follow"
      name="robots"
    />);
  }

  if (config.description) {
    tagsToRender.push(
      <meta
        key="description"
        content={config.description}
        name="description"
      />,
      <meta
        key="og:description"
        content={config.description}
        property="og:description"
      />,
    );
  }

  if (config.mobileAlternate) {
    tagsToRender.push(<link
      key="mobileAlternate"
      href={config.mobileAlternate.href}
      media={config.mobileAlternate.media}
      rel="alternate"
    />);
  }

  if (config.languageAlternates && config.languageAlternates.length > 0) {
    config.languageAlternates.forEach((languageAlternate) => {
      tagsToRender.push(<link
        key={`languageAlternate-${languageAlternate.hrefLang}`}
        href={languageAlternate.href}
        hrefLang={languageAlternate.hrefLang}
        rel="alternate"
      />);
    });
  }

  if (config.twitter) {
    if (config.twitter.cardType) {
      tagsToRender.push(<meta
        key="twitter:card"
        content={config.twitter.cardType}
        name="twitter:card"
      />);
    }

    if (config.twitter.site) {
      tagsToRender.push(<meta
        key="twitter:site"
        content={config.twitter.site}
        name="twitter:site"
      />);
    }

    if (config.twitter.handle) {
      tagsToRender.push(<meta
        key="twitter:creator"
        content={config.twitter.handle}
        name="twitter:creator"
      />);
    }
  }

  if (config.facebook) {
    if (config.facebook.appId) {
      tagsToRender.push(<meta
        key="fb:app_id"
        content={config.facebook.appId}
        property="fb:app_id"
      />);
    }
  }

  if (config.openGraph) {
    if (config.openGraph.url || config.canonical) {
      tagsToRender.push(<meta
        key="og:url"
        content={config.openGraph.url || config.canonical}
        property="og:url"
      />);
    }

    if (config.openGraph.type) {
      const type = config.openGraph.type.toLowerCase();

      tagsToRender.push(<meta
        key="og:type"
        content={type}
        property="og:type"
      />);

      if (type === 'profile' && config.openGraph.profile) {
        if (config.openGraph.profile.firstName) {
          tagsToRender.push(<meta
            key="profile:first_name"
            content={config.openGraph.profile.firstName}
            property="profile:first_name"
          />);
        }

        if (config.openGraph.profile.lastName) {
          tagsToRender.push(<meta
            key="profile:last_name"
            content={config.openGraph.profile.lastName}
            property="profile:last_name"
          />);
        }

        if (config.openGraph.profile.username) {
          tagsToRender.push(<meta
            key="profile:username"
            content={config.openGraph.profile.username}
            property="profile:username"
          />);
        }

        if (config.openGraph.profile.gender) {
          tagsToRender.push(<meta
            key="profile:gender"
            content={config.openGraph.profile.gender}
            property="profile:gender"
          />);
        }
      } else if (type === 'book' && config.openGraph.book) {
        if (
          config.openGraph.book.authors
          && config.openGraph.book.authors.length
        ) {
          config.openGraph.book.authors.forEach((author, index) => {
            tagsToRender.push(<meta
              key={`book:author:0${index}`}
              content={author}
              property="book:author"
            />);
          });
        }

        if (config.openGraph.book.isbn) {
          tagsToRender.push(<meta
            key="book:isbn"
            content={config.openGraph.book.isbn}
            property="book:isbn"
          />);
        }

        if (config.openGraph.book.releaseDate) {
          tagsToRender.push(<meta
            key="book:release_date"
            content={config.openGraph.book.releaseDate}
            property="book:release_date"
          />);
        }

        if (config.openGraph.book.tags && config.openGraph.book.tags.length) {
          config.openGraph.book.tags.forEach((tag, index) => {
            tagsToRender.push(<meta
              key={`book:tag:0${index}`}
              content={tag}
              property="book:tag"
            />);
          });
        }
      } else if (type === 'article' && config.openGraph.article) {
        if (config.openGraph.article.publishedTime) {
          tagsToRender.push(<meta
            key="article:published_time"
            content={config.openGraph.article.publishedTime}
            property="article:published_time"
          />);
        }

        if (config.openGraph.article.modifiedTime) {
          tagsToRender.push(<meta
            key="article:modified_time"
            content={config.openGraph.article.modifiedTime}
            property="article:modified_time"
          />);
        }

        if (config.openGraph.article.expirationTime) {
          tagsToRender.push(<meta
            key="article:expiration_time"
            content={config.openGraph.article.expirationTime}
            property="article:expiration_time"
          />);
        }

        if (
          config.openGraph.article.authors
          && config.openGraph.article.authors.length
        ) {
          config.openGraph.article.authors.forEach((author, index) => {
            tagsToRender.push(<meta
              key={`article:author:0${index}`}
              content={author}
              property="article:author"
            />);
          });
        }

        if (config.openGraph.article.section) {
          tagsToRender.push(<meta
            key="article:section"
            content={config.openGraph.article.section}
            property="article:section"
          />);
        }

        if (
          config.openGraph.article.tags
          && config.openGraph.article.tags.length
        ) {
          config.openGraph.article.tags.forEach((tag, index) => {
            tagsToRender.push(<meta
              key={`article:tag:0${index}`}
              content={tag}
              property="article:tag"
            />);
          });
        }
      } else if (
        (type === 'video.movie'
          || type === 'video.episode'
          || type === 'video.tv_show'
          || type === 'video.other')
        && config.openGraph.video
      ) {
        if (
          config.openGraph.video.actors
          && config.openGraph.video.actors.length
        ) {
          config.openGraph.video.actors.forEach((actor, index) => {
            if (actor.profile) {
              tagsToRender.push(<meta
                key={`video:actor:0${index}`}
                content={actor.profile}
                property="video:actor"
              />);
            }

            if (actor.role) {
              tagsToRender.push(<meta
                key={`video:actor:role:0${index}`}
                content={actor.role}
                property="video:actor:role"
              />);
            }
          });
        }

        if (
          config.openGraph.video.directors
          && config.openGraph.video.directors.length
        ) {
          config.openGraph.video.directors.forEach((director, index) => {
            tagsToRender.push(<meta
              key={`video:director:0${index}`}
              content={director}
              property="video:director"
            />);
          });
        }

        if (
          config.openGraph.video.writers
          && config.openGraph.video.writers.length
        ) {
          config.openGraph.video.writers.forEach((writer, index) => {
            tagsToRender.push(<meta
              key={`video:writer:0${index}`}
              content={writer}
              property="video:writer"
            />);
          });
        }

        if (config.openGraph.video.duration) {
          tagsToRender.push(<meta
            key="video:duration"
            content={config.openGraph.video.duration.toString()}
            property="video:duration"
          />);
        }

        if (config.openGraph.video.releaseDate) {
          tagsToRender.push(<meta
            key="video:release_date"
            content={config.openGraph.video.releaseDate}
            property="video:release_date"
          />);
        }

        if (config.openGraph.video.tags && config.openGraph.video.tags.length) {
          config.openGraph.video.tags.forEach((tag, index) => {
            tagsToRender.push(<meta
              key={`video:tag:0${index}`}
              content={tag}
              property="video:tag"
            />);
          });
        }

        if (config.openGraph.video.series) {
          tagsToRender.push(<meta
            key="video:series"
            content={config.openGraph.video.series}
            property="video:series"
          />);
        }
      }
    }

    // images
    if (config.openGraph.image) {
      if (config.openGraph.image.url) {
        const ogImageUrl = config.openGraph.image.url;

        tagsToRender.push(<meta
          key="og:image"
          content={ogImageUrl}
          property="og:image"
        />);

        tagsToRender.push(<meta
          key="og:image:secure_url"
          content={ogImageUrl}
          property="og:image:secure_url"
        />);

        tagsToRender.push(<meta
          key="og:image:type"
          content={`image/${getExtension(ogImageUrl)}`}
          property="og:image:type"
        />);
      }

      if (config.openGraph.image.alt) {
        tagsToRender.push(<meta
          key="og:image:alt"
          content={config.openGraph.image.alt}
          property="og:image:alt"
        />);
      }

      if (config.openGraph.image.width) {
        tagsToRender.push(<meta
          key="og:image:width"
          content={config.openGraph.image.width.toString()}
          property="og:image:width"
        />);
      }

      if (config.openGraph.image.height) {
        tagsToRender.push(<meta
          key="og:image:height"
          content={config.openGraph.image.height.toString()}
          property="og:image:height"
        />);
      }
    }

    // videos
    if (config.openGraph.videos && config.openGraph.videos.length) {
      config.openGraph.videos.forEach((video, index) => {
        tagsToRender.push(<meta
          key={`og:video:0${index}`}
          content={video.url}
          property="og:video"
        />);

        if (video.alt) {
          tagsToRender.push(<meta
            key={`og:video:alt0${index}`}
            content={video.alt}
            property="og:video:alt"
          />);
        }

        if (video.width) {
          tagsToRender.push(<meta
            key={`og:video:width0${index}`}
            content={video.width.toString()}
            property="og:video:width"
          />);
        }

        if (video.height) {
          tagsToRender.push(<meta
            key={`og:video:height${index}`}
            content={video.height.toString()}
            property="og:video:height"
          />);
        }
      });
    }

    if (config.openGraph.locale) {
      tagsToRender.push(<meta
        key="og:locale"
        content={config.openGraph.locale}
        property="og:locale"
      />);
    }

    if (config.openGraph.site_name) {
      tagsToRender.push(<meta
        key="og:site_name"
        content={config.openGraph.site_name}
        property="og:site_name"
      />);
    }
  }

  if (config.canonical) {
    tagsToRender.push(<link
      key="canonical"
      href={config.canonical}
      rel="canonical"
    />);
  }

  if (config.additionalMetaTags && config.additionalMetaTags.length > 0) {
    config.additionalMetaTags.forEach((tag) => {
      tagsToRender.push(<meta
        key={tag.name ? tag.name : tag.property}
        {...tag}
      />);
    });
  }

  if (config.breadcrumbItemList) {
    const jsonld = getBreadcrumbJsonLd(config.breadcrumbItemList);

    tagsToRender.push(<script
      key="breadcrumb-item-list"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(jsonld))}
    />);
  }

  if (config.faqs?.length) {
    const jsonld = getFaqJsonLd(config.faqs);

    tagsToRender.push(<script
      key="faq-list"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(jsonld))}
    />);
  }

  if (config.organization) {
    const jsonld = getOrganizationJsonLd(config.organization);

    tagsToRender.push(<script
      key="organization"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(jsonld))}
    />);
  }

  if (config.person) {
    const jsonld = getPersonJsonLd(config.person);

    tagsToRender.push(<script
      key="person"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(jsonld))}
    />);
  }

  if (config.product) {
    const jsonld = getProductJsonLd(config.product);

    tagsToRender.push(<script
      key="product"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(jsonld))}
    />);
  }

  if (config.website) {
    const jsonld = getWebSiteJsonLd(config.website);

    tagsToRender.push(<script
      key="website"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={markup(getMinifyJsonLd(jsonld))}
    />);
  }

  return tagsToRender;
};

const getExtension = (ogImageUrl: string) => {
  const division = ogImageUrl.split('.');
  const extension = division[division.length - 1] ?? 'jpeg';

  return extension;
};
