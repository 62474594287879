import dayjs from 'dayjs';

// eslint-disable-next-line default-param-last
export const formatDate = (date: string = '', formatTemplate: string) => {
  if (date === null) {
    return '';
  }

  if (!dayjs(date).isValid()) {
    return date;
  }

  return dayjs(date).format(formatTemplate);
};
