import React, {
  useState,
  useEffect,
} from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  colors,
  Button,
  CloseIcon,
} from '@kmong/ui';
import { Link } from 'gatsby';

const MobileNavBlock = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .76);
  backdrop-filter: blur(4px);
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 1 auto;
`;

const CloseButton = styled(Button)`
  margin-left: 3px;
`;

const Menu = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 64px 0;
`;

const MenuItem = styled.div<{ show: boolean; delay: number }>`
  height: 64px;
  opacity: 0;
  transform: translateY(24px) scale(1);
  transition: transform 0.7s cubic-bezier(0.0, 0.0, 0.2, 1), opacity 0.7s cubic-bezier(0.0, 0.0, 0.2, 1);
  transition-delay: ${(props) => `${props.delay}s`};

  ${(props) => (props.show && css`
    transform: translateY(0) scale(1);
    opacity: 1;
  `)}

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 64px;
    padding: 0 48px;
    text-decoration: none;
    color: ${colors.gray[100]};
    font-size: 24px;
  }

`;

export interface MobileNavProps{
    onClose: () => void;
}

function MobileNav({
  onClose,
}: MobileNavProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 0);
  }, []);

  return (
    <MobileNavBlock>
      <Header>
        <CloseButton
          dark
          icon
          rounded
          size="large"
          variant="text"
          onClick={onClose}
        >
          <CloseIcon />
        </CloseButton>
      </Header>
      <Menu>
        <MenuItem
          delay={0}
          show={show}
        >
          <Link to="/">홈</Link>
        </MenuItem>
        <MenuItem
          delay={0.12}
          show={show}
        >
          <Link to="/career">채용</Link>
        </MenuItem>
      </Menu>
    </MobileNavBlock>
  );
}

export default MobileNav;
