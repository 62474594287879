import api from './api';

export interface Rating {
  RID: number;
  evaluated_at: string;
  image: {
    FID: number;
    original_url: string;
    preview_url: string;
  } | null;
  ad_type: string | null;
  is_prime: boolean;
  message: string;
  buyer_username: string;
  status: 'ACTIVE' | 'BLINDED';
  replied_at: string;
  is_blocked: boolean;
  rate: number;
  buyer: {
    image: string;
    username: string;
  };
  reply: {
    message: string;
    replied_at: string;
    seller_username: string;
    status: 'ACTIVE' | 'BLINDED';
  } | null;
  order: {
    type: string;
    id: number;
    status: null | string;
    title: string;
    thumbnail: string;
    price_range: string;
    days: string;
  } | null;
}

export interface GetRatingsResponse {
  total: number;
  last_page: number;
  ratings: Rating[];
  previous_page_link: string | null;
  next_page_link: string | null;
}

export type OrderBy = 'REPLIED_AT_DESC' | 'RATE_DESC' | 'RATE_ASC';

export interface GetRatingsParams {
  page: number;
  order_by: OrderBy;
}

const getRatings = async (PID: number, params: GetRatingsParams) => {
  const { data } = await api.get<GetRatingsResponse>(
    `/api/v5/gigs/${PID}/ratings`,
    {
      params,
    },
  );

  return data;
};

export interface GetAllRatingResponse {
  total: number;
  previous_page_link: string | null;
  last_page: number;
  ratings: Rating[];
  avg_rating: number;
}

const getAllRatings = async (userId: number, page: number) => {
  const { data } = await api.get<GetAllRatingResponse>(`/api/seller-profile/v2/${userId}/ratings?page=${page}`);

  return data;
};

export const reviewApi = {
  getRatings,
  getAllRatings,
};
