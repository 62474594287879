import { CLOUDFRONT_URL } from '@kmong-service/utils';

export const freelancerDaySEO = {
  title: '2022 크몽 프리랜서데이ㅣ프리랜서, 시작하자! 잘하자! 오래하자!',
  breadcrumbs: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      name: '크몽 프리랜서데이 홈',
      position: 2,
    },
  ],
  openGraph: {
    image: {
      url: `${CLOUDFRONT_URL}/assets/events/freelancer_day_v2.jpg`,
      alt: '크몽 프리랜서데이',
    },
  },
};
