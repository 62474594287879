import { CLOUDFRONT_URL } from '@kmong-service/utils';

const getFavicon = () => {
  switch (process.env.NEXT_PUBLIC_MODE) {
    case 'local': {
      return `${CLOUDFRONT_URL}/assets/icon/favicon/favicon-local-32x32.png`;
    }
    case 'development': {
      return `${CLOUDFRONT_URL}/assets/icon/favicon/favicon-dev-32x32.png`;
    }
    default: {
      return `${CLOUDFRONT_URL}/assets/icon/favicon/favicon-32x32.png`;
    }
  }
};

export default getFavicon;
