import React, { memo } from 'react';
import styled from '@emotion/styled';
import { SkeletonBox } from '@kmong/ui';

function PaginationSkeleton() {
  return (
    <PaginationSkeletonBlock data-testid="pagination-skeleton">
      {[...Array(7)].map((_, i) => (
        <PaginationWrapper key={i}>
          <SkeletonBox
            style={{
              width: 20,
              height: 20,
              margin: 8,
            }}
          />
        </PaginationWrapper>
      ))}
    </PaginationSkeletonBlock>
  );
}

const PaginationSkeletonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

const PaginationWrapper = styled.article``;

export default memo(PaginationSkeleton);
