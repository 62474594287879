import { CLOUDFRONT_URL } from '@kmong-service/utils';

const title = '전문가님, 크몽 광고 혜택 받아가세요';

export const supportSellerSEO = {
  title,
  description: '크몽 무료 광고 혜택 대상으로 선정되신 것을 축하드립니다! 전문가님의 서비스를 더 널리 알리기 위해, 아래 2가지 광고를 제공합니다.',
  breadcrumbs: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      name: '크몽 광고 지원',
      position: 2,
    },
  ],
  openGraph: {
    image: {
      url: `${CLOUDFRONT_URL}/assets/events/support-seller/og_image.jpg`,
      alt: title,
      width: 1200,
      height: 630,
    },
  },
};
