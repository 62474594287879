import type { QueryString } from '@kmong/utils';

export const getIdDeletingUrlTitle = (id?: QueryString) => {
  let paramsId = String(id ?? '');

  if (paramsId.indexOf('--') > -1) {
    [paramsId] = paramsId.split('--');
  }

  return paramsId;
};
