import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { LinkInfo, TempGig } from '..';

interface GetGatewayCategoriesResponse {
  categories: number[];
}

const getGatewayCategories = async (): Promise<GetGatewayCategoriesResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/gateway/v2/categories.json`);

  return data.json();
};

export interface GetHeroLabelResponse {
  labels: HeroLabel[];
}

export interface HeroLabel {
  title: string;
  imageUrl: string;
  linkInfo: LinkInfo;
}

const getHeroLabel = async (categoryId: number): Promise<GetHeroLabelResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/gateway/v2/categories/${categoryId}/labels.json`);

  return data.json();
};

interface GetPopularCategoriesResponse {
  categories: GatewayPopularCategory[];
}

export interface GatewayPopularCategory {
  categoryId: number;
  categoryName: string;
}

const getPopularCategories = async (categoryId: number) => {
  const { data } = await api.get<GetPopularCategoriesResponse>(`/api/next/gig-app/category/v1/categories/${categoryId}/top-ranking-sub-categories`);

  return data;
};

export interface GetGatewayPopularGigsResponse {
  gigs: TempGig[];
}

const getPopularGigs = async (subCategoryId: number) => {
  const { data } = await api.get<GetGatewayPopularGigsResponse>('/api/next/gig-app/gig/v1/top-order-gigs/sub-category', {
    params: {
      subCategoryId,
      takeCount: 20,
    },
  });

  return data;
};

interface GetFastReactionGigsResponse {
  gigs: TempGig[];
}

const getFastReactionGigs = async (categoryId: number) => {
  const { data } = await api.get<GetFastReactionGigsResponse>(`/api/next/gig-app/gig/v1/categories/${categoryId}/fast-reaction-gigs`);

  return data;
};

interface GetCurationBannersResponse {
  bannerInfo: CurationBannerInfo;
}

interface CurationBannerInfo {
  title: string;
  banners: CurationBanner[];
}

export interface CurationBanner {
  title: string;
  description: string | null;
  imageUrl: string;
  linkInfo: LinkInfo;
}

const getCurationBanners = async (categoryId: number): Promise<GetCurationBannersResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/gateway/v2/categories/${categoryId}/banners.json`);

  return data.json();
};
export const gatewayApis = {
  getGatewayCategories,
  getHeroLabel,
  getPopularCategories,
  getPopularGigs,
  getFastReactionGigs,
  getCurationBanners,
};
