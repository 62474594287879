const title = '[크몽] 비즈니스 맞춤형 마케팅 전략';
const description = '쇼핑몰, 요식업, 스타트업, 병원 대표님! 마케터들이 선택한 크몽 마케팅! 50만+건의 노하우로 마케팅 방법, 예산 배분, 전문가 추천까지 한 번에 제안해 드립니다.';

export const businessDiagnosisSEO = {
  title,
  description,
  openGraph: {
    title,
    description,
  },
};

export const businessDiagnosisBreadCrumbJsonLd = {
  itemListElements: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      item: 'https://kmong.com/business-diagnosis/marketing/survey',
      name: '마케팅 추천',
      position: 2,
    },
    {
      name: '마케팅 제안',
      position: 3,
    },
  ],
};
