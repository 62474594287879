import type { SessionStorageKeyEnum } from './types/sessionStorageType';

const internalSessionStorage = () => {
  if (typeof window === 'undefined' || !window.sessionStorage) {
    return null;
  }

  return window.sessionStorage;
};

const setItem = (key: SessionStorageKeyEnum, value: string) => {
  internalSessionStorage()?.setItem(key, value);
};

const getItem = (key: SessionStorageKeyEnum) => internalSessionStorage()?.getItem(key);

const removeItem = (key: SessionStorageKeyEnum) => {
  internalSessionStorage()?.removeItem(key);
};

const clearItem = () => {
  internalSessionStorage()?.clear();
};

export const sessionStorage = {
  setItem,
  getItem,
  removeItem,
  clearItem,
};
