import api from '../api';

interface SellerWithdrawalBankAccount {
  depositor: string | null;
  accountNumber: string | null;
  bankName: string | null;
}

export interface GetWithdrawalBankAccountResponse {
  sellerUserId: number;
  bankAccount: SellerWithdrawalBankAccount;
}

const getWithdrawalBankAccount = async (): Promise<GetWithdrawalBankAccountResponse> => {
  const { data } = await api.get<GetWithdrawalBankAccountResponse>('/api/next/order-app/payment/v1/payments/seller/withdrawal/bank-account');

  return data;
};

export const sellerPaymentApi = {
  getWithdrawalBankAccount,
};
