import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import Layout from '../../components/Layout';
import Pagination from '../../components/Pagination';

function SellersPage() {
  const [data, setData] = useState<string[]>([]);
  const [nowPage, setNowPage] = useState(1);
  const start = (nowPage - 1) * PER_PAGE;
  const end = start + PER_PAGE;
  const pageData = data.slice(start, end);

  useEffect(() => {
    const loadData = async () => {
      setData(await getSellerCompanies());
    };

    loadData();
  }, []);

  const onChangePage = (currentValue: number) => {
    setNowPage(currentValue);
  };

  return (
    <Layout minHeight="74vh">
      <ContentWrapper>
        <Section>
          <Title>사업자 전문가 조회</Title>
          <Description>크몽의 서비스(상품) 용역을 제공하는 사업자 전문가를 확인할 수 있습니다.</Description>
          <List>
            {pageData.map((item) => <li key={item}>{item}</li>)}
          </List>
          <PaginationWrapper>
            <Pagination
              pageRange={3}
              total={Math.ceil(data.length / PER_PAGE)}
              onChange={onChangePage}
            />
          </PaginationWrapper>
        </Section>
      </ContentWrapper>
    </Layout>
  );
}

interface GetSellerCompaniesResponse {
  companies: string[];
}

const getSellerCompanies = async (): Promise<string[]> => {
  const res = await fetch(`${CLOUDFRONT_URL}/assets/seller/companies.json`);

  try {
    const data: GetSellerCompaniesResponse = await res.json();

    return data.companies;
  } catch {
    return [];
  }
};

const PER_PAGE = 20;

const Section = styled.section`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 16px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
`;

const Title = styled.h1`
  color: ${colors.gray[800]};
  text-align: center;
  margin-bottom: 12px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    margin-bottom: 8px;
    font-size: 24px;
  }
`;

const Description = styled.p`
  margin-top: 0;
  text-align: center;
  color: ${colors.gray[500]};
  font-size: 15px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    max-width: 230px;
    margin: auto;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

const List = styled.ul`
  max-width: 400px;
  margin: 40px auto 0;
  padding: 20px;
  background: ${colors.gray[100]};
  border-radius: 12px;
  color: ${colors.gray[600]};
  font-size: 14px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    margin-top: 20px;
  }
`;

export default SellersPage;
