import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';

export interface SellerGradeCondition {
  max_received_rate: number;
  max_avg_response_rate: number;
  max_success_order_rate: number;
  max_compliance_rate: number;
  max_activation_period: number;
  received_rate: number;
  avg_response_rate: number;
  success_order_rate: number;
  compliance_rate: number;
  count_penalty: number;
  amount_sold_order: number;
  count_sold_order: number;
  activation_period: number;
}

export interface GradeDateRage {
  start_date: string;
  end_date: string;
}

export interface GetMyConditionResponse {
  USERID: string;
  seller_grade_condition: SellerGradeCondition;
  grade_date_range: GradeDateRage;
  grade_update_date: string;
  current_month: number;
}

const getMyGradeCondition = async (userId: number) => {
  const { data } = await api.get<GetMyConditionResponse>(`/api/v5/seller-profile/${userId}/condition`);

  return data;
};

/**
 *
 * @description MASTER 등급일 때만 호출합니다.
 */
const getGradeMasterPlus = async () => {
  const { data } = await api.get('/api/next/user-app/seller/grade/v1/grade-master-plus');

  return data;
};

export interface Question {
  title: string;
  subTitle: string;
  description: string;
}

export interface QnaInfo {
  title: string;
  questions: Question[];
}

export interface GradeCondition {
  grade: string;
  satisfactionRate: number;
  averageResponseRate: number;
  orderCompletionRate: number;
  orderComplianceRate: number;
  penaltyCount: number;
  accumulateSoldAmount: number;
  accumulateSoldCount: number;
  accumulateSoldMinAmount: number;
  activationPeriod: number;
}

export interface Item {
  title: string;
  description: string;
}

interface GradeConditionInfo {
  title: string;
  items: Item[];
}

export interface GradeInfoResponse {
  qnaInfo: QnaInfo;
  gradeConditions: GradeCondition[];
  gradeConditionInfo: GradeConditionInfo;
}

export const getGradeInfo = async () => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/seller/seller-grade/v1/seller-grade-info.json`);

  return data.json() as Promise<GradeInfoResponse>;
};

export const myGradeApi = {
  getMyGradeCondition,
  getGradeMasterPlus,
  getGradeInfo,
};
