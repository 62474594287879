import api from './api';

export interface SavedCouponType {
  buyer_coupon_provider_id: number;
  USERID: number;
  expired_at: string;
  updated_at: string;
  created_at: string;
  id: number;
  status: string;
  title: string;
  available_count: number;
  type: string;
  type_amount: number;
  type_amount_ko: string;
  unit: string;
  available_minimum_amount: number;
  available_maximum_amount: number;
  available_category_text: string;
  is_available_to_use: boolean;
  remaining_days: number;
}

interface SaveBuyerCouponsParams {
  code: string;
}

export interface SaveBuyerCouponsResponse {
  coupons: SavedCouponType[];
}

const saveBuyerCoupons = async (params: SaveBuyerCouponsParams) => {
  const { data } = await api.post<SaveBuyerCouponsResponse>('/api/v5/my-kmong/BUYER/coupons', params);

  return data?.coupons ?? [];
};

interface GetBuyerCouponsParams {
  page?: number;
}

export type CouponDiscountType = 'PERCENT' | 'AMOUNT';

export interface CouponDetail {
  id: number;
  title: string;
  expired_at: string;
  status: string;
  available_count: number;
  type: CouponDiscountType;
  type_amount: number;
  type_amount_ko: string;
  unit: string;
  available_minimum_amount: number;
  available_maximum_amount: number;
  available_category_text: string;
  is_available_to_use: boolean;
  remaining_days: number;
}

export interface GetBuyerCouponsResponse {
  total: number;
  previous_page_link: null | string;
  next_page_link: null | string;
  last_page: number;
  coupons: CouponDetail[];
}

const getBuyerCoupons = async (params: GetBuyerCouponsParams) => {
  const { data } = await api.get<GetBuyerCouponsResponse>('/api/v5/my-kmong/BUYER/coupons', {
    params: {
      page: params.page ?? 1,
    },
  });

  return data;
};

export const couponApi = {
  saveBuyerCoupons,
  getBuyerCoupons,
};
