import styled from '@emotion/styled';
import { colors, breakpoints } from '@kmong/ui';

const SectionTitle = styled.p`
  font-size: 21px;
  color: ${colors.gray[800]};
  line-height: 1.52;
  margin: 0 0 24px 0;
  font-weight: normal;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 15px;
    margin: 0 0 16px 0;
    line-height: normal;
  }
`;

export default SectionTitle;
