import styled from '@emotion/styled';
import { colors, breakpoints } from '@kmong/ui';

const SectionDescription = styled.p`
  font-size: 16px;
  line-height: 1.75;
  color: ${colors.gray[600]};
  margin: 0 0 96px 0;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 15px;
    line-height: 1.4;
    margin: 24px 0 0 0;
  }
`;

export default SectionDescription;
