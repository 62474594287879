import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints } from '@kmong/ui';

const View = styled.div<{ platform: 'desktop' | 'mobile' }>`
    width: 100%;
    ${(props) => (props.platform === 'desktop'
    ? css`
        display: block;
        @media screen and (max-width: ${breakpoints.large}px){
            display: none;
        }
    `
    : css`
        display: none;
        @media screen and (max-width: ${breakpoints.large}px){
            display: block;
        }
    `)
}
`;

export default View;
