import { CLOUDFRONT_URL } from '@kmong-service/utils';

const SEOTitle = '꼭 필요한 노하우만 | 크몽 전자책∙VOD';
const SEODescription = '내 노하우, 쉽고 빠르게 판매해보세요!';

export const sellerSEO = {
  title: SEOTitle,
  description: SEODescription,
  openGraph: {
    title: SEOTitle,
    description: SEODescription,
    image: {
      url: `${CLOUDFRONT_URL}/assets/desktop/modules/money-plus/seller/og_image.jpg`,
      width: 1200,
      height: 630,
      alt: SEOTitle,
    },
  },
};

export const sellerBreadCrumbJsonLd = {
  itemListElements: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      item: 'https://kmong.com/knowhow',
      name: '전자책∙VOD',
      position: 2,
    },
    {
      name: '내 노하우가 수익이 되는 곳, 크몽',
      position: 3,
    },
  ],
};
