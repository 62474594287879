import categoryApi from './category';
import commonApi from './common';
import knowhowMainApi from './knowhow';
import searchApi from './search';

export * from './knowhow';

export const knowhowApi = {
  ...knowhowMainApi,
  ...commonApi,
  ...searchApi,
  ...categoryApi,
};
