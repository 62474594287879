import api from './api';

interface CustomProject {
  request: {
    requestId: number;
    title: string;
  };
  proposal: {
    proposalId: number;
    title: string;
    amount: number;
  };
}

interface GetOrderLogEventDataResponse {
  seller: {
    userId: number;
    nickname: string;
    safeNumber: string;
  };
  coupon: {
    isUsed: boolean;
    id: number;
    name: string;
    usedAmount: number;
  };
  order: {
    revenue: number;
    totalPrice: number;
    serviceType: string;
    quantity: number;
    currency: string;
    orderId: number;
    isAdditionalPay: boolean;
    additionalPrice: number;
    isSelfMarketing: boolean;
  };
  gig: {
    gigId: number;
    price: number;
    title: string;
    isPrime: boolean;
  };
  customProject: CustomProject | null;
  category: {
    rootCategoryId: number;
    rootCategoryName: string;
    subCategoryId: number;
    subCategoryName: string;
    thirdCategoryId: number | null;
    thirdCategoryName: string | null;
  };
}

interface GetOrderLogEventDataProps {
  directOrderId: number;
  orderId: number;
}

const getOrderLogEventData = async ({ directOrderId, orderId }: GetOrderLogEventDataProps): Promise<GetOrderLogEventDataResponse> => {
  const { data } = await api.get<GetOrderLogEventDataResponse>(`/api/order/v2/direct-orders/${directOrderId}/orders/${orderId}/analytics`);

  return data;
};

export const eventTrackerApi = {
  getOrderLogEventData,
};
