import type { LocalStorageKeyEnum } from './types/localStorageType';

const internalLocalStorage = () => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return null;
  }

  return window.localStorage;
};

/**
 *
 * @param key LocalStorageKeyEnum
 * @param value 중요!! 내부에서 JSON.stringify를 사용하여 값을 저장합니다.
 */
const setItem = (key: LocalStorageKeyEnum, value: unknown) => {
  const stringifiedValue = JSON.stringify(value);
  internalLocalStorage()?.setItem(key, stringifiedValue);
};

function getItem<T>(key: LocalStorageKeyEnum):T | null | undefined {
  const item = internalLocalStorage()?.getItem(key);
  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch {
    return undefined;
  }
}

const removeItem = (key: LocalStorageKeyEnum) => {
  internalLocalStorage()?.removeItem(key);
};

const clearItem = () => {
  internalLocalStorage()?.clear();
};

export const localStorage = {
  setItem,
  getItem,
  removeItem,
  clearItem,
};
