import api from '../api';
import type { OrderType, ReviewModalDeliveryFile, ReviewScore } from '..';

export interface ReviewModalFormData {
  message: string;
  fileId: number | null;
  files: ReviewModalDeliveryFile[] | null;
  ratingsFiles: string | null;
  rate: string;
  ratingScores: ReviewScore[];
  orderProgressId: number | null;
  orderType: OrderType | null;
}

export const postReviewModal = async (orderId: number, formData: ReviewModalFormData) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/rate/buyer`, formData);
};

export const putReviewModal = async (orderId: number, formData: ReviewModalFormData) => {
  await api.put(`/api/order/v1/orders/${orderId}/progress/rate/buyer`, formData);
};

/**
 * @param rate 의 경우 현재 사용하지 않는 spec이라 null로 보내도록 합니다 (BE에서 처리)
 * @see https://kmong.slack.com/archives/C03LTCV98JW/p1667187486840869?thread_ts=1667177949.874729&cid=C03LTCV98JW
 */
export interface ReviewModalFormDataForSeller {
  message: string;
  rate: null;
}

export const postReviewModalForSeller = async (orderId: number, formData: ReviewModalFormDataForSeller) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/rate/seller`, formData);
};

export const putReviewModalForSeller = async (orderId: number, formData: ReviewModalFormDataForSeller) => {
  await api.put(`/api/order/v1/orders/${orderId}/progress/rate/seller`, formData);
};

export type ReviewModalAfterCancelForBuyerRatingsKey = 'quality' | 'communication' | 'deliveryCompliance' | 'reordering';

export type ReviewModalAfterCancelForBuyerFormData = Record<ReviewModalAfterCancelForBuyerRatingsKey, number>;

export const postReviewModalAfterCancelForBuyer = async (orderId: number, formData: Record<ReviewModalAfterCancelForBuyerRatingsKey, number>) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/evaluation/buyer`, formData);
};

export interface ReviewModalAfterCancelForSellerFormData {
  sellerReview: number;
}

export const postReviewModalAfterCancelForSeller = async (orderId: number, formData: ReviewModalAfterCancelForSellerFormData) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/evaluation/seller`, formData);
};
