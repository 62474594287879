import type { Viewport } from 'next';

/**
 * @link https://nextjs.org/docs/app/api-reference/functions/generate-viewport#width-initialscale-maximumscale-and-userscalable
 * @description generateViewport는 동적 렌더링으로 변경되므로 필요한 페이지 내에서 따로 정의하세요.
 * 순서: rootLayout -> nestedLayout -> page
 */
export const defaultViewport: Viewport = {
  width: 'device-width',
  initialScale: 1,
  maximumScale: 1,
  userScalable: false,
};
