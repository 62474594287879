import unescape from 'lodash/unescape';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Response = Record<string, any>;

export function unescapeFields<T extends Response>(response: T, fields: (keyof T)[]) {
  return fields.reduce<T>((acc, field) => ({
    ...acc,
    [field]: unescape(acc[field]),
  }), response);
}
