import api from '../../api';

export type ArticleSearchSortType = 'SCORE' | 'PUBLISHED_AT';

export interface GetSearchedArticlesProps {
  searchText: string;
  page?: number;
  perPage?: number;
  sort?: ArticleSearchSortType;
  headerType?: 'BLOG' | 'MARKET';
}

interface BlogSection {
  id: number;
  name: string;
}

export interface SearchedArticle {
  articleId: number;
  title: string;
  subtitle: string;
  thumbnail: string;
  blogSection: BlogSection;
  publishedAt: string;
}

export interface SearchedArticlesResponse {
  currentPage: number;
  lastPage: number;
  perPage: 10;
  totalItemCount: number;
  items: SearchedArticle[];
}

const getSearchedArticles = async ({
  searchText,
  page = 1,
  perPage = 10,
  sort = 'SCORE',
  headerType = 'BLOG',
}: GetSearchedArticlesProps) => {
  const { data } = await api.get<SearchedArticlesResponse>('/api/next/community/article/v1/search/articles', {
    params: {
      searchText, page, perPage, sort, headerType,
    },
  });

  return data;
};

export const articleSearchApi = {
  getSearchedArticles,
};
