/* eslint-disable func-names */
import type { IncomingHttpHeaders } from 'http';

export interface AsyncHttpHeader {
  set?: (headers: IncomingHttpHeaders) => void;
  get?: () => IncomingHttpHeaders | undefined;
  destroy?: () => void;
}

export const asyncHttpHeader: AsyncHttpHeader = {
  set: undefined,
  get: undefined,
  destroy: undefined,
};

if (typeof window === 'undefined') {
  (async function () {
    const asyncHooks = await import('async_hooks');
    const store: Map<number, IncomingHttpHeaders | undefined> = new Map();

    const asyncHook = asyncHooks.createHook({
      init: (asyncId, _, triggerAsyncId) => {
        if (store.has(triggerAsyncId)) {
          store.set(asyncId, store.get(triggerAsyncId));
        }
      },
      destroy: (asyncId) => {
        if (store.has(asyncId)) {
          store.delete(asyncId);
        }
      },
    });

    asyncHook.enable();

    asyncHttpHeader.set = (headers: IncomingHttpHeaders) => {
      store.set(asyncHooks.executionAsyncId(), headers);
    };

    asyncHttpHeader.get = () => store.get(asyncHooks.executionAsyncId());
  }());
}
