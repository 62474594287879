export enum CookiesKeyEnum {
  HIDE_SURVEY_WIDGET = 'hideSurveyWidget',
  KMONG_FIRST_REFERRER = 'kmongFirstReferrer',
  KMONG_LAST_REFERRER = 'kmongLastReferrer',
  MONEY_PLUS_SIGNUP = 'moneyPlusSignup',
  X_KMONG_AUTHORIZATION = 'X-Kmong-Authorization',
  X_KMONG_AUTHORIZATION_REFRESH = 'X-Kmong-Authorization-Refresh',
  HIDE_CATEGORY_CHOOSE_TOOLTIP = 'hideCategoryChooseTooltip',
  HIDE_PLACE_FILTER_TOOLTIP = 'hidePlaceFilterTooltip',
  HIDE_META_PLACE_FILTER_TOOLTIP = 'hideMetaPlaceFilterTooltip',
  HIDE_SCHEDULE_UPDATE_POPUP = 'hideScheduleUpdatePopup',
  HIDE_SELLER_SCHEDULE_WIDGET = 'hideSellerScheduleWidget',
  HIDE_INBOX_ONBOARDING_MODAL = 'hideInboxOnboardingModal',
  HIDE_AI_ONBOARDING_MODAL = 'hideAiOnboardingModal',
  HIDE_BUSINESS_USER_POPUP = 'hideBusinessUserPopup',
  HIDE_MOBILE_WEB_TO_APP_BANNER = 'hideMobileWebToAppBanner',
  ALREADY_SIGNUP_USER = 'alreadySignupUser',
  ALREADY_SIGNUP_USERS = 'alreadySignupUsers',
  GCLID = 'gclid',
  GCLID_ENTERED_TIME = 'gclidEnteredTime',
}
