import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from './api';

export interface Salon {
  id: number;
  title: string;
  contents: string | null;
  event_date: string;
  place: string;
  is_available: boolean;
  thumbnail: string;
  type: 'ONLINE' | 'OFFLINE';
}

export interface GetLatestSalonsResponse {
  salon_list: Salon[];
}

const getLatestSalons = async () => {
  const { data } = await api.get<GetLatestSalonsResponse>('/api/fss/v1/salon?page=1');

  return data.salon_list;
};

export interface GetSalonDetailResponse {
  id: number;
  available_participants: number;
  content_images: string[];
  contents: string | null;
  amount: number;
  deadline: string | null;
  detail_place: string | null;
  end_time: string;
  is_application: boolean;
  is_deadline: boolean;
  leader: {
    description: string | null;
    images: string[] | null;
    userid: number;
    username: string | null;
    nickname: string;
  };
  main_image: string;
  participants: number;
  place: string;
  published_at: string;
  online_url: string | null;
  original_url: string | null;
  survey_url: string | null;
  start_time: string;
  title: string;
  review_url: string | null;
  summary: string;
  questions: Array<{
    id: number;
    question: string;
  }>;
  PID: number | null;
}

const getSalonDetail = async (salonId: number) => {
  const { data } = await api.get<GetSalonDetailResponse>(`/api/fss/v1/salon/${salonId}`);

  return data;
};

export interface ApplySalonEventResponse {
  total_applied_seminar_count: number;
}

export interface ApplySalonEventPayload {
  userid?: number;
  fss_id: number;
  username: string;
  mobile: string;
  email: string;
  job_type_id: number | null;
  job_sector_id: number | null;
  job_career: number | null;
  is_email_consent: number;
  is_mobile_consent: number;
  answers: Array<{
    id: number;
    answer: string;
  }>;
}

const applySalonEvent = async (payload: ApplySalonEventPayload) => {
  const { data } = await api.post<ApplySalonEventResponse>('/api/fss/v1/salon/application', payload);

  return data?.total_applied_seminar_count;
};

export interface CancelApplicationPayload {
  userid: number;
  fss_id: number;
}

const cancelApplication = async (payload: CancelApplicationPayload) => {
  const { data } = await api.post<ApplySalonEventResponse>('/api/fss/v1/salon/application/cancel', payload);

  return data?.total_applied_seminar_count;
};

interface SubscribeNewsLetterResponse {
  id: number;
  userid: number | null;
  username: string;
  email: string;
  is_subscribe: boolean;
  updated_at: string;
  created_at: string;
}

export interface SubscribeNewsLetterPayload {
  name: string;
  email: string;
  is_subscribe: boolean;
  userid?: number;
}

const subscribeNewsLetter = async (payload: SubscribeNewsLetterPayload) => {
  const { data } = await api.post<SubscribeNewsLetterResponse>('/api/fss/v1/salon/newsletter', payload);

  return data;
};

interface CheckNewsLetterApplicationResponse {
  is_subscribe: boolean;
}

const checkNewsLetterApplication = async (email: string) => {
  const { data } = await api.get<CheckNewsLetterApplicationResponse>(`/api/fss/v1/salon/newsletter-check?email=${email}`);

  return data?.is_subscribe;
};

interface SubscribedMembershipResponse {
  id?: number;
  created_at?: string;
}

const getIsSubscribedMembership = async (USERID: number) => {
  const { data } = await api.get<SubscribedMembershipResponse>(`/api/fss/v1/users/${USERID}`);

  if (!data || !data?.id) {
    return false;
  }

  return data.id > 0;
};

export interface GetIsApplySeminarParams {
  id: number;
  USERID: number;
}

interface GetIsApplySeminarResponse {
  is_application: boolean;
}

const getIsApplySeminar = async ({ USERID: userid, id: fss_id }: GetIsApplySeminarParams) => {
  const { data } = await api.get<GetIsApplySeminarResponse>('/api/fss/v1/salon/application-check', {
    params: {
      userid,
      fss_id,
    },
  });

  return data?.is_application;
};

/**
 * @todo 프리랜서데이 기획이 꼬이는 바람에 파라미터 임시 옵셔널 처리
 */
const postSubscribedMembership = async (agreedMarketing?: boolean) => {
  const { data } = await api.post<SubscribedMembershipResponse>('/api/fss/v1/users', {
    is_agreed: agreedMarketing,
  });

  return (data?.id ?? 0) > 0;
};

const deleteSubscribedMembership = async (USERID: number) => {
  if (USERID) {
    const { data } = await api.delete<SubscribedMembershipResponse>(`/api/fss/v1/users/${USERID}`);

    return (data?.id ?? 0) > 0;
  }

  return null;
};

export interface FreelancerClubStaticJsonResponse {
  seller: FreelancerClubStaticJsonArticle[];
  insight: FreelancerClubStaticJsonArticle[];
}

export interface FreelancerClubStaticJsonArticle {
  src: string;
  title: string;
  href: string;
}

const getFreelancerClubStaticJson = async (): Promise<FreelancerClubStaticJsonResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/static-json/freelancer-club-static-json-240423-v02.json`);

  return data.json();
};

export interface MoreLink {
  title: string;
  href: string;
  target: string;
}

export interface Article {
  icon: string;
  title: string;
  href: string;
}

interface ForYourGrowthQuery {
  moreLink: MoreLink;
  articles: Article[];
}

const getFreelancerClubForYourGrowth = async (): Promise<ForYourGrowthQuery> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/static-json/freelancer-club-for-your-growth-v02.json`);

  return data.json();
};

export const freelancerClubApi = {
  getLatestSalons,
  getSalonDetail,
  applySalonEvent,
  cancelApplication,
  subscribeNewsLetter,
  checkNewsLetterApplication,
  getIsSubscribedMembership,
  getIsApplySeminar,
  postSubscribedMembership,
  deleteSubscribedMembership,
  getFreelancerClubStaticJson,
  getFreelancerClubForYourGrowth,
};
