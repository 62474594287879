/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, colors } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import useSiteMetadata from '../lib/hooks/useSiteMetaData';
import type { PageProps } from 'gatsby';

const NotFoundPageBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 160px 16px 16px;
`;

const GhostImage = styled.img`
  max-width: 100%;
  width: 450px;
  height: 450px;
  object-fit: contain;
`;

function NotFoundPage({ location }: PageProps) {
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout>
      <SEO
        description="404 | 요청하신 페이지를 찾을 수 없습니다."
        siteURL={`${siteUrl}${location.pathname}`}
        title="404"
      />
      <NotFoundPageBlock>
        <GhostImage
          alt="NOT FOUND"
          src={`${CLOUDFRONT_URL}/assets/images/desktop/kmong-ghost.png`}
          srcSet={`${CLOUDFRONT_URL}/assets/images/desktop/kmong-ghost@2x.png 2x,
          ${CLOUDFRONT_URL}/assets/images/desktop/kmong-ghost@3x.png 3x`}
        />
        <p css={css`font-size: 88px; font-weight: bold; margin: 16px 0; color: ${colors.gray[800]};`}>404</p>
        <h1 css={css`font-size: 28px; font-weight: bold; margin: 0; color: ${colors.gray[800]};`}>요청하신 페이지를 찾을 수 없습니다.</h1>
        <p css={css`color: ${colors.gray[600]}; margin: 8px 0 32px 0;`}>페이지가 삭제되었거나 주소가 변경되었을 수 있습니다.</p>
        <Button
          color="yellow"
          href="/"
          size="large"
          variant="contained"
        >
          메인페이지로 돌아가기
        </Button>
      </NotFoundPageBlock>
    </Layout>
  );
}
export default NotFoundPage;
