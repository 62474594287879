import type {
  GetSearchGigResultParams,
  SearchParams, SearchSort, SearchType,
} from '../search';
import type { ParsedUrlQuery } from 'querystring';

export const getSearchTypeText = (type: SearchType) => (type === 'gigs' ? '서비스' : '전문가');

export const defaultSearchParams: SearchParams = {
  type: 'gigs',
  page: 1,
  sort: '_score',
  service: 'web',
  metadata: {},
};

/**
 * url query를 searchParams로 변환합니다.
 * @param query SearchParams
 */
export const parseSearchParams = (query: ParsedUrlQuery) => {
  const params: SearchParams = {
    ...query,
  };

  if (query.keyword) {
    params.q = query.keyword as string;
  }

  if (query.type) {
    params.type = query.type as SearchType;
  }

  if (query.page) {
    params.page = Number(query.page);
  }

  if (query.sort) {
    params.sort = query.sort as SearchSort;
  }

  if (query.is_online) {
    params.is_online = query.is_online === 'true';
  }

  if (query.is_company) {
    params.is_company = query.is_company === 'true';
  }

  if (query.is_prime) {
    params.is_prime = query.is_prime === 'true';
  }

  if (query.has_portfolio) {
    params.has_portfolio = query.has_portfolio === 'true';
  }

  if (query.is_resident) {
    params.is_resident = query.is_resident === 'true';
  }

  if (query.category_id) {
    params.category_id = query.category_id as string;
  }

  if (query.skill_id) {
    params.skill_id = query.skill_id as string;
  }

  if (query.specialty_id) {
    params.specialty_id = query.specialty_id as string;
  }

  if (query.notSuggestSearch) {
    params.notSuggestSearch = query.notSuggestSearch === 'true';
  }

  if (query.force_q) {
    params.force_q = query.force_q as string;
  }

  if (query.metadata) {
    params.metadata = (query.metadata as string)
      .split('|')
      .map((o) => o.split(':'))
      .reduce<{ [key: string]: number[] }>((acc, [key, value]) => {
        if (key in acc) {
          acc[key].push(Number(value));
        } else {
          acc[key] = [Number(value)];
        }

        return acc;
      }, {});
  }

  return params;
};

/**
 * searchParams를 url query로 가공합니다.
 * @param query SearchParams
 */
export const stringifySearchParams = (query: SearchParams | GetSearchGigResultParams) => (
  Object.entries(query).reduce((params, [key, value]) => {
    if (key === 'metadata' && query.metadata) {
      return {
        ...params,
        metadata: Object.entries(query.metadata)
          .reduce<string[]>(
            (acc, [metaKey, metaValues]) => (metaValues.length
              ? acc.concat(metaValues
                .map((metaValue) => `${metaKey}:${metaValue}`)
                .join('|'))
              : acc),
            [],
          )
          .join('|'),
      };
    }

    return {
      ...params,
      [key]: String(value),
    };
  }, {})
);
