import styled from '@emotion/styled';
import { colors, breakpoints } from '@kmong/ui';

const SectionCopy = styled.p`
  font-size: 35px;
  color: ${colors.gray[800]};
  font-weight: 500;
  margin: 0 0 48px 0;
  line-height: 1.34;

  @media screen and (max-width: ${breakpoints.large}px){
    font-size: 24px;
    line-height: 1.4;
    margin: 0 0 24px 0;
  }
`;

export default SectionCopy;
