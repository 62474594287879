import { nl2br } from '@kmong/utils';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import type { SEOProps } from '../..';

export const sellerGuideFaqData = [
  {
    summary: '엔터프라이즈 전문가 등록 시 어떤 혜택이 있나요?',
    details: `엔터프라이즈 전문가로 등록하면 다음과 같은 혜택을 받을 수 있습니다.

  - 더 많은 프로젝트 기회: 대형 고객사의 프로젝트에 우선적으로 참여할 수 있습니다.
  - 전문성 인증: 엔터프라이즈 전문가로 인증받아 고객에게 신뢰성을 증명할 수 있습니다.
  - 추가 수익 기회: 대기업, 고예산 프로젝트에 참여함으로써 안정적인 수익을 창출할 수 있습니다.
  - 마케팅 지원: 크몽 엔터프라이즈 심사를 통해 전문가 마케팅 지원을 받을 수 있습니다.`,
  },
  {
    summary: '엔터프라이즈 전문가 사전 등록 기간은 무엇인가요?',
    details: `사전 전문가 등록 기간은 2024년 8월부터 베타 서비스로 진행됩니다. 
  사전 등록 기간 동안 기존 회원은 새로운 엔터프라이즈 프로필을 등록 및 수정할 수 있습니다. 

  추후 하반기에 런칭될 정식 서비스에서 등록된 엔터프라이즈 프로필로 프로젝트 지원과 활동이 가능합니다.`,
  },
  {
    summary:
      '엔터프라이즈 전문가 프로필은 기존 전문가 프로필과 어떤 차이가 있나요?',
    details: `크몽 엔터프라이즈 프로젝트 지원을 위해서 반드시 크몽 엔터프라이즈 전문가 프로필 등록이 필요합니다. 
  엔터프라이즈 전문가 프로필은 기존 전문가 프로필보다 더욱 세부적이고 전문적인 내용을 포함합니다.

  - 경력 및 자격 요건: 더 높은 경력과 자격 요건 필요.
  - 세부 프로젝트 사례: 주요 프로젝트 사례와 성과 상세 기재.
  - 고객 리뷰 및 평가: 더욱 심층적인 고객 리뷰 및 평가 필요.

  ※ 엔터프라이즈 전문가 프로필이 등록되어있지 않은 경우, 엔터프라이즈 프로젝트 지원이 불가합니다. (2024년 하반기 시행 예정)`,
  },
  {
    summary: '엔터프라이즈 전문가로 활동 시 이용 요금이 있나요?',
    details: `엔터프라이즈 전문가로 활동 시, 외주 프로젝트 중개에 한해 수수료가 발생합니다. 

  <b>[외주]</b>
  전문가의 수익금은 계약 1건을 기준으로 계산하며, 총 계약 대금에서 중개 수수료 13.2%(VAT 포함)를 차감한 금액입니다.

  ※ '총 계약 대금'이란? 의뢰인과 전문가 사이에 거래되는 대금의 총액으로 부가가치세를 포함한 금액을 의미합니다.

  <b>[예시]</b>
  - 총 계약 대금 : 11,000,000원(VAT 포함)
  - 중개 수수료 : 1,452,000원(VAT 포함)
  - 수익금 : 9,548,000원(VAT 포함)

  ※ 프로젝트 대금 결제처는 계약 형태에 따라 달라질 수 있어 크몽 전담 매니저가 별도로 안내해 드립니다.

  <b>[상주]</b>
  상주 프로젝트(지정된 장소에서 기간제 업무) 진행 시, 전문가에게 이용료 부과는 발생하지 않습니다.`,
  },
  {
    summary: '엔터프라이즈 전문가 등록을 위해 어떤 내용을 작성해야 하나요?',
    details: `엔터프라이즈 전문가 등록을 위해 다음과 같은 내용을 작성할 수 있습니다.
  다양하고 퀄리티 있는 내용을 작성할수록 엔터프라이즈 전문가로서 더 나은 매칭 기회를 얻을 수 있습니다.

  - 기본 정보: 이름, 연락처, 이메일 등 기본 정보
  - 경력 사항: 관련 분야에서의 경력 및 주요 업무 내용
  - 자격 및 인증: 관련 자격증 및 인증서
  - 프로젝트 사례: 참여한 주요 프로젝트의 상세한 설명 및 성과
  - 기술 및 능력: 보유한 기술 및 능력에 대한 상세 설명
  - 고객 평가: 이전 프로젝트에서 받은 고객 평가 및 리뷰
  - 포트폴리오: 실제 진행한 포트폴리오에 대한 이미지 및 상세 설명`,
  },
];

export const sellerGuideFaqJsonLd = `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    ${sellerGuideFaqData.map((faqItem) => `{
      "@type": "Question",
      "name": "${faqItem.summary}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${nl2br(faqItem.details)}"
      }
    }`)}
   ]
}`;

const title = '크몽 엔터프라이즈 - 전문가 프로필';

export const sellerGuideSEO: SEOProps = {
  title,
  description:
    '대기업도 믿고 맡기는 외주 서비스, 크몽 엔터프라이즈 전문가로 미리 등록하시면 정식 오픈 시 다양한 혜택을 드릴게요.',
  openGraph: {
    image: {
      url: `${CLOUDFRONT_URL}/press/og/og_enterprise_seller_guide.png`,
      alt: title,
    },
  },
  breadcrumbItemList: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      item: 'https://kmong.com/enterprise',
      name: '엔터프라이즈',
      position: 2,
    },
    {
      item: 'https://kmong.com/seller/guide',
      name: '엔터프라이즈 전문가 프로필 등록 가이드',
      position: 3,
    },
  ],
};
