import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { CustomProjectRequestAnswer, ProjectType } from '../customProject';

export interface EstimatedProject {
  averageMonths: number;
  minBudgetRange: number;
  maxBudgetRange: number;
  requestIds: number[];
  projectLink: string;
}

export interface GetEstimateProjectRequest {
  contents: string;
  plan: string;
}

const getEstimateProject = async ({ contents, plan }: GetEstimateProjectRequest): Promise<EstimatedProject> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/enterprise/estimate/project-budget-${contents}-${plan}.json`);

  return data.json();
};

export interface CustomProject {
  requestId: number;
  title: string;
  amount: number;
  proposalCount: number;
  projectType: ProjectType;
}

export interface RelatedProject {
  customProjects: CustomProject[];
}

const getRelatedProjects = async (requestIds: string) => {
  const { data } = await api.get<RelatedProject>(`/api/custom-project/v2/custom-projects?requestIds=${requestIds}`);

  return data;
};

export interface ProjectRequestDetail {
  id: number;
  title: string;
  projectType: ProjectType;
  amount: number;
  proposalCount: number;
  answers: CustomProjectRequestAnswer[];
}

const getProjectRequestDetail = async (requestId: number) => {
  const { data } = await api.get<ProjectRequestDetail>(`/api/custom-project/v1/estimate/requests/${requestId}/answers`);

  return data;
};

export const estimateApi = {
  getEstimateProject,
  getRelatedProjects,
  getProjectRequestDetail,
};
