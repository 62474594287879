import api from './api';

export type SignupServiceType = 'naver' | 'kakao' | 'facebook' | 'google' | 'apple' | 'email';

interface SignUp {
  email: string;
  mileage_code?: string;
  has_ads_information: string;
  utm_source?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_term?: string;
  first_referrer?: string;
  last_referrer?: string;
  job_type_id: string | null;
  job_sector_id: string | null;
  dupl_info_id?: string;
  conn_info_id?: string;
  attention_categories: string | null;
  is_seller: boolean;
}

export interface SignUpEmail extends SignUp {
  password: string;
  next_page?: string;
  utm_campaign?: string;
  target?: string;
}

export interface SignUpSNS extends SignUp {
  id: string;
  thumbnail: string;
}

interface SignUpResponse {
  USERID: number;
}

interface SignupFailure {
  message: string;
}

const postSignUp = async (service: Omit<SignupServiceType, 'email'>, payload: SignUpEmail | SignUpSNS): Promise<SignUpResponse | SignupFailure> => {
  try {
    const postURL = `/api/auth/v2/signup/${service ?? ''}`;
    const { data } = await api.post<SignUpResponse>(postURL, payload);

    return {
      USERID: data.USERID,
    };
  } catch (error) {
    return {
      message: '오류가 발생했습니다. 고객센터로 연락해주세요',
    } as SignupFailure;
  }
};

export type UserType = 'seller' | 'buyer';

const validateEmail = async (email: string) => {
  const { data } = await api.get(`/api/auth/v1/signup/email/validation?email=${email}`);

  return data;
};

export interface ReconnectSNSPayload {
  id: number;
  email: string;
}

const reconnectSNS = async (service: SignupServiceType, payload: ReconnectSNSPayload) => {
  await api.post(`/api/auth/v1/reconnect-sns/${service}`, payload);
};

export interface SignupRequest {
  email: string;
}

export interface Job {
  id: number;
  title: string;
}

export const signupApi = {
  postSignUp,
  validateEmail,
  reconnectSNS,
};
