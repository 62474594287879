import { numberFormat } from '@kmong/utils';

interface GetCouponAmountDescriptionProps {
  min: number;
  max: number;
}

export const getCouponAmountDescription = ({ min, max }: GetCouponAmountDescriptionProps) => {
  if (min > 0 && max > 0) {
    return `${numberFormat(min)}원 이상 구매 시 최대 ${numberFormat(max)}원 할인`;
  }

  if (min > 0 && max <= 0) {
    return `${numberFormat(min)}원 이상 구매 시`;
  }

  if (min <= 0 && max > 0) {
    return `최대 ${numberFormat(max)}원 할인`;
  }

  return '';
};
