module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-f9debd179c/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-45d59a4aa5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"크몽","short_name":"크몽","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/kmong-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7374fd7602eafb02761d8676aab1c166"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-use-query-params-virtual-7ec97366ad/0/cache/gatsby-plugin-use-query-params-npm-1.0.1-878a7051ae-62027d9099.zip/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-d3d91c487f/0/cache/gatsby-plugin-google-analytics-npm-5.13.1-b252bbe12e-483f41051b.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-9241865-32","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-bbd448e79e/0/cache/gatsby-plugin-canonical-urls-npm-5.13.1-b01f445972-e420319395.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.kmongcorp.com","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-dad93d2e32/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
