import api from '../api';
import type { BlogSection } from '.';

export interface BlogArticleCardBase {
  articleId: number;
  thumbnail: string;
  title: string;
  subtitle: string;
  blogSection: BlogSection;
}

interface BlogArticlesResponse {
  articles: BlogArticleCardBase[];
}

interface MainArticlesResponse {
  articles: MainArticle[];
}

export interface MainArticle extends BlogArticleCardBase {
  description: string | null;
  tags: string[];
}

const getMainArticles = async (customUA?: string) => {
  const { data } = await api.get<MainArticlesResponse>('/api/next/community/blog/v1/main-articles', getHeaders(customUA));

  return data.articles ?? [];
};

const getBestArticles = async (customUA?: string) => {
  const { data } = await api.get<BlogArticlesResponse>('/api/next/community/blog/v1/best-articles', getHeaders(customUA));

  return data.articles ?? [];
};

const getNewArticles = async (customUA?: string) => {
  const { data } = await api.get<BlogArticlesResponse>('/api/next/community/blog/v1/new-articles', getHeaders(customUA));

  return data.articles ?? [];
};

const getHeaders = (customUA?: string) => {
  if (!customUA) {
    return undefined;
  }

  return {
    headers: {
      'User-Agent': customUA,
    },
  };
};

const getBlogSectionMainArticles = async (blogSectionId: number, customUA?: string) => {
  const { data } = await api.get<BlogArticlesResponse>(`/api/next/community/blog/v1/sections/${blogSectionId}/main-articles`, getHeaders(customUA));

  return data.articles ?? [];
};

interface GetBlogSectionNewArticlesParams {
  page?: number;
  customUA?: string;
}

export interface GetBlogSectionNewArticlesResponse {
  totalItemCount: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  items: BlogArticleCardBase[];
}

const getBlogSectionNewArticles = async (blogSectionId: number, { page = 1, customUA }: GetBlogSectionNewArticlesParams) => {
  const { data } = await api.get<GetBlogSectionNewArticlesResponse>(`/api/next/community/blog/v1/sections/${blogSectionId}/new-articles`, {
    ...getHeaders(customUA),
    params: {
      page,
      perPage: customUA ? 10 : 20,
    },
  });

  return data;
};

interface GetBlogSectionsResponse {
  blogSections: BlogSection[];
}

const getBlogSections = async (customUA?: string) => {
  const { data } = await api.get<GetBlogSectionsResponse>('/api/next/community/blog/v1/sections', getHeaders(customUA));

  return data.blogSections ?? [];
};

export const blogApi = {
  getMainArticles,
  getBestArticles,
  getNewArticles,
  getBlogSectionMainArticles,
  getBlogSectionNewArticles,
  getBlogSections,
};
