import { CLOUDFRONT_URL } from '@kmong-service/utils';
import type { SEOProps } from '../..';

export const projectRegisterSEO: SEOProps = {
  title: '크몽 엔터프라이즈 - 기업 전용 아웃소싱 솔루션',
  description: '산업별 전문 매니저 상담, 국내 최대 전문가 풀, 계약 완수율 99%, 빠르고 안전한 전문가 매칭 서비스를 경험해보세요!',
  openGraph: {
    image: {
      url: `${CLOUDFRONT_URL}/press/og/og_enterprise.png`,
      alt: '크몽 엔터프라이즈 - 기업 전용 아웃소싱 솔루션',
    },
  },
  breadcrumbItemList: [
    {
      item: 'https://kmong.com',
      name: '홈',
      position: 1,
    },
    {
      item: 'https://kmong.com/enterprise',
      name: '엔터프라이즈',
      position: 2,
    },
    {
      item: 'https://kmong.com/enterprise/projects/new',
      name: '프로젝트 등록',
      position: 3,
    },
  ],
};
