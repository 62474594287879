import type { ArticleDetailModuleType } from './module.type';
import type { BlogSection } from '../../../blog';
import type { ArticleDetailModuleBase, ArticleDetailTitleBase, LinkBase } from '../base.type';

export enum ArticleDesignType {
  THREE_LIST = 'THREE_LIST',
  THREE_CUT = 'THREE_CUT',
}

export interface ArticleType extends ArticleDetailTitleBase, ArticleDetailModuleBase {
  type: ArticleDetailModuleType.ARTICLE;
  designType: ArticleDesignType.THREE_LIST | ArticleDesignType.THREE_CUT;
  buttonText: string | null;
  linkInfo: LinkBase | null;
  articles: ArticleCardBase[];
  isRandom: boolean;
}

export interface ArticleCardBase {
  articleId: number;
  thumbnail: string;
  title: string;
  subtitle: string | null;
  blogSection: BlogSection | null;
}
