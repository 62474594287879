import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, colors, breakpoints } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import SectionDescriptionOrigin from './SectionTitle';

const LastSectionBanner = styled.section`
  width: 100%;
  height: 320px;
  background-image: url("${CLOUDFRONT_URL}/assets/kmong_is/main-background-01.jpg");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.large}px){
    height: 192px;
  }
`;

const Main = styled.div<{ inView: boolean }>`
  text-align: center;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}
`;

const SectionTitle = styled(SectionDescriptionOrigin)`
  font-size: 35px;
  color: ${colors.white};
  margin-bottom: 32px;

  @media screen and (max-width: ${breakpoints.large}px){
    margin-bottom: 28px;
    font-size: 16px;
  }
`;

function LastSection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <LastSectionBanner ref={ref}>
      <Main inView={inView}>
        <SectionTitle>
          지금 크몽에서
          {' '}
          <b>다양한 서비스</b>
          를 만나보세요.
        </SectionTitle>
        <DesktopButton
          color="yellow"
          href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/"
          size="xlarge"
          variant="contained"
        >
          마켓 이용하기
        </DesktopButton>

      </Main>
    </LastSectionBanner>
  );
}

const DesktopButton = styled(Button)`
  width: 238px;
`;

export default LastSection;
