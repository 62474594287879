import type { ArticleDetailImageBase } from './base.type';
import type { BlogSection } from '../../blog';

/**
 * @description mdpick deprecated. use exhibit instead.
 * @link https://kmong.slack.com/archives/C0528F4A2QN/p1686621718604829?thread_ts=1686621610.230159&cid=C0528F4A2QN
 */

export enum ArticleDetailHeaderType {
  MARKET = 'MARKET',
  BLOG = 'BLOG',
}

export enum PanelType {
  BLOG = 'BLOG',
  EXHIBIT_IMAGE = 'EXHIBIT_IMAGE',
  EXHIBIT_TEMPLATE = 'EXHIBIT_TEMPLATE',
}

export interface ArticleDetailPanel {
  blog: BlogPanelType | null;
  exhibitImage: ExhibitImageType | null;
  exhibitTemplate: ExhibitTemplateType | null;
}

export interface BlogPanelType {
  type: PanelType.BLOG;
  title: string;
  subtitle: string;
  blogSection: BlogSection;
  isDisplayingFreelancerClub: boolean;
  publishedAt: string;
  tags: string | null;
}

export interface ExhibitImageType {
  type: PanelType.EXHIBIT_IMAGE;
  images: ArticleDetailImageBase;
  hasNavigationBars: boolean;
  navigationBars: PanelNavigationBar[] | null;
}

export interface ExhibitTemplateType {
  type: PanelType.EXHIBIT_TEMPLATE;
  title: string;
  subtitle: string | null;
  backgroundColor: string;
  textColor: TextColor;
  images: ArticleDetailImageBase;
  hasNavigationBars: boolean;
  navigationBars: PanelNavigationBar[] | null;
}

type TextColor = 'white' | 'black';

export interface PanelNavigationBar {
  moduleSort: number;
  text: string;
}
