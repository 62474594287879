import api from './api';
import type { Gig } from './gig';

export interface PrimeRootCategory {
  categoryId: number;
  name: string;
}
export interface GetRootCategoriesResponse {
  categories: PrimeRootCategory[];
}

export interface PrimeGigsParams {
  is_main?: boolean;
  marketPrimePath?: boolean;
  is_prime?: boolean;
}

export interface PrimeGigsResponse {
  gigs: Gig[];
  random_number: number;
}

export interface PrimeRootCategory {
  id: number;
  name: string;
}

export interface SubCategorySiteMap {
  id: number;
  name: string;
  parent_id: number;
  prime_root_category: PrimeRootCategory;
}

export interface PrimeThumbnail {
  id: number;
  image_url: string;
  name: string;
  order: number;
}

export interface PrimePopularCategory {
  subCategoryId: number;
  rootCategoryName: string;
  subCategoryName: string;
}

export interface PrimePopularCategoriesResponse {
  popularCategories: PrimePopularCategory[];
}

const getRootCategories = async () => {
  const { data } = await api.get<GetRootCategoriesResponse>('/api/category/v1/prime/root-categories');

  return data;
};

const getPrimeGigs = async (
  categoryId: number,
  params: PrimeGigsParams = { marketPrimePath: true, is_prime: true },
) => {
  const { data } = await api.get<PrimeGigsResponse>(
    `/api/v5/category/${categoryId}/gigs`,
    { params },
  );

  return data?.gigs ?? [];
};

const getPopularCategories = async () => {
  const { data } = await api.get<PrimePopularCategoriesResponse>('/api/category/v1/prime/popular-categories');

  return data;
};

const getPopularServices = async (categories: PrimePopularCategory[]) => {
  const popularServices = await Promise.all(categories.map(async ({ subCategoryId }) => {
    const response = await getPrimeGigs(
      subCategoryId,
      { is_main: true, marketPrimePath: true, is_prime: true },
    );

    return response;
  }));

  return popularServices;
};

export const primeApi = {
  getPrimeGigs,
  getRootCategories,
  getPopularCategories,
  getPopularServices,
};
