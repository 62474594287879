import api from '../api';

interface PutMutualCancellationResponse {
  orderId: number;
  status: string;
  updated_at: string;
}

interface PutMutualCancellationParams {
  orderId: number;
  orderProgressId: number;
}

const putMutualCancellationAccept = async ({ orderId, orderProgressId }: PutMutualCancellationParams) => {
  await api.put<PutMutualCancellationResponse>(`/api/order/v1/orders/${orderId}/progress/mutual-cancellation/accept`, { orderProgressId });
};

const putMutualCancellationReject = async ({ orderId, orderProgressId }: PutMutualCancellationParams) => {
  await api.put<PutMutualCancellationResponse>(`/api/order/v1/orders/${orderId}/progress/mutual-cancellation/reject`, { orderProgressId });
};

export const orderCancellationApi = {
  putMutualCancellationAccept,
  putMutualCancellationReject,
};
