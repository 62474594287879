import api from '../api';
import type { GetInboxGroupsParams } from './inboxGroups';
import type { InboxMessagesPayloadSearchParams } from './messages';

export interface GetInboxSearchParams extends GetInboxGroupsParams, InboxMessagesPayloadSearchParams {
  inbox_group_id?: number;
  partner_id?: number;
}

interface RelatedGIG {
  PID: number;
}

interface RelatedPortfolio {
  portfolioId: number;
}

interface RelatedProposal {
  proposalId?: number;
  resendProposal?: boolean;
}

interface RelatedOrder {
  orderId: number;
}

type RelatedService = RelatedGIG | RelatedPortfolio | RelatedOrder | RelatedProposal;

interface InboxUrlRequest {
  USERID: number;
  relatedService?: RelatedService;
}

/**
 * @param USERID number
 * @param relatedService { PID?: number; portfolioId?: number; proposalId?: number }
 */
const getInboxUrl = async ({
  USERID,
  relatedService,
}: InboxUrlRequest) => {
  const inboxGroupId = await postInboxGroups(USERID);
  let relatedServices = '';
  if (relatedService) {
    relatedServices = getRelatedServiceParams(relatedService);
  }

  const inboxPartnerId = `&partner_id=${USERID}`;

  return `/inboxes?inbox_group_id=${inboxGroupId}${inboxPartnerId}${relatedServices}`;
};

const getRelatedServiceParams = (services: RelatedService) => Object.keys(services).map((key) => {
  if (key === 'PID' && 'PID' in services) {
    return `&related_gig_id=${services[key]}`;
  }

  if (key === 'portfolioId' && 'portfolioId' in services) {
    return `&related_portfolio_id=${services[key]}`;
  }

  if (key === 'proposalId' && 'proposalId' in services) {
    return `&related_custom_project_proposal_id=${services[key]}`;
  }

  if (key === 'resendProposal' && 'resendProposal' in services) {
    return '&re_proposal';
  }

  if (key === 'orderId' && 'orderId' in services) {
    return `&related_order_id=${services[key]}`;
  }

  return '';
}).join('') ?? '';

interface PostInboxGroupsResponse {
  inbox_group_id: number;
}

const postInboxGroups = async (USERID: number) => {
  const { data } = await api.post<PostInboxGroupsResponse>('/api/v5/inbox-groups', {
    USERID,
  });

  return data.inbox_group_id;
};

interface RelatedGigInboxRequest {
  USERID: number;
  inbox_group_id: number;
  mid: number;
  pid: number;
}

/**
 * @desc: IT 카테고리에 속한 서비스에 문의한 구매자가 답변을 받았는지 확인. 특정 조건에 따라 캠페인 액션 실행
 */
const relatedGigInboxResponseCheck = (payload: RelatedGigInboxRequest) => api.post('/api/v5/campaign/related-gig-inbox-message-response-check', payload);

interface GetHasInboxMessagesResponse {
  hasInboxMessages: boolean;
}

const getHasInboxMessages = async (partnerUserId: number) => {
  const { data } = await api.get<GetHasInboxMessagesResponse>(`/api/inbox/v1/hasInboxMessages/${partnerUserId}`);

  return data?.hasInboxMessages;
};

export interface AutoMessage {
  id: number;
  question: string;
  answer: string;
  images: AutoMessageImage[];
  sort: number;
}

interface AutoMessageImage {
  path: string;
  sort: number;
}

export interface GetAutoMessagesResponse {
  autoMessages: AutoMessage[];
}

export type AutoMessagesProvider = 'SELLER' | 'GIG';

export interface CommonAutoMessagesParams {
  provider: AutoMessagesProvider;
  providerId: number;
}

const getAutoMessages = async (params: CommonAutoMessagesParams) => {
  const { data } = await api.get<GetAutoMessagesResponse>('/api/next/inbox-app/seller/v1/auto-messages', {
    params,
  });

  return data;
};

export enum BlockedTypeLabel {
  BYPASS = '직접결제유도',
  BAD_WORD = '모욕/비방 사용',
  ETC = '기타 운영정책 위반',
}

export type BlockedType = keyof typeof BlockedTypeLabel;

export interface BlockedInfo {
  blockedType: BlockedType;
  blockedReason: string;
  blockedDateTime: string;
  unblockedDate: string;
  remainingDay: number;
}

export interface GetAutoMessageUsersResponse {
  usedFlag: boolean;
  blockedFlag: boolean;
  blockedInfo: BlockedInfo | null;
}

export interface CommonAutoMessagesFormPayload {
  question: string;
  answer: string;
}

type AutoMessagesPayload = CommonAutoMessagesFormPayload & CommonAutoMessagesParams

const postAutoMessages = async (payload: AutoMessagesPayload) => {
  const { data } = await api.post('/api/next/inbox-app/seller/v1/auto-messages', payload);

  return data;
};

const putAutoMessages = async (autoMessageId: number, payload: AutoMessagesPayload) => {
  const { data } = await api.put(`/api/next/inbox-app/seller/v1/auto-messages/${autoMessageId}`, payload);

  return data;
};

const deleteAutoMessage = async (autoMessageId: number, params: CommonAutoMessagesParams) => {
  const { data } = await api.delete(`/api/next/inbox-app/seller/v1/auto-messages/${autoMessageId}`, {
    params,
  });

  return data;
};

const deleteAutoMessages = async () => {
  const { data } = await api.delete('/api/next/inbox-app/seller/v1/auto-message-users/block');

  return data;
};

const getAutoMessageUsers = async () => {
  const { data } = await api.get<GetAutoMessageUsersResponse>('/api/next/inbox-app/seller/v1/auto-message-users');

  return data;
};

const putAutoMessageUsersUsedFlag = async (usedFlag: boolean) => {
  const { data } = await api.put('/api/next/inbox-app/seller/v1/auto-message-users/used-flag', {
    usedFlag,
  });

  return data;
};

export type PutAutoMessagesItemSort = Pick<AutoMessage, 'sort' | 'id'>;

export interface PutAutoMessageSortsPayload extends CommonAutoMessagesParams {
  autoMessages: PutAutoMessagesItemSort[];
}

const putAutoMessagesSort = async (payload: PutAutoMessageSortsPayload) => {
  const { data } = await api.put('/api/next/inbox-app/seller/v1/auto-messages/sort', payload);

  return data;
};

export interface GetInboxBannedKeywordsResponse {
  inboxBannedKeywords: InboxBannedKeyword[];
}

export interface InboxBannedKeyword {
  id: number;
  keyword: string;
  senderWarningMessage: string | null;
  receiverWarningMessage: string | null;
}

const getInboxBannedKeywords = async () => {
  const { data } = await api.get<GetInboxBannedKeywordsResponse>('/api/inbox/v1/inbox-banned-keywords');

  return data;
};

export const inboxApi = {
  getInboxUrl,
  postInboxGroups,
  relatedGigInboxResponseCheck,
  getHasInboxMessages,
  getAutoMessages,
  postAutoMessages,
  putAutoMessages,
  deleteAutoMessage,
  deleteAutoMessages,
  getAutoMessageUsers,
  putAutoMessageUsersUsedFlag,
  putAutoMessagesSort,
  getInboxBannedKeywords,
};
