export function formatCompanyLicenseNumber(value: string) {
  if (!value) {
    return '';
  }

  const firstPart = value.slice(0, 3);
  const secondPart = value.slice(3, 5);
  const thirdPart = value.slice(5, 10);

  return `${firstPart}-${secondPart}-${thirdPart}`;
}
