import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors, Divider } from '@kmong/ui';

export interface Notice {
  id: number;
  title: string;
  createdAt: string;
  contents: string[];
  footer: string[];
}

interface NoticeItemProps {
  notice: Notice;
}

function NoticeItem({ notice }: NoticeItemProps) {
  const { title, createdAt } = notice;

  return (
    <NoticeItemBlock>
      <Title>{title}</Title>
      <CreatedAt>{createdAt}</CreatedAt>
    </NoticeItemBlock>
  );
}

const NoticeItemBlock = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.medium}px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Title = styled.h4`
  flex: 1;
  font-size: 22px;
  font-weight: 400;
  color: ${colors.gray[900]};

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`;

const CreatedAt = styled.p`
  color: ${colors.gray[600]};
  font-size: 18px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 14px;
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: ${colors.gray[900]};
`;

export default NoticeItem;
