import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, Button, colors } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import layoutBase from '../../lib/styles/layoutBase';
import SectionCopy from './SectionCopy';
import SectionDescription from './SectionDescription';
import SectionTitle from './SectionTitle';
import View from './View';

function PrimeSection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <PrimeSectionBlock>
      <Main ref={ref}>
        <VideoSection inView={inView}>
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={`${CLOUDFRONT_URL}/assets/kmong_is/market-prime-still-001.jpg`}
          >
            <source
              src={`${CLOUDFRONT_URL}/assets/kmong_is/market-prime.mp4`}
              type="video/mp4"
            />
          </video>
          <View platform="mobile">
            <Button
              fullWidth
              color="orange"
              href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/prime"
              size="xlarge"
              variant="contained"
            >
              Prime 서비스 둘러보기
            </Button>
          </View>
        </VideoSection>
        <ContentSection inView={inView}>
          <SectionTitle>Prime 서비스</SectionTitle>
          <SectionCopy>
            포트폴리오와 고객평가를 통해
            <br />
            크몽이 엄선한 프리미엄 서비스
          </SectionCopy>
          <SectionDescription>
            <View platform="desktop">
              100만 건의 거래 분석과 인터뷰, 레퍼런스 체크를 통해 엄선한
              <br />
              Top2% 전문가의 Prime 서비스를 만나보세요.
            </View>
            <View platform="mobile">
              100만 건의 거래 분석과 인터뷰,
              <br />
              레퍼런스 체크를 통해 엄선한
              <br />
              Top2% 전문가의 Prime 서비스를 만나보세요.
            </View>
          </SectionDescription>
          <View platform="desktop">
            <Button
              color="orange"
              css={css`width: 238px;`}
              href="https://kmong.onelink.me/BwxQ?af_dp=kmong://kmong.com&af_web_dp=https://kmong.com/prime"
              size="xlarge"
              variant="contained"
            >
              Prime 서비스 둘러보기
            </Button>
          </View>
        </ContentSection>
      </Main>
    </PrimeSectionBlock>
  );
}

const PrimeSectionBlock = styled.section`
  padding: 62px 16px;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 62px 20px 20px 20px;
  }
`;

const Main = styled.div`
  ${layoutBase};
  padding: 0;
  display: flex;

  @media screen and (max-width: ${breakpoints.large}px){
    flex-direction: column-reverse;
  }
`;

const ContentSection = styled.div<{ inView: boolean }>`
  margin-left: 110px;
  flex: 1;
  opacity: 0;
  transform: translateX(-40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateX(0);
    opacity: 1;
  `}

  @media screen and (max-width: ${breakpoints.large}px){
    margin: 0;
  }
`;

const VideoSection = styled.div<{ inView: boolean }>`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue[900]};
  height: 434px;

  video {
    width: 530px;
  }

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 0;
    margin: 32px 0 0 0;
    flex-direction: column;

    video {
      width: 100%;
      margin-bottom: 32px;
    }
  }
`;

export default PrimeSection;
