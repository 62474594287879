import api from './api';

const postEmailReject = async (email: string) => {
  const { data } = await api.post(
    '/api/v5/reject/email/set-email-reject',
    { email },
  );

  return data;
};

export const rejectEmailApi = {
  postEmailReject,
};
