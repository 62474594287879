import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, Button } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import layoutBase from '../../lib/styles/layoutBase';
import SectionCopy from './SectionCopy';
import SectionDescription from './SectionDescription';
import SectionTitle from './SectionTitle';
import View from './View';

function EnterpriseSection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <EnterpriseSectionBlock>
      <Main ref={ref}>
        <ContentSection inView={inView}>
          <SectionTitle>엔터프라이즈</SectionTitle>
          <SectionCopy>
            기업 아웃소싱,
            <br />
            전담 매니저와 함께 하세요.
          </SectionCopy>
          <SectionDescription>
            중·대형 프로젝트·기업 외주·정부지원 사업을 의뢰하고
            <br />
            One-Stop 서비스를 경험해보세요.
          </SectionDescription>
          <View platform="desktop">
            <Button
              color="blue"
              css={css`width: 238px;`}
              href="https://kmong.com/enterprise"
              size="xlarge"
              variant="contained"
            >
              엔터프라이즈 둘러보기
            </Button>
          </View>
        </ContentSection>
        <View platform="desktop">
          <ImageSection inView={inView}>
            <img
              alt="크몽 엔터프라이즈"
              src={`${CLOUDFRONT_URL}/assets/kmong_is/content-enterprise-img.jpg`}
              srcSet={`${CLOUDFRONT_URL}/assets/kmong_is/content-enterprise-img@2x.jpg 1920w,
              ${CLOUDFRONT_URL}/assets/kmong_is/content-enterprise-img@3x.jpg`}
            />
          </ImageSection>
        </View>
        <View platform="mobile">
          <ImageSection inView={inView}>
            <img
              alt="크몽 엔터프라이즈"
              src={`${CLOUDFRONT_URL}/assets/kmong_is/content-enterprise-m-img.jpg`}
              srcSet={`${CLOUDFRONT_URL}/assets/kmong_is/content-enterprise-m-img.jpg 375w,
              ${CLOUDFRONT_URL}/assets/kmong_is/content-enterprise-m-img@2x.jpg`}
            />
          </ImageSection>
          <Button
            fullWidth
            color="blue"
            href="https://kmong.com/enterprise"
            size="xlarge"
            variant="contained"
          >
            자세히 알아보기
          </Button>
        </View>
      </Main>
    </EnterpriseSectionBlock>
  );
}

const EnterpriseSectionBlock = styled.section`
  padding: 62px 16px;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 62px 20px 20px 20px;
  }
`;

const Main = styled.div`
  ${layoutBase};
  display: flex;

  > div {
    flex: 1;
  }

  @media screen and (max-width: ${breakpoints.large}px){
    display: block;
    padding: 0;
  }
`;

const ContentSection = styled.div<{ inView: boolean }>`
  @media screen and (max-width: ${breakpoints.large}px){
    padding-left: 0;
  }

  opacity: 0;
  transform: translateX(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateX(0);
    opacity: 1;
  `}
`;

const ImageSection = styled.div<{ inView: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 0;
    margin: 60px 0 32px 0;
  }
  
`;

export default EnterpriseSection;
