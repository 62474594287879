import api from '../api';
import type { Gig } from '../gig';

export interface FastReactionResponse {
  gigs: Gig[];
}

const getFastReactions = async (categoryId: number) => {
  const { data } = await api.get<FastReactionResponse>(`/api/gig/v1/categories/${categoryId}/fast-reaction-gigs`);

  return data?.gigs ?? [];
};

export const fastReactionApi = {
  getFastReactions,
};
