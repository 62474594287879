import api from './api';

export interface KmongVOD {
  id: number;
  vod_id: number;
  title: string;
  description: string;
  image: string | null;
  link: string | null;
  play_time: string;
}

export interface Lesson {
  title: string;
  resource_uuid: string;
  resource_uri: string;
  resource_name: string | null;
  timelapse: number;
}

export interface LessonGroup {
  title: string;
  group: number;
  lessons: Lesson[];
}

export interface GetCourseLectureResponse {
  uuid: string;
  type: string;
  service_title: string;
  summary_title: null | string;
  summary_type_id: number;
  summaries: string[];
  lesson_groups: LessonGroup[];
  total_lesson_count: number;
  total_timelapse: number;
  PID: number;
  OID: number;
  expired_date: string;
  rated: false;
  order_number: number;
}

const getCourseLecture = async (VOD: string) => {
  const { data } = await api.get<GetCourseLectureResponse>(`/api/course/lecture/v1/${VOD}`);

  return data;
};

export interface VimeoData {
  created_at: string;
  is_completed: boolean;
  last_playback: {
    duration: number;
    percent: number;
    second: number;
  };
  video_uuid: string;
}

export interface UpdateVideoParams {
  duration: number;
  percent: number;
  seconds: number;
}

export interface UpdateVideoResponse {
  data: VimeoData;
  error_code: string;
  message: string;
  result: string;
}

const updateVideo = async (id: string, params: UpdateVideoParams) => {
  const { data } = await api.put<UpdateVideoResponse>(
    `/api/next/playback/video/${id}`,
    {
      duration: params.duration,
      percent: params.percent,
      second: params.seconds,
    },
  );

  return data;
};

export interface PatchVideoCompleteResponse {
  data: VimeoData;
  error_code: string;
  message: string;
  result: string;
}

const patchVideoComplete = async (id: string) => {
  const { data } = await api.patch<PatchVideoCompleteResponse>(
    `/api/next/playback/video/complete/${id}`,
    undefined,
  );

  return data;
};

interface GetVideoParams {
  videoUuids: string;
}

export interface GetVideoResponse {
  data: VimeoData[];
  error_code: string;
  message: string;
  result: string;
}

const getVideo = async (params: GetVideoParams) => {
  const { data } = await api.get<GetVideoResponse>('/api/next/playback/video', {
    params,
  });

  return data;
};

export const kmongVODApi = {
  getCourseLecture,
  updateVideo,
  patchVideoComplete,
  getVideo,
};
