import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, colors } from '@kmong/ui';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import NoticeItem, { StyledDivider } from '../../components/notice/NoticeItem';
import SEO from '../../components/SEO';
import noticeList from '../../data/noticeList.json';
import useSiteMetadata from '../../lib/hooks/useSiteMetaData';
import type { Notice } from '../../components/notice/NoticeItem';

interface IndexProps {
  location: typeof window.location;
}

function NoticePage({ location }: IndexProps) {
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout minHeight="74vh">
      <SEO
        description="크몽 공지사항 | 크몽에서 알려드립니다."
        siteURL={`${siteUrl}${location.pathname}`}
        title="공지사항"
        breadcrumbListElement={[
          {
            '@type': 'ListItem',
            position: 1,
            name: '공지사항',
            item: 'https://www.kmongcorp.com/notice',
          },
        ]}
      />
      <ContentWrapper>
        <Section>
          <Title>공지사항</Title>
          <NoticeList>
            <StyledDivider />
            {noticeList.map((notice: Notice) => (
              <React.Fragment key={notice.id}>
                <Link to={`/notice/${notice.id}`}>
                  <NoticeItem notice={notice} />
                </Link>
                <StyledDivider />
              </React.Fragment>
            ))}
          </NoticeList>
        </Section>
      </ContentWrapper>
    </Layout>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
`;

const Title = styled.h1`
  color: ${colors.gray[800]};
  text-align: center;

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 24px;
  }
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 16px;
`;

const NoticeList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  a {
    text-decoration: none;
  }
`;

export default NoticePage;
