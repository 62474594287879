import api from '../../api';

interface FundsAccount {
  id: number;
  type: 'INIT' | 'CHARGED' | 'PAID_ORDER' | 'CANCELED_ORDER' | 'REFUNDED' | 'EXPIRED_VBANK' | 'EXPIRED' | 'DELETED' | 'PAID_PLATFORM_FEE' | 'CANCELED_PLATFORM_FEE';
  typeLabel: string;
  amount: number;
  orderId: number;
  createdDateTime: string;
}

interface OrderAggregate {
  orderId: number;
  productTitle: string;
}

interface Checkout {
  providerType: string;
  expiredDateTime: string;
  mileageTitle: string;
}

export interface CashHistory {
  fundsAccount: FundsAccount;
  orderAggregate: OrderAggregate;
  checkout: Checkout;
}

export interface CashHistoryResponse {
  totalItemCount: number;
  previous_page_link: string | null;
  currentPage: string | null;
  lastPage: number;
  items: CashHistory[];
}

export interface CashHistoryParams {
  page: number;
  startedDate: string;
  endedDate: string;
  searchFundsAccountType: string;
  perPage: number;
}

const getCashHistory = async (params: CashHistoryParams) => {
  const { data } = await api.get<CashHistoryResponse>('/api/next/order-app/payment/v1/payments/buyer/funds/histories', { params });

  return data;
};

export interface CashHistoriesInfo {
  fundAmount: number;
  cashAmount: number;
  mileageAmount: number;
}

const getCashHistoriesInfo = async () => {
  const { data } = await api.get<CashHistoriesInfo>('/api/next/order-app/payment/v1/payments/buyer/funds/amount/aggregate');

  return data;
};

export interface CashAmountRemaining {
  amount: number;
}

export interface ExpectedExpiredAmount {
  expectedExpiredAmount: number;
}

const getExpectedExpiredAmount = async () => {
  const { data } = await api.get<ExpectedExpiredAmount>('/api/next/order-app/payment/v1/payments/buyer/funds/expected-expired/amount');

  return data;
};

interface RefundRequest {
  amount: number;
  refundRequestId: number;
  createdDateTime: string;
}

const getCashHistoriesRefundRequest = async () => {
  const { data } = await api.get<RefundRequest>('/api/next/order-app/payment/v1/payments/buyer/refund/refund-request/amount');

  return data;
};

const cancelRefundRequest = async (id: number) => {
  const { data } = await api.delete(`/payments/funds/refund/${id}`);

  return data;
};

type RefundAccountType = 'PERSONAL' | 'BUSINESS';

export interface RefundAccountDetail {
  userRefundAccountId: number;
  bankName: string;
  bankCode: string;
  depositor: string;
  accountNumber: string;
  accountType: RefundAccountType;
}

export interface UserRefundAccountResponse {
  userRefundAccountDetail: RefundAccountDetail | null;
}

const getUserRefundAccount = async () => {
  const { data } = await api.get<UserRefundAccountResponse>('/api/next/order-app/payment/v1/payments/buyer/refund/user-refund-accounts');

  return data;
};

interface RemainingCashResponse {
  amount: number;
}

const getRemainingCash = async () => {
  const { data } = await api.get<RemainingCashResponse>('/api/next/order-app/payment/v1/payments/buyer/funds/cash/amount/remaining');

  return data;
};

interface RequestRefundResponse {
  refundRequestId: number;
}

const requestRefund = async () => {
  const { data } = await api.post<RequestRefundResponse>('/api/payment/buyer/refund/v1/funds/request');

  return data;
};

export const cashApi = {
  getCashHistory,
  getCashHistoriesInfo,
  getRemainingCash,
  getExpectedExpiredAmount,
  getCashHistoriesRefundRequest,
  cancelRefundRequest,
  getUserRefundAccount,
  requestRefund,
};
