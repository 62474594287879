import api from '../api';
import { isKmongError } from '../types';
import type { MeetingSchedule, MeetingStatus } from '../proposal';

export type CustomProjectRequestStatus = 'WAITING' | 'APPROVAL' |'REJECTED' | 'CLOSED' | 'ORDER' | 'DELETED' | 'OVER_DEADLINE';

export interface CustomProjectRequest {
  id: number;
  breadcrumb: string;
  status: CustomProjectRequestStatus;
  title: string;
  category: {
    cat1: number;
    cat1_name: string;
    cat2: number;
    cat2_name: string;
  };
  content: string;
  main_image: string;
  amount: number;
  deadline: number;
  is_tax: boolean;
  business_type: 'PERSONAL' | 'COMPANY' | 'PROSPECTIVE_FOUNDER';
  project_type: ProjectType;
  is_government: boolean;
  days: number;
  proposal_count: number;
  buyer: Buyer;
}

export interface Order {
  OID: number;
  seller_USERID: number;
}

export interface Buyer {
  USERID: number;
  username: string;
  is_online: boolean;
  image: string;
}

export type ProjectType = 'OUTSOURCING' | 'RESIDENT';

type Status = 'APPROVAL' | 'CLOSED' | 'REJECTED';

export interface CustomProjectRequestAnswer {
  title: string;
  type: string;
  contents: string[];
  description: string;
  files: File[];
}

export interface File {
  FID: number;
  fname: string;
  preview_url: string;
  download: string;
}

export interface CustomProjectRequestResponse {
  id: number;
  title: string;
  description: string | null;
  deadline: number;
  amount: number;
  status: Status;
  project_type: ProjectType;
  is_mine: boolean;
  is_proposal: boolean;
  is_government: boolean;
  main_image: string;
  breadcrumb: string;
  answers: CustomProjectRequestAnswer[];
  is_tax: boolean;
  proposal_count: number;
  order?: Order;
  auth_type: 'UPMARKET' | 'MARKET';
  root_category_id: number;
  root_category_name: string;
  sub_category_id: number;
  sub_category_name: string;
  buyer: Buyer;
  is_confidential: boolean;
}

export interface GetCustomProjectRequestsResponse {
  total: number;
  last_page: number;
  requests: CustomProjectRequest[];
}

export type CustomProjectSortType = 'CREATED_AT' | 'DEADLINE_DATE' | 'AMOUNT';

export interface GetCustomProjectRequestsParams {
  page?: number;
  per_page?: number;
  q?: string;
  sort?: CustomProjectSortType;
  category_list?: string;
  sub_category_list?: string;
  project_type?: string;
  is_dead?: boolean;
  is_landing_page?: boolean;
}

const getCustomProjectRequests = async (params: GetCustomProjectRequestsParams) => {
  const { data } = await api.get<GetCustomProjectRequestsResponse>('/api/custom-project/v1/requests', {
    params,
  });

  return data;
};

export interface GetCustomProjectCategoryResponse {
  categories: CustomProjectCategory[];
}

export interface CustomProjectChildCategory {
  id: number;
  name: string;
  is_resident?: boolean;
}

export interface CustomProjectCategory {
  id: number;
  is_resident: boolean;
  name: string;
  child_categories: CustomProjectChildCategory[];
}

const getCustomProjectCategories = async () => {
  const { data } = await api.get<GetCustomProjectCategoryResponse>('/api/custom-project/v1/categories');

  return data;
};

const getCustomProjectRequest = async (requestId: number) => {
  const { data } = await api.get<CustomProjectRequestResponse>(`/api/custom-project/v1/requests/${requestId}`);

  return data;
};

export interface CheckRequestAvailabilityResponse {
  is_available: boolean;
}

const checkRequestAvailability = async (requestId: number) => {
  try {
    const { data } = await api.get<CheckRequestAvailabilityResponse>(`/api/custom-project/v2/seller/proposal/check/${requestId}`);

    return data;
  } catch (error) {
    if (isKmongError<number>(error)) {
      return {
        error: {
          message: error.response.data.message,
          code: error.response.data.code,
        },
      };
    }

    return null;
  }
};

export interface ChangeVacationStatusParams {
  type: string;
}

const changeVacationStatus = async (params: ChangeVacationStatusParams) => {
  const { data } = await api.post('/vacation_request', params);

  return data;
};

export interface Policy {
  from_amount: number;
  to_amount: number;
  commission: number;
  order: number;
  expected_profits: number;
  expected_commission: number;
  title: string;
}

export interface GetExpectedProfitsResponse {
  expected_profits: {
    title: string;
    type: string;
    amount: number;
    profits: number;
    policies: Policy[];
  };
  service_pg_commission: {
    title: string;
    type: string;
    type_amount: number;
    amount: number;
  };
  value_added_tax: {
    title: string;
    type: string;
    type_amount: number;
    amount: number;
  };
  total_order_price: number;
  calculation_type: string;
  total_expected_profits: number;
}

export interface ExpectedProfitsParams {
  PID?: number; // gig id
  requestId?: number; // custom-project request id
  amount: number;
}

const getExpectedProfits = async (params: ExpectedProfitsParams) => {
  const { data } = await api.get<GetExpectedProfitsResponse>('/api/v5/expected-profits', {
    params,
  });

  return data;
};

export interface AmountDetail {
  type: string;
  title: string;
  contents: string;
  price: string;
}

export interface SaveProposalParam {
  amount: number;
  amount_details?: AmountDetail[];
  buyer_USERID: number;
  content: string;
  days: number;
  files: number[];
  is_tax: boolean;
  request_id: number;
}

export interface SaveProposalOnlyRequestIdAsParam {
  request_id: number;
}

export interface SaveProposalResponse {
  proposal_id: number;
}

export interface ErrorResponse {
  error: {
    code: number;
    message: string;
  };
}

async function saveProposal(
  param: SaveProposalParam
): Promise<SaveProposalResponse | ErrorResponse>;

async function saveProposal(
  param: SaveProposalOnlyRequestIdAsParam
): Promise<SaveProposalResponse | ErrorResponse>;

async function saveProposal(param: SaveProposalParam | SaveProposalOnlyRequestIdAsParam): Promise<SaveProposalResponse | ErrorResponse> {
  try {
    const { data } = await api.post<SaveProposalResponse>('/api/custom-project/v1/seller/proposal', param);

    return data;
  } catch (error) {
    if (isKmongError<number>(error)) {
      return {
        error: {
          message: error.message ?? '',
          code: Number(error.response.data.code ?? 0),
        },
      };
    }

    return {
      error: {
        message: '',
        code: 0,
      },
    };
  }
}

const getCustomProjectRequestsAppLink = async (requestId: number) => {
  const { data } = await api.get<string>(`/api/custom-project/v1/requests/app/${requestId}`);

  return data ?? '';
};

export interface GetCustomProjectRequestFileDownloadResponse {
  url: string;
}

const getCustomProjectRequestFileDownload = async (FID: number) => {
  const { data } = await api.get<GetCustomProjectRequestFileDownloadResponse>(`/api/v5/files/download/custom-project/request/${FID}`);

  return data;
};

export interface GetBuyerProposalsResponse {
  totalCount: number;
  lastPage: number;
  proposals: BuyerProposal[];
  hasOngoing: boolean;
  rootCategoryId: number;
  rootCategoryName: string;
  subCategoryId: number;
  subCategoryName: string;
  projectType: ProjectType;
  unrepliedMeetings: number;
}

export interface BuyerProposal {
  id: number;
  amount: number | null;
  days: number | null;
  content: string;
  status: BuyerProposalStatus;
  meeting: MeetingSchedule | null;
  buyerUserId: number;
  seller: BuyerProposalSeller;
  isBookmarked: boolean;
  isRead: boolean;
}

export type BuyerProposalStatus = 'WAITING' | 'ONGOING';

export type BuyerProposalSeller = ProposalSeller | ProposalResidentSeller;

export interface ProposalSeller {
  userId: number;
  nickname: string;
  isOnline: boolean;
  status: string;
  thumbnail: string;
  ratingAvg: number;
  ratingCount: number;
  isRecommendedSeller: boolean;
  hasPortfolio: boolean;
  portfolioCount: number;
}

export interface ProposalResidentSeller extends ProposalSeller {
  skill: Skill[];
  specialty: Specialty[];
  totalCareer: number;
  isCompletedProfile: boolean;
  place: Place;
  residentProject: ResidentProject[];
  residentProjectCount: ResidentProjectCount;
}

interface Skill {
  categoryId: number;
  categoryName: string;
  options: ResidentOption[];
}

export interface ResidentOption {
  id: number;
  name: string;
}

interface Specialty {
  categoryId: number;
  categoryName: string;
  options: ResidentOption[];
}

interface Place {
  id: number;
  name: string;
}

interface ResidentProject {
  subject: Subject;
  projectStartDate: string;
  projectEndDate?: string | null;
  isOngoing: boolean;
  title: string;
}

interface Subject {
  subjectId: number;
  subjectName: string;
}

export interface ResidentProjectCount {
  public: number;
  finance: number;
  it: number;
  commerce: number;
  travel: number;
  etc: number;
}

export const BUYER_PROPOSALS_SORTINGS_MAP = [
  {
    label: '만족도순',
    value: 'rating',
  },
  {
    label: '최신순',
    value: 'created',
  },
  {
    label: '저가순',
    value: 'amount',
  },
] as const;

export type BuyerProposalsSortKey = typeof BUYER_PROPOSALS_SORTINGS_MAP[number]['value'];

export interface GetBuyerProposalRequestParams {
  page: number;
  sort?: BuyerProposalsSortKey;
  isBookmarked?: boolean;
  isNotRespond?: boolean;
  meetingStatus?: MeetingStatus | 'ALL';
}

const getBuyerProposals = async (requestId: number, params: GetBuyerProposalRequestParams) => {
  const { data } = await api.get<GetBuyerProposalsResponse>(`/api/custom-project/v1/buyer/requests/${requestId}/proposals`, {
    params,
  });

  return data;
};

const removeProposal = async (proposalId: number) => {
  await api.put(`/api/v5/custom-project/seller/proposal/${proposalId}/DELETED`);
};

const readRequest = async (requestId: number) => {
  await api.get(`/api/v5/custom-project/request/${requestId}/read`);
};

export const customProjectApi = {
  getCustomProjectRequests,
  getCustomProjectCategories,
  getCustomProjectRequest,
  checkRequestAvailability,
  changeVacationStatus,
  getExpectedProfits,
  saveProposal,
  getCustomProjectRequestsAppLink,
  getCustomProjectRequestFileDownload,
  getBuyerProposals,
  removeProposal,
  readRequest,
};
