import type { ArticleDetailModuleType } from './module.type';
import type { ArticleDetailModuleBase } from '../base.type';

export enum ArticleDetailDividerDesignType {
  SOLID_BOLD = 'SOLID_BOLD',
  SOLID_THIN = 'SOLID_THIN',
  DASHED = 'DASHED',
}

export interface ArticleDetailDividerType extends ArticleDetailModuleBase {
  type: ArticleDetailModuleType.DIVIDER;
  designType: ArticleDetailDividerDesignType;
}
