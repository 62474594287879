/** @jsx jsx */
import React from 'react';
import { Global, jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { baseCSS, breakpoints } from '@kmong/ui';
import Footer from './Footer';
import Header from './Header';

require('intersection-observer');

const MainBlock = styled.main<{ clearOnTop: boolean; minHeight: string }>`
  min-height: ${({ minHeight }) => minHeight};
  overflow: hidden;

  ${(props) => !props.clearOnTop && css`
    margin-top: 64px;
    @media screen and (max-width: ${breakpoints.medium}px){
      margin-top: 48px;
    }
  `}
`;

export interface LayoutProps {
  clearOnTop?: boolean;
  children: React.ReactNode;
  whiteTextHeader?: boolean;
  darkMode?: boolean;
  noFooter?: boolean;
  minHeight?: string;
}

function Layout({
  clearOnTop = false,
  children,
  whiteTextHeader,
  darkMode,
  noFooter,
  minHeight = '100vh',
}: LayoutProps) {
  return (
    <React.Fragment>
      <Global styles={baseCSS} />
      <Header
        clearOnTop={clearOnTop}
        darkMode={darkMode}
        whiteText={whiteTextHeader}
      />
      <MainBlock
        clearOnTop={clearOnTop}
        minHeight={minHeight}
      >
        {children}
      </MainBlock>
      {!noFooter && <Footer />}
    </React.Fragment>
  );
}

export default Layout;
