import React from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, colors } from '@kmong/ui';
import { CLOUDFRONT_URL } from '@kmong-service/utils';
import layoutBase from '../../lib/styles/layoutBase';
import SectionCopy from './SectionCopy';
import SectionTitle from './SectionTitle';
import View from './View';

function SecuritySection() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <SecuritySectionBlock>
      <Main ref={ref}>
        <ContentSection inView={inView}>
          <SectionCopy css={css`margin-bottom: 44px;`}>
            안전한 거래 시스템
          </SectionCopy>
          <SectionTitle css={css`margin-bottom: 80px;`}>
            크몽을 통해 결제하면 거래 완료시까지
            <br />
            결제 대금을 안전하게 보호받을 수 있습니다.
          </SectionTitle>
          <Illust>
            <img
              alt="안전한 크몽 거래 시스템"
              src={`${CLOUDFRONT_URL}/assets/kmong_is/security-image-01.png`}
              srcSet={`${CLOUDFRONT_URL}/assets/kmong_is/security-image-01@2x.png 2x,
             ${CLOUDFRONT_URL}/assets/kmong_is/security-image-01@3x.png 3x`}
            />
            <SellerTypeWrapper>
              <Side>전문가</Side>
              <Side>의뢰인</Side>
            </SellerTypeWrapper>
          </Illust>
        </ContentSection>
        <View platform="desktop">
          <ImageSection inView={inView}>
            <img
              alt="안전한 크몽 거래 시스템"
              src={`${CLOUDFRONT_URL}/assets/kmong_is/security-phone.png`}
              srcSet={`${CLOUDFRONT_URL}/assets/kmong_is/security-phone@2x.png 2x,
             ${CLOUDFRONT_URL}/assets/kmong_is/security-phone@3x.png 3x`}
            />
          </ImageSection>
        </View>
        <View platform="mobile">
          <ImageSection inView={inView}>
            <img
              alt="안전한 크몽 거래 시스템"
              src={`${CLOUDFRONT_URL}/assets/kmong_is/security-phone.png`}
              srcSet={`${CLOUDFRONT_URL}/assets/kmong_is/security-phone@2x.png 2x,
             ${CLOUDFRONT_URL}/assets/kmong_is/security-phone@3x.png 3x`}
            />
          </ImageSection>
        </View>
      </Main>
    </SecuritySectionBlock>
  );
}

const SecuritySectionBlock = styled.section`
  padding: 128px 16px;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 64px 20px;
  }
`;

const Main = styled.div`
  ${layoutBase};
  display: flex;

  @media screen and (max-width: ${breakpoints.large}px){
    display: block;
  }
`;

const ContentSection = styled.div<{ inView: boolean }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 64px;

  @media screen and (max-width: ${breakpoints.large}px){
    padding: 0;
    text-align: center;
  }

  img {
    display: block;
    width: 100%;
    max-width: 400px;
    max-height: 100%;

    @media screen and (max-width: ${breakpoints.large}px){
      margin-bottom: 8px;
    }
  }

  opacity: 0;
  transform: translateX(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateX(0);
    opacity: 1;
  `}
`;

const ImageSection = styled.div<{ inView: boolean }>`
  flex: 1 1 40%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s, transform 0.9s cubic-bezier(0, 0.21, 0.03, 1.01) 0s;

  ${(props) => props.inView && css`
    transform: translateY(0);
    opacity: 1;
  `}

  @media screen and (max-width: ${breakpoints.large}px){
    margin-top: 40px;
  }
`;

const Illust = styled.div`
  @media screen and (max-width: ${breakpoints.large}px){
    margin-top: 16px;
  }
`;

const SellerTypeWrapper = styled.div`
  width: 410px;
  padding: 12px 58px;
  display: flex;
  justify-content: space-between;
  color: ${colors.gray[600]};

  @media screen and (max-width: ${breakpoints.large}px){
    width: 100%;
    padding: 0 34px;
  }
`;

const Side = styled.span``;

export default SecuritySection;
