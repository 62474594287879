import api from '../api';

export interface RecommendAvailableGigApplicant {
  applicantId: number;
}

export interface RecommendAvailableGig {
  currentCategoryId: number;
  rootCategoryId: number;
  subCategoryId: number;
  title: string;
  gigId: number;
  images: string[];
  applicant: RecommendAvailableGigApplicant | null;
}

export interface GetRecommendAvailableGigsResponse {
  gigs: RecommendAvailableGig[];
}

const getRecommendAvailableGigs = async () => {
  const { data } = await api.get<GetRecommendAvailableGigsResponse>('/api/next/gig-app/gig/v1/kmong-ad/click/recommend/available-gigs');

  return data;
};

interface GigDetail {
  gigId: number | null;
  title: string | null;
  images: string[] | null;
}

export enum CurrentRecommendDisplayStatusReasonTypeLabel {
  TURNED_OFF = '광고 OFF',
  NEEDS_FUNDS = '잔액 부족',
  EXCEED_BUDGET = '일 예산 도달',
  DISPLAYABLE = '노출 가능 ',
}

export type CurrentRecommendDisplayStatusReasonType = keyof typeof CurrentRecommendDisplayStatusReasonTypeLabel;

export enum CurrentRecommendDisplayStatusTypeLabel {
  READY = '대기 중',
  DISPLAYABLE = '광고 가능',
  DISPLAYING = '광고 중',
  STOPPED = '중지',
}

export type CurrentRecommendDisplayStatusType = keyof typeof CurrentRecommendDisplayStatusTypeLabel

export interface GigRecommendApplicant {
  id: number;
  sellerUserId: number;
  gigId: number;
  adPolicyId: number;
  bidAmount: number;
  budgetAmount: number | null;
  currentDisplayStatusType: CurrentRecommendDisplayStatusType;
  currentDisplayStatusReasonType: CurrentRecommendDisplayStatusReasonType | null;
  isOn: boolean;
  expectedClosedDateTime: string | null;
  createdDateTime: string;
  updatedDateTime: string;
  gig: GigDetail;
}

export interface GetRecommendGigApplicantsResponse {
  items: GigRecommendApplicant[];
}

const getRecommendGigApplicants = async () => {
  const { data } = await api.get<GetRecommendGigApplicantsResponse>('/api/next/order-app/kmong-ad/v1/gig/click/recommend/applicants');

  return data;
};

const getRecommendGigApplicant = async (applicantId: number) => {
  const { data } = await api.get<GigRecommendApplicant>(`/api/next/order-app/kmong-ad/v1/gig/click/recommend/applicants/${applicantId}`);

  return data;
};

export interface GetRecommendSuggestedBidAmountResponse {
  rootCategoryId: number;
  rootCategoryName: string;
  rootCategoryAmount: number;
  subCategoryId: number | null;
  subCategoryName: string | null;
  subCategoryAmount: number;
  thirdCategoryId: number | null;
  thirdCategoryName: string | null;
}

const getRecommendSuggestedBidAmount = async (categoryId: number) => {
  const { data } = await api.get<GetRecommendSuggestedBidAmountResponse>(`/api/next/order-app/kmong-ad/v1/gig/click/recommend/categories/${categoryId}/suggested-bid-amounts`);

  return data;
};

export interface GetRecommendReportsParams {
  startedDate: string;
  endedDate: string;
}

export interface RecommendReportSummary {
  totalImpressionCount: number;
  totalValidClickCount: number;
  averageClickAmount: number;
  totalDeductedClickAmount: number;
}

export interface RecommendReportItem {
  applicantId: number;
  gigId: number;
  userId: number;
  totalImpressionCount: number;
  totalValidClickCount: number;
  totalDeductedClickAmount: number;
  averageClickAmount: number;
}

export interface GetRecommendReportsResponse {
  summary: RecommendReportSummary;
  items: RecommendReportItem[];
}

const getRecommendReports = async (params: GetRecommendReportsParams) => {
  const { data } = await api.get<GetRecommendReportsResponse>('/api/next/order-app/kmong-ad/v1/gig/click/recommend/reports', { params });

  return data;
};

export interface PostRecommendGigApplicantData {
  gigId: number;
  budgetAmount: number | null;
  expectedClosedDate: string | null;
}

const postRecommendGigApplicant = async (data: PostRecommendGigApplicantData) => {
  await api.post('/api/next/order-app/kmong-ad/v1/gig/click/recommend/applicants', data);
};

export interface PutRecommendGigApplicantData extends Omit<PostRecommendGigApplicantData, 'gigId'> {
  applicantId: number;
}

const putRecommendGigApplicant = async ({ applicantId, ...data } : PutRecommendGigApplicantData) => {
  await api.put(`/api/next/order-app/kmong-ad/v1/gig/click/recommend/applicants/${applicantId}`, data);
};

const putDisplayStatusOn = async (applicantId: number) => {
  await api.put(`/api/next/order-app/kmong-ad/v1/gig/click/recommend/applicants/${applicantId}/action/on`);
};

const putDisplayStatusOff = async (applicantId: number) => {
  await api.put(`/api/next/order-app/kmong-ad/v1/gig/click/recommend/applicants/${applicantId}/action/off`);
};

export const smartPickApi = {
  getRecommendAvailableGigs,
  getRecommendGigApplicants,
  getRecommendGigApplicant,
  getRecommendSuggestedBidAmount,
  getRecommendReports,
  postRecommendGigApplicant,
  putRecommendGigApplicant,
  putDisplayStatusOn,
  putDisplayStatusOff,
};
