import api from '../api';

export const QUESTION_TYPE = {
  FILE: 'FILE',
  MULTI_SELECT: 'MULTI_SELECT',
  RADIO: 'RADIO',
  TEXT: 'TEXT',
} as const;

export type QuestionType = keyof typeof QUESTION_TYPE;

export interface SelectOptionValueType {
  content: string;
}

export interface FileType {
  fileId: number;
  fileName: string;
}

export interface BuyerOrderRequestDetailType {
  id: number;
  gigInstQuestionId: number;
  content: string;
  type: QuestionType;
  isMandatory: boolean;
  selections?: SelectOptionValueType[];
  answer?: string;
  file?: FileType;
}

export interface GetBuyerOrderRequestDetailResponse {
  gigInstQuestions: BuyerOrderRequestDetailType[];
}

const getBuyerOrderRequestDetail = async (gigLogId: number) => {
  const { data } = await api.get<GetBuyerOrderRequestDetailResponse>(`/api/gig/v1/gig-logs/${gigLogId}/inst-questions`);

  return data?.gigInstQuestions ?? [];
};

export interface PostSubmitBuyerRequestParams {
  answers: BuyerOrderRequestDetailType[];
}

const postSubmitBuyerRequest = async (orderId: number, params: PostSubmitBuyerRequestParams) => {
  const { data } = await api.post(`/api/order/v1/orders/${orderId}/progress/instructions`, params);

  return data;
};

export const buyerRequestApi = {
  getBuyerOrderRequestDetail,
  postSubmitBuyerRequest,
};
